import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Loader from '../../../../common/loader';
import {
  AddressToBeChangedHeader,
  AddressToBeChangedSelect,
  ChangeAddressButtonsContainer,
  ChangeAddressContainer,
  FieldHeader,
  LinkStyleButton,
  ReasonForChangeField,
  ReasonForChangeHeader,
  ReasonForChangeTextArea,
  RequestedEffectiveDateField,
  RequestedEffectiveDateHeader,
  RequiredField,
  SubmitButton,
} from './styles';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import { submitPolicyChange } from '../../policyChangeAPI';
import { setLoading, setPolicyChangeCoverageRecapFields } from '../../policyChangeReducer';
import { createField } from '../selector';
import Addresses from '../../../../common/addresses';
import { defaultLookupAddress, setAddressFields } from '../../../../common/addresses/addressUtils';
import { setLookupAddress } from '../../../../common/addresses/addressesReducer';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useNavigate } from 'react-router';

export const ChangeBillingMailingAddress = (props) => {
  const {
    policies,
    customerId,
    requestOnlyInd,
    selectedPolicyChangeTemplate,
    selectedPolicy,
    isLoading,
    i18n,
    dispatch,
  } = props;
  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [reason, setReason] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateOrProvince, setStateOrProvince] = useState({
    label: '-- Select a State --',
    value: 'default',
  });
  const [addressToChange, setAddressToChange] = useState('');
  const [zip, setZip] = useState('');
  const options = [
    { value: 'MailingAddr', label: 'Mailing' },
    { value: 'BillingAddr', label: 'Billing' },
  ];
  const defaultStateValue = { label: '-- Select an Address --', value: 'default' };

  const getPolicyAddress = () => {
    const insuredAddressType =
      addressToChange.label === 'Billing' ? 'InsuredBillingAddr' : 'InsuredMailingAddr';
    return policies
      .find((policy) => selectedPolicy.includes(policy.policyNumber))
      .insured.partyInfo.find((party) => party.partyTypeCd === 'InsuredParty')
      .addresses.find((address) => address.addrTypeCd === insuredAddressType);
  };

  const getPolicy = () => {
    return policies.find((policy) => selectedPolicy.includes(policy.policyNumber));
  };

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(createField('Selected Account', selectedPolicy, 'text'));
    fields.push(createField('Address', address, 'text'));
    fields.push(createField('City', city, 'text'));
    fields.push(createField('State', stateOrProvince.value, 'text'));
    fields.push(createField('Postal Code', zip, 'text'));
    return fields;
  };

  const onSubmitButtonClicked = () => {
    window.scrollTo(0, 0);
    const policyAddress = getPolicyAddress();
    const policy = getPolicy();
    // create payload
    const payload = {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: policy.policyRef,
      },
      changeRequest: [
        {
          detailDescription: reason,
          partyInfo: [
            {
              addresses: [
                {
                  id: policyAddress.id,
                  addrTypeCd: addressToChange.value,
                  addr1: address,
                  city: city,
                  stateProvCd: stateOrProvince.value,
                  postalCode: zip,
                },
              ],
            },
          ],
        },
      ],
      sourceCd: 'ServicePortal',
    };
    // set fields for recap/result
    dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));
    // submit API call
    dispatch(setLoading(true));
    dispatch(submitPolicyChange(payload));
  };

  const getFullInsuredAddress = (account, addressType) => {
    let isBilling = false;
    if (addressType === 'Billing') {
      isBilling = true;
    }
    const insuredAddressType = isBilling ? 'InsuredBillingAddr' : 'InsuredMailingAddr';
    const insuredAddress = policies
      .find((policy) => account.includes(policy.policyNumber))
      .insured.partyInfo.find((party) => party.partyTypeCd === 'InsuredParty')
      .addresses.find((address) => address.addrTypeCd === insuredAddressType);
    return (
      insuredAddress.addr1 +
      ', ' +
      insuredAddress.city +
      ', ' +
      insuredAddress.stateProvCd +
      ', ' +
      insuredAddress.postalCode
    );
  };

  const getFullInsuredAddressObject = (account, addressType) => {
    let isBilling = false;
    if (addressType === 'Billing') {
      isBilling = true;
    }
    const insuredAddressType = isBilling ? 'InsuredBillingAddr' : 'InsuredMailingAddr';
    return policies
      .find((policy) => account.includes(policy.policyNumber))
      .insured.partyInfo.find((party) => party.partyTypeCd === 'InsuredParty')
      .addresses.find((address) => address.addrTypeCd === insuredAddressType);
  };

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    return (
      (requestOnlyInd === false || effectiveDate.length > 0) &&
      reason.length > 0 &&
      address &&
      address.length > 0 &&
      city &&
      city.length > 0 &&
      zip &&
      zip.length > 0 &&
      stateOrProvince &&
      stateOrProvince.value &&
      stateOrProvince.value.length > 0 &&
      !isLoading
    );
  };

  let handleAddressChange = (address) => {
    if (address && address.value && address.value !== 'default') {
      setAddressToChange(address);
      setAddress('');
      setZip('');
      setCity('');
      setStateOrProvince(defaultStateValue);
      dispatch(setLookupAddress(defaultLookupAddress));
    }
  };

  useEffect(() => {
    setAddressToChange('');
  }, []);

  const handlePlaceSelect = (address) => {
    setAddressFields(address, setAddress, setCity, setZip, setStateOrProvince);
  };

  const navigate = useNavigate();

  return (
    <ChangeAddressContainer>
      {requestOnlyInd === true ? (
        <>
          <RequestedEffectiveDateHeader>
            Requested Effective Date<RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.changeBillingAddress.effectiveDateTooltip')}
            />
          </RequestedEffectiveDateHeader>

          <RequestedEffectiveDateField>
            <input
              id="effectiveDate"
              type="date"
              aria-label="Requested Effective Date"
              placeholder={todayDt}
              onChange={(e) => {
                setEffectiveDate(e.target.value);
              }}
            />
          </RequestedEffectiveDateField>
        </>
      ) : null}
      <ReasonForChangeHeader>
        Reason for Change<RequiredField>*</RequiredField>
        <TooltipInfo title={getText(i18n, 'changeCoverage.changeBillingAddress.reasonTooltip')} />
      </ReasonForChangeHeader>
      <ReasonForChangeField>
        <ReasonForChangeTextArea
          id="reason"
          onChange={(e) => {
            setReason(e.target.value);
          }}
        />
      </ReasonForChangeField>
      <AddressToBeChangedHeader>
        Address To Be Changed<RequiredField>*</RequiredField>
        <TooltipInfo
          title={getText(i18n, 'changeCoverage.changeBillingAddress.addressToBeChangedTooltip')}
        />
      </AddressToBeChangedHeader>
      <AddressToBeChangedSelect
        id="addressToChange"
        onChange={handleAddressChange}
        options={options}
        defaultValue={defaultStateValue}
      />
      {addressToChange === '' ? null : (
        <>
          {addressToChange.value === 'MailingAddr' ? (
            <FieldHeader id="currentMailingAddressLabel">Current Mailing Address:</FieldHeader>
          ) : (
            <FieldHeader id="currentBillingAddressLabel">Current Billing Address:</FieldHeader>
          )}
          <div>{getFullInsuredAddress(selectedPolicy, addressToChange.label)}</div>
          {addressToChange.value === 'MailingAddr' ? (
            <FieldHeader id="newMailingAddressLabel">New Mailing Address:</FieldHeader>
          ) : (
            <FieldHeader id="newBillingAddressLabel">New Billing Address:</FieldHeader>
          )}
          <Addresses
            id="changeCoverageMailingBillingAddress"
            handlePlaceSelect={handlePlaceSelect}
            isRequiredField={true}
            defaultAddress={getFullInsuredAddressObject(selectedPolicy, addressToChange.label)}
          />
        </>
      )}
      <ChangeAddressButtonsContainer>
        <LinkStyleButton
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          Cancel
        </LinkStyleButton>
        <SubmitButton
          disabled={!checkRequiredFields()}
          onClick={() => {
            onSubmitButtonClicked();
          }}
        >
          Submit
        </SubmitButton>
        {isLoading && <Loader loaderheight="20px" loaderwidth="20px" />}
      </ChangeAddressButtonsContainer>
    </ChangeAddressContainer>
  );
};

ChangeBillingMailingAddress.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicyChangeTemplate: PropTypes.object,
  customerId: PropTypes.string,
  policyChangeCoverageRecapFields: PropTypes.array,
  policies: PropTypes.array,
  requestOnlyInd: PropTypes.bool,
  selectedPolicy: PropTypes.string,
  isLoading: PropTypes.bool,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  customerId: state.customerSlice.customer.systemId,
  policyChangeCoverageRecapFields: state.policyChangeSlice.policyChangeCoverageRecapFields,
  policies: state.policySlice.policies,
  requestOnlyInd: state.policyChangeSlice.selectedPolicyChangeTemplate.requestOnlyInd,
  selectedPolicy: state.policyChangeSlice.selectedPolicy.label,
  isLoading: state.policyChangeSlice.isLoading,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(ChangeBillingMailingAddress);
