import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  AmountContainer,
  AmountData,
  AmountHeader,
  AmountHeaderMobile,
  DescriptionContainer,
  NoRecentPayments,
  PaymentIdContainer,
  PaymentIdData,
  PaymentIdHeader,
  PaymentIdHeaderMobile,
  ReceiptDateContainer,
  ReceiptDateData,
  ReceiptDateHeader,
  ReceiptDateHeaderMobile,
  ReceiptDescriptionData,
  ReceiptDescriptionHeader,
  ReceiptDescriptionHeaderMobile,
  RecentPaymentDataContainer,
  RecentPaymentHeaderContainer,
  ShowPaymentsButton,
  StatusContainer,
  StatusData,
  StatusHeader,
  StatusHeaderMobile,
  TableFix,
} from './styles';
import { DISPLAY_DATE_FORMAT } from '../../common/constants';
import { setPolicyAccountsAndMerge, setStatementAccountsAndMerge } from './utils';

export const ScheduledPendingPaymentsRow = (props) => {
  const { fullAccount, paymentTypes } = props;

  const [paymentTypesMap, setPaymentTypesMap] = React.useState(new Map());
  const [showAllRecentPayments, setShowRecentPayments] = React.useState(false);
  React.useEffect(() => {
    if (paymentTypes) {
      const entries = Object.entries(paymentTypes);
      if (entries.length > 0) {
        setPaymentTypesMap(new Map(Object.entries(paymentTypes)));
      }
    }
  }, [paymentTypes]);

  const setStatementAccounts = () => {
    let merged = setStatementAccountsAndMerge(fullAccount, paymentTypesMap, true);
    return (
      <>
        <RecentPaymentHeaderContainer>
          <PaymentIdHeader>Payment Id</PaymentIdHeader>
          <ReceiptDescriptionHeader>Type</ReceiptDescriptionHeader>
          <ReceiptDateHeader>Date</ReceiptDateHeader>
          <AmountHeader>Amount </AmountHeader>
          <StatusHeader>Status</StatusHeader>
        </RecentPaymentHeaderContainer>
        {merged.length > 0 ? (
          merged
            .filter((_item, index) => (showAllRecentPayments ? index <= merged.length : index < 5))
            .map((payment, index) => {
              return (
                <RecentPaymentDataContainer key={index}>
                  <PaymentIdContainer>
                    <PaymentIdHeaderMobile>Payment Id</PaymentIdHeaderMobile>
                    <PaymentIdData>{payment.receiptId}</PaymentIdData>
                  </PaymentIdContainer>
                  <DescriptionContainer>
                    <ReceiptDescriptionHeaderMobile>Type</ReceiptDescriptionHeaderMobile>
                    <ReceiptDescriptionData>{payment.typeCd}</ReceiptDescriptionData>
                  </DescriptionContainer>
                  <ReceiptDateContainer>
                    <ReceiptDateHeaderMobile>Date</ReceiptDateHeaderMobile>
                    <ReceiptDateData>
                      {moment(payment.receiptDt).format(DISPLAY_DATE_FORMAT)}
                    </ReceiptDateData>
                  </ReceiptDateContainer>
                  <AmountContainer>
                    <AmountHeaderMobile>Amount</AmountHeaderMobile>
                    <AmountData>${parseFloat(payment.receiptAmt).toFixed(2)}</AmountData>
                  </AmountContainer>
                  <StatusContainer>
                    <StatusHeaderMobile>Status</StatusHeaderMobile>
                    <StatusData>{payment.statusCd}</StatusData>
                  </StatusContainer>
                </RecentPaymentDataContainer>
              );
            })
        ) : (
          <NoRecentPayments>No Pending/Scheduled Payments Available</NoRecentPayments>
        )}
      </>
    );
  };

  const setPolicyAccounts = () => {
    let merged = setPolicyAccountsAndMerge(fullAccount, paymentTypesMap, true);

    return (
      <>
        <RecentPaymentHeaderContainer>
          <PaymentIdHeader>Payment Id</PaymentIdHeader>
          <ReceiptDescriptionHeader>Type</ReceiptDescriptionHeader>
          <ReceiptDateHeader>Date</ReceiptDateHeader>
          <AmountHeader>Amount </AmountHeader>
          <StatusHeader>Status</StatusHeader>
        </RecentPaymentHeaderContainer>
        {merged.length > 0 ? (
          <>
            {merged
              .filter((_item, index) =>
                showAllRecentPayments ? index <= merged.length : index < 5,
              )
              .map((payment, index) => {
                return (
                  <RecentPaymentDataContainer key={index}>
                    <PaymentIdContainer>
                      <PaymentIdHeaderMobile>Payment Id</PaymentIdHeaderMobile>
                      <PaymentIdData>{payment.receiptId}</PaymentIdData>
                    </PaymentIdContainer>
                    <DescriptionContainer>
                      <ReceiptDescriptionHeaderMobile>Type</ReceiptDescriptionHeaderMobile>
                      <ReceiptDescriptionData>{payment.typeCd}</ReceiptDescriptionData>
                    </DescriptionContainer>
                    <ReceiptDateContainer>
                      <ReceiptDateHeaderMobile>Date</ReceiptDateHeaderMobile>
                      <ReceiptDateData>
                        {moment(payment.receiptDt).format(DISPLAY_DATE_FORMAT)}
                      </ReceiptDateData>
                    </ReceiptDateContainer>
                    <AmountContainer>
                      <AmountHeaderMobile>Amount</AmountHeaderMobile>
                      <AmountData>${parseFloat(payment.receiptAmt).toFixed(2)}</AmountData>
                    </AmountContainer>
                    <StatusContainer>
                      <StatusHeaderMobile>Status</StatusHeaderMobile>
                      <StatusData>{payment.statusCd}</StatusData>
                    </StatusContainer>
                  </RecentPaymentDataContainer>
                );
              })}
            <TableFix>
              <ShowPaymentsButton onClick={() => setShowRecentPayments(!showAllRecentPayments)}>
                {merged.length <= 5
                  ? null
                  : showAllRecentPayments
                  ? `Hide Pending/Scheduled Payments`
                  : `Show All Pending/Scheduled Payments`}
              </ShowPaymentsButton>
            </TableFix>
          </>
        ) : (
          <NoRecentPayments>No Pending/Scheduled Payments Available</NoRecentPayments>
        )}
      </>
    );
  };

  return (
    <>
      {fullAccount.accountDisplayName === 'Statement'
        ? setStatementAccounts()
        : setPolicyAccounts()}
    </>
  );
};
ScheduledPendingPaymentsRow.propTypes = {
  fullAccount: PropTypes.shape({
    underlyingBillingAccounts: PropTypes.array,
    accountDisplayName: PropTypes.string,
    billingHistory: PropTypes.array,
    pendingReceipts: PropTypes.array,
  }),
  paymentTypes: PropTypes.object,
};
export default ScheduledPendingPaymentsRow;
