import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  InformationMessage,
  CurrentDeductibleField,
  CurrentPremiumField,
  NewDeductibleField,
  NewPremiumField,
  NewPremiumLabel,
  PolicyChangePreviewBorderedContainer,
  PolicyChangePreviewButton,
  PolicyChangePreviewButtonsContainer,
  PolicyChangePreviewContainer,
  PolicyChangePreviewFieldHeader,
  PolicyChangePreviewField,
  PolicyChangePreviewSubContainer,
  PolicyChangePreviewTextArea,
  TwoColumnContainer,
  HorizontalLine,
  LinkStyleButton,
} from './styles';
import {
  setPolicyChangeCoverageRecapFields,
  setPolicyChangePreview,
} from '../../policyChangeReducer';
import { submitPolicyChange } from '../../policyChangeAPI';
import Loader from '../../../../common/loader';
import { Spacer, StyledLoaderContainer } from '../../styles';
import { useNavigate } from 'react-router';

export const PolicyChangePreview = (props) => {
  const {
    dispatch,
    latestPolicyInfo,
    newDeductible,
    policyChangePreview,
    previewData,
    showPremiumChange,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const premiumDiff = (policyChangePreview.finalPremiumAmt - latestPolicyInfo.finalPremiumAmt)
    .toFixed(2)
    .toString();

  const onClickSubmit = () => {
    setIsSubmitting(true);

    // set fields for recap/result
    dispatch(setPolicyChangeCoverageRecapFields(previewData.recapFields));
    // submit API call
    dispatch(submitPolicyChange(previewData.payload));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsSubmitting(false);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <PolicyChangePreviewContainer>
        {showPremiumChange && (
          <TwoColumnContainer>
            <PolicyChangePreviewSubContainer>
              {latestPolicyInfo && latestPolicyInfo.deductible && (
                <CurrentDeductibleField id="currentDeductibleAmount">
                  <PolicyChangePreviewFieldHeader>
                    Current Deductible
                  </PolicyChangePreviewFieldHeader>
                  ${latestPolicyInfo.deductible}
                </CurrentDeductibleField>
              )}
              <CurrentPremiumField id="currentTotalPremiumAmount">
                <PolicyChangePreviewFieldHeader>
                  Current Total Premium
                </PolicyChangePreviewFieldHeader>
                ${latestPolicyInfo.finalPremiumAmt}
              </CurrentPremiumField>
            </PolicyChangePreviewSubContainer>
            <PolicyChangePreviewBorderedContainer>
              {newDeductible && (
                <NewDeductibleField>
                  <PolicyChangePreviewFieldHeader>New Deductible</PolicyChangePreviewFieldHeader>
                  <NewPremiumLabel id="newDeductible">{newDeductible.value}</NewPremiumLabel>
                </NewDeductibleField>
              )}
              <NewPremiumField>
                <PolicyChangePreviewFieldHeader>New Total Premium</PolicyChangePreviewFieldHeader>
                <NewPremiumLabel id="newTotalPremium">
                  ${policyChangePreview.finalPremiumAmt}
                </NewPremiumLabel>
              </NewPremiumField>
            </PolicyChangePreviewBorderedContainer>
            <div />
            <div>
              <PolicyChangePreviewField id="premiumChanges">
                <PolicyChangePreviewFieldHeader>Premium Changes</PolicyChangePreviewFieldHeader>
                {premiumDiff.includes('-') ? `-$${premiumDiff.substring(1)}` : `$${premiumDiff}`}
              </PolicyChangePreviewField>
              <InformationMessage>
                The premium change amount reflects the change in premium from the effective date of
                this change to the end of the policy term
              </InformationMessage>
            </div>
          </TwoColumnContainer>
        )}
        <HorizontalLine />
        <TwoColumnContainer>
          {previewData.fields.map((field) =>
            field.showFieldOnPremiumPreview ? (
              <PolicyChangePreviewField key={`id-${field.label.replaceAll(' ', '-')}`}>
                <PolicyChangePreviewFieldHeader>{field.label}</PolicyChangePreviewFieldHeader>
                {field.type === 'textArea' ? (
                  <PolicyChangePreviewTextArea value={field.value} />
                ) : (
                  <div id={field.label + ' Text'}>{field.value}</div>
                )}
              </PolicyChangePreviewField>
            ) : null,
          )}
        </TwoColumnContainer>
        <PolicyChangePreviewButtonsContainer>
          <LinkStyleButton
            onClick={() => {
              navigate('/dashboard');
            }}
            disabled={isSubmitting}
          >
            Cancel
          </LinkStyleButton>
          <PolicyChangePreviewButton
            onClick={() => {
              dispatch(setPolicyChangePreview(null));
            }}
            disabled={isSubmitting}
          >
            Edit Changes
          </PolicyChangePreviewButton>
          <PolicyChangePreviewButton
            id="Submit"
            onClick={() => {
              onClickSubmit();
            }}
            disabled={isSubmitting}
          >
            Submit
          </PolicyChangePreviewButton>
          {isSubmitting ? (
            <StyledLoaderContainer>
              <Loader loaderheight="20px" loaderwidth="20px" />
            </StyledLoaderContainer>
          ) : (
            <Spacer />
          )}
        </PolicyChangePreviewButtonsContainer>
      </PolicyChangePreviewContainer>
    </>
  );
};

PolicyChangePreview.propTypes = {
  dispatch: PropTypes.func,
  newDeductible: PropTypes.string,
  latestPolicyInfo: PropTypes.object,
  policyChangePreview: PropTypes.object,
  previewData: PropTypes.object,
  policyChangeSection: PropTypes.array,
  showPremiumChange: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  latestPolicyInfo: state.policyChangeSlice.latestPolicyInfo,
  newDeductible: state.policyChangeSlice.newDeductible,
  policyChangePreview: state.policyChangeSlice.policyChangePreview,
  previewData: state.policyChangeSlice.policyChangePreviewData,
  policyChangeSection: state.policyChangeSlice.policyChangeSection,
  showPremiumChange: state.policyChangeSlice.selectedPolicyChangeTemplate.showPremChgInd,
});
export default connect(mapStateToProps)(PolicyChangePreview);
