import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  RequiredField,
  TemplateBasedButton,
  TemplateBasedButtonsContainer,
  TemplateBasedContainer,
  TemplateBasedField,
  TemplateBasedFieldHeader,
  TemplateBasedTextAreaField,
  TwoColumnContainer,
  TwoColumnContainerTop,
} from './styles';
import moment from 'moment';
import { submitPolicyChange } from '../../policyChangeAPI';
import PropTypes from 'prop-types';
import { getAdditionalQuestions, getQuestionReplies } from '../selector';
import AdditionQuestionField from './additional-question-field';
import { setPolicyChangeCoverageRecapFields } from '../../policyChangeReducer';
import { Spacer, StyledLoaderContainer } from '../../styles';
import { createPolicyChangeCoverageSummary, YES_NO_DEFAULT } from './selector';
import Loader from '../../../../common/loader';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useNavigate } from 'react-router-dom';

export const TemplateBased = (props) => {
  const { selectedPolicyChangeTemplate, selectedPolicy, customerId, i18n } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [reason, setReason] = useState('');
  const [additionalQuestions] = useState(getAdditionalQuestions(selectedPolicyChangeTemplate));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [answers, setAnswers] = useState({});

  const setAdditionalQuestionsAnswers = (index, answer) => {
    const theAnswer = {};
    theAnswer[index] = { answer };
    setAnswers((prevAnswers) => {
      return { ...prevAnswers, ...theAnswer };
    });
  };

  const setAdditionalSubQuestionsAnswers = (parentIndex, index, answer) => {
    const subQuestionAnswers = [];
    subQuestionAnswers[index] = answer;

    const theAnswer = {};
    theAnswer[parentIndex] = {
      answer: answers[parentIndex].answer,
      subQuestionAnswers,
    };
    setAnswers((prevAnswers) => {
      return { ...prevAnswers, ...theAnswer };
    });
  };

  const checkAdditionalAnswersComplete = () => {
    let requiredAnswersCompleted = true;
    for (let i = 0; i < additionalQuestions.length; i++) {
      const answer = answers[i] !== undefined ? answers[i].answer : '';
      const question = additionalQuestions[i];
      if (question.dataType === 'YesNo' && question.requiredInd === 'Yes') {
        if (answer === undefined || answer.length === 0) {
          // default or yes if not set
          setAdditionalQuestionsAnswers(
            i,
            question.defaultValue !== undefined ? question.defaultValue : YES_NO_DEFAULT,
          );
        }
      } else if (question.dataType === 'List' && question.requiredInd === 'Yes') {
        requiredAnswersCompleted = answer !== undefined && answer.length !== 0;
        if (!requiredAnswersCompleted) {
          return false;
        }
      } else if (question.dataType === 'Numeric' && question.requiredInd === 'Yes') {
        requiredAnswersCompleted =
          requiredAnswersCompleted && answer !== undefined && answer.length > 0;
        if (!requiredAnswersCompleted) {
          return false;
        }
      } else if (question.dataType === 'TextArea' && question.requiredInd === 'Yes') {
        requiredAnswersCompleted =
          requiredAnswersCompleted && answer !== undefined && answer.length > 0;
        if (!requiredAnswersCompleted) {
          return false;
        }
      }

      // sub question answers check
      if (answers[i] && answers[i].subQuestionAnswers) {
        for (let j = 0; j < answers[i].subQuestionAnswers.length; j++) {
          const subAnswer = answers[i].subQuestionAnswers[j];
          const subQuestion = additionalQuestions[i].questions.question[j];
          if (subQuestion.dataType === 'YesNo' && subQuestion.requiredInd === 'Yes') {
            if (subAnswer === undefined || subAnswer.length === 0) {
              // default or yes if not set
              setAdditionalSubQuestionsAnswers(
                i,
                j,
                question.defaultValue !== undefined ? question.defaultValue : YES_NO_DEFAULT,
              );
            }
          } else if (subQuestion.dataType === 'List' && subQuestion.requiredInd === 'Yes') {
            requiredAnswersCompleted = subAnswer !== undefined && subAnswer.length !== 0;
            if (!requiredAnswersCompleted) {
              return false;
            }
          } else if (subQuestion.dataType === 'Numeric' && subQuestion.requiredInd === 'Yes') {
            requiredAnswersCompleted =
              requiredAnswersCompleted && subAnswer !== undefined && subAnswer.length > 0;
            if (!requiredAnswersCompleted) {
              return false;
            }
          } else if (subQuestion.dataType === 'TextArea' && subQuestion.requiredInd === 'Yes') {
            requiredAnswersCompleted =
              requiredAnswersCompleted && subAnswer !== undefined && subAnswer.length > 0;
            if (!requiredAnswersCompleted) {
              return false;
            }
          }
        }
      }
    }
    return !!requiredAnswersCompleted;
  };

  const checkRequiredFieldsCompleted = () => {
    // check effective date and reason
    let requiredFieldsCompleted = effectiveDate.length > 0 && reason.trim().length > 0;
    return !!requiredFieldsCompleted;
  };

  const createPayload = () => {
    return {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
        },
      ],
      policyChangeSection: selectedPolicyChangeTemplate.policyChangeSections,
      questionReplies: [{ questionReply: getQuestionReplies(additionalQuestions, answers) }],
      sourceCd: 'ServicePortal',
    };
  };

  const onSubmitButtonClick = () => {
    // create payload
    const payload = createPayload();

    // set fields for recap/result
    props.dispatch(
      setPolicyChangeCoverageRecapFields(
        createPolicyChangeCoverageSummary(
          effectiveDate,
          reason,
          selectedPolicyChangeTemplate.name,
          additionalQuestions,
          answers,
        ),
      ),
    );

    setIsSubmitting(true);
    // submit API call
    props.dispatch(submitPolicyChange(payload));
  };

  const navigate = useNavigate();

  return (
    <>
      <TemplateBasedContainer>
        <TwoColumnContainerTop>
          <TemplateBasedField>
            <TemplateBasedFieldHeader>
              Requested Effective Date <RequiredField>*</RequiredField>
              <TooltipInfo
                title={getText(i18n, 'changeCoverage.TemplateBased.effectiveDateTooltip')}
              />
            </TemplateBasedFieldHeader>
            <input
              type="date"
              aria-label="Requested Effective Date"
              value={effectiveDate}
              min={todayDt}
              onChange={(e) => {
                setEffectiveDate(e.target.value);
              }}
            />
          </TemplateBasedField>
          <TemplateBasedField>
            <TemplateBasedFieldHeader>
              Reason <RequiredField>*</RequiredField>
              <TooltipInfo title={getText(i18n, 'changeCoverage.TemplateBased.reasonTooltip')} />
            </TemplateBasedFieldHeader>
            <TemplateBasedTextAreaField
              id="reason"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </TemplateBasedField>
        </TwoColumnContainerTop>
        <TwoColumnContainer>
          {additionalQuestions.map((q, index) => {
            return (
              <AdditionQuestionField
                id={q.text}
                key={index}
                q={q}
                index={index}
                setAdditionalQuestionsAnswers={setAdditionalQuestionsAnswers}
                setAdditionalSubQuestionsAnswers={setAdditionalSubQuestionsAnswers}
              />
            );
          })}
        </TwoColumnContainer>
        <TemplateBasedButtonsContainer>
          <TemplateBasedButton
            id="Cancel"
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Cancel
          </TemplateBasedButton>
          <>
            <TemplateBasedButton
              disabled={
                !checkAdditionalAnswersComplete() || !checkRequiredFieldsCompleted() || isSubmitting
              }
              onClick={() => onSubmitButtonClick()}
            >
              Submit
            </TemplateBasedButton>
            {isSubmitting ? (
              <StyledLoaderContainer>
                <Loader loaderheight="20px" loaderwidth="20px" />
              </StyledLoaderContainer>
            ) : (
              <Spacer />
            )}
          </>
        </TemplateBasedButtonsContainer>
      </TemplateBasedContainer>
    </>
  );
};

TemplateBased.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicyChangeTemplate: PropTypes.object,
  customerId: PropTypes.string,
  selectedPolicy: PropTypes.object,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  customerId: state.customerSlice.customer.systemId,
  state,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(TemplateBased);
