import styled from 'styled-components';
import { StyledButtonNext } from '../styles';
import PrintIcon from '@mui/icons-material/Print';
import InfoIcon from '@mui/icons-material/Info';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const PaymentHeader = styled.h2`
  font-size: 1.525rem;
  font-weight: 400;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const NotificationContainer = styled.div`
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  margin-top: 24px;
  padding: 20px 20px 20px 8px;
  border-radius: 4px;
`;
export const StyledInfoIcon = styled(InfoIcon).attrs({
  className: 'fa fa-lg fa-info-circle',
})`
  position: relative;
  bottom: ${(props) => (props.failed ? '6px' : 'initial')};
`;
export const StyledInformationMessage = styled.div.attrs({})`
  margin-left: 12px;
  font-size: 14px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: inline-block;
  max-width: ${(props) => (props.failed ? '900px' : 'initial')};
`;
export const StyledSuccessHeader = styled.h2.attrs({})`
  margin-left: 8px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: inline;
  font-size: 18px;
  font-weight: 400;
`;

export const AmountCard = styled.div.attrs({
  id: 'amountCards',
})`
  margin-top: 24px;
  display: grid;
  border-radius: 4px;
  background-color: #fafafa;
  @media (max-width: 464px) {
    padding: 24px;
  }
  @media (min-width: 465px) and (max-width: 1129px) {
    padding: 24px;
  }
  @media (min-width: 1130px) and (max-width: 1445px) {
    padding: 24px;
    height: 200px;
  }
  @media (min-width: 1445px) {
    height: 200px;
  }
`;
export const PaidWithCard = styled(AmountCard).attrs({
  id: 'PaymentMethodCard',
})``;

export const PayDateCard = styled(AmountCard).attrs({
  id: 'payDateCard',
})``;

export const CardContainer = styled.div`
  display: grid;
  @media (max-width: 464px) {
  }
  @media (min-width: 465px) and (max-width: 1129px) {
  }
  @media (min-width: 1130px) and (max-width: 1445px) {
    grid-template-columns: 31% 31% 31%;
    column-gap: 24px;
  }
  @media (min-width: 1445px) {
    grid-template-columns: 32% 32% 32%;
    column-gap: 24px;
  }
`;

export const AmountHeader = styled.div.attrs({
  id: 'amountToBePaid',
})`
  font-size: 20px;
  color: #28333f;
  font-weight: 500;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  align-self: end;
  text-align: center;
`;
export const AmountText = styled.div.attrs({
  id: 'amountText',
})`
  font-size: 32px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  color: #28333f;
  text-align: center;
  margin-top: 8px;
`;
export const PaidWithHeader = styled.div.attrs({
  id: 'paidWithHeader',
})`
  font-size: 16px;
  color: #28333f;
  font-weight: 400;
  display: inline-block;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  align-self: end;
  text-align: center;
`;
export const StyledCreditIcon = styled(CreditCardIcon).attrs({
  className: 'fa fa-lg fa-credit-card',
})`
  margin-right: 8px;
`;
export const StyledACHIcon = styled(AccountBalanceIcon).attrs({
  className: 'fa fa-lg fa-university',
})`
  margin-right: 8px;
  position: relative;
  top: 5px;
`;
export const PaidWithType = styled.div`
  font-size: 16px;
  color: #28333f;
  margin-top: 8px;
  font-weight: 500;
  display: inline-block;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-align: center;
`;
export const PayDate = styled.div.attrs({
  id: 'paymentDateText',
})`
  font-size: 16px;
  color: #28333f;
  margin-top: 8px;
  font-weight: 600;
  display: inline-block;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-align: center;
`;
export const PayDateHeader = styled.div.attrs({
  id: 'payDateHeader',
})`
  font-size: 16px;
  color: #28333f;
  font-weight: 400;
  display: inline-block;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  align-self: end;
  text-align: center;
`;
export const StyledSuccessMessage = styled.span.attrs({})`
  font-size: 14px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: inline;
  font-weight: 400;
`;
export const StyledButtonHome = styled(StyledButtonNext)`
  margin-left: 25px;
  padding: 15px 25px 15px 25px;
  @media (max-width: 599px) {
    margin-bottom: 140px;
  }
`;
export const PayFailedButtonHome = styled(StyledButtonNext)`
  margin-left: 0;
  margin-top: 25px;
  padding: 15px 25px 15px 25px;
`;
export const StyledPrintButton = styled.button.attrs({
  id: 'printButton',
})`
  padding: 10px 28px 10px 28px;
  border: 2px solid ${(props) => props.theme.primaryColor};
  transition: 220ms all ease-in-out;
  background-color: ${(props) => props.theme.white};

  color: ${(props) => props.theme.primaryColor};
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
  /* border-radius: 4px; */
  :hover {
    background-color: #fafafa;
  }
  :active {
    background-color: white;
  }
  a:webkit-any-link {
    color: white;
  }
  :disabled {
    background-color: gray;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
export const StyledPrintIcon = styled(PrintIcon)`
  font-size: 19px !important;
  right: 12px;
  position: relative;
  top: 4px;
`;
export const SuccessHeaderTop = styled.h2`
  font-size: 1.525rem;
  font-weight: 400;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const InstructionsContainer = styled.div.attrs({
  id: 'instructionsContainer',
})`
  margin: 16px 0;
`;
