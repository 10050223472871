import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';

import {
  ChangePayScheduleEditScreenContainer,
  DateOfPaymentContainer,
  PaymentDateHeader,
  PayScheduleEditHeader,
  PayPlanButtonContainer,
  RequiredField,
  SelectPayPlanHeader,
  SelectPayMethodHeader,
  StyledPayDateInput,
} from './styles';

import PayScheduleTable from './payScheduleTable';
import FundingInstruments from './fundingInstruments';
import { setNewPlanSchedule, setDateToPay } from './payPlanReducer';
import { setPaymentInstrument } from '../make-payment/step-two-amount/makePaymentStepTwoReducer';
import { handlePayDateChange } from './utils';
export const EditPaySchedule = (props) => {
  const { account, dispatch, newPlan, payPlanDateToPay, payPlanOptions } = props;
  const payPlanOptionsMapped = payPlanOptions.map((plan) => {
    return { ...plan, label: plan.name, value: plan.payPlanCd };
  });

  const handlePlanScheduleChange = (plan) => {
    if (plan.payPlanCd === 'Direct Pay In Full-NB') {
      dispatch(setPaymentInstrument(null));
      dispatch(setDateToPay(''));
      //if they previously had set their payment we want to null that out.
    }
    dispatch(setNewPlanSchedule(plan));
  };

  return (
    <ChangePayScheduleEditScreenContainer>
      <PayScheduleEditHeader>Payment Schedule</PayScheduleEditHeader>
      <SelectPayPlanHeader>Select your new payment schedule</SelectPayPlanHeader>
      <PayPlanButtonContainer>
        <Select
          id="payPlanSelector"
          options={payPlanOptionsMapped}
          value={newPlan}
          defaultValue={{ label: '-- Select a Plan --', value: 'default' }}
          onChange={handlePlanScheduleChange}
        />
      </PayPlanButtonContainer>
      {newPlan ? (
        <div>
          <h1>Your New Payment Schedule</h1>
          <PayScheduleTable schedule={newPlan.payPlan.schedule} />
        </div>
      ) : null}
      <DateOfPaymentContainer>
        {newPlan &&
        newPlan.payPlanCd !== 'Direct Pay In Full-NB' &&
        newPlan.payPlan.dayOfMonthInd !== 'No' ? (
          <>
            <PaymentDateHeader>
              What day of the month do you want to pay?
              {newPlan.payPlan.dayOfMonthInd === 'Required' && <RequiredField> *</RequiredField>}
            </PaymentDateHeader>
            <StyledPayDateInput
              id="payDateSelect"
              max="31"
              min="1"
              value={payPlanDateToPay}
              onChange={(e) => handlePayDateChange(e.target.value, dispatch)}
            />
          </>
        ) : null}
      </DateOfPaymentContainer>

      {newPlan && !newPlan.payPlan.payPlanTypeCd.includes('None') ? (
        <>
          <SelectPayMethodHeader>Select your payment method.</SelectPayMethodHeader>
          <FundingInstruments payScheduleAccount={account} />
        </>
      ) : null}
    </ChangePayScheduleEditScreenContainer>
  );
};
EditPaySchedule.propTypes = {
  account: PropTypes.any,
  dispatch: PropTypes.any,
  payPlanDateToPay: PropTypes.any,
  payPlanOptions: PropTypes.array,
  newPlan: PropTypes.any,
};
export const mapStateToProps = (state) => ({
  state,
  billingAccounts: state.billingSlice.billingAccounts,
  payPlanDateToPay: state.payPlanSlice.payPlanDateToPay,
  payPlanOptions: state.payPlanSlice.payPlanOptions,
  newPlan: state.payPlanSlice.newPlan,
});

export default connect(mapStateToProps)(EditPaySchedule);
