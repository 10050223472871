import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { setCustomer } from '../../customer/customerReducer';
import {
  setActiveStep,
  setAmountToPay,
  setBillSelected,
  setIsLoading,
  setNextButtonClicked,
  setNextButtonDisabled,
  setPaymentConfigId,
} from '../makePaymentAnonymousReducer';
import { connect } from 'react-redux';
import { getCustomerBillingInformation } from './makePaymentAnonymousApi';
import PendingPaymentBanner from '../../../common/payment/pending-payment';
import {
  RadioChoice,
  CurrentDueAmount,
  CurrentDueContainer,
  DatesAndAmountsContainer,
  GridSpacingDesktopOne,
  GridSpacingDesktopTwo,
  InstructionsContainer,
  NoPaymentDueText,
  PolicyAndCurrentDueContainer,
  PolicyCard,
  PolicyHeader,
  PolicySubHeaders,
  PolicyValueHeader,
  ReturnHomeButton,
  PolicyToPayContainer,
  PayoffAmountHeader,
} from './styles';
import { getAccountsWithABill } from './selectors';
import Loader from '../../../common/loader';
import TooltipInfo from '../../../common/tooltip';
import { getText } from '../../../../utils/i18n';
import {
  determineAnonymousPaymentConfigId,
  getPendingPayments,
} from '../../../common/payment/selectors';
import { restructureCustomerForPayment } from '../step3/selectors';
import { isEmpty } from 'lodash';
const Step2 = (props) => {
  const {
    activeStep,
    amountToPay,
    billSelected,
    billingAccounts,
    customerId,
    dispatch,
    errors,
    hasOpenBalance,
    isLoading,
    nextButtonClicked,
    paymentConfigIds,
    registrationToken,
    i18n,
    billingAccountsCountLimit,
  } = props;
  const [pendingPayments, setPendingPayments] = React.useState(
    getPendingPayments(billingAccounts[0]),
  );
  const isValidPayment = (account) => {
    return account.billingAccount.openAmt > 0 || account.billingAccount.totalAmt > 0;
  };

  useEffect(() => {
    dispatch(setIsLoading(false));
  }, [errors]);
  useEffect(() => {
    if (!isLoading && !isEmpty(billSelected)) {
      dispatch(setNextButtonDisabled(false));
    }
  }, [billSelected, isLoading]);
  useEffect(() => {
    dispatch(setIsLoading(true));
    async function getCustBillingInfo() {
      await dispatch(
        getCustomerBillingInformation(
          customerId,
          registrationToken,
          hasOpenBalance,
          billingAccountsCountLimit,
        ),
      );
      dispatch(setIsLoading(false));
    }
    if (customerId !== '' && registrationToken !== '') {
      getCustBillingInfo();
    }
  }, [registrationToken]);

  const setBill = (bill) => {
    if (bill) {
      dispatch(setBillSelected(bill));
    }
  };

  const nextButtonClickHandler = () => {
    let customer = restructureCustomerForPayment(billSelected.billingAccount);
    dispatch(setCustomer(customer));
    dispatch(setPaymentConfigId(determineAnonymousPaymentConfigId(paymentConfigIds, billSelected)));
    dispatch(setNextButtonClicked(false));
    dispatch(setActiveStep(2));
  };
  useEffect(() => {
    if (activeStep === 1 && nextButtonClicked === true) {
      nextButtonClickHandler();
    }
  }, [nextButtonClicked, activeStep]);

  const resetAmountToPay = () => {
    if (amountToPay !== undefined) {
      dispatch(setAmountToPay(undefined));
    }
  };

  return (
    <>
      <Helmet>
        <title>Make Payment - Select Amount</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {billingAccounts && billingAccounts.length > 0 && !isLoading ? (
            <>
              {i18n?.makePaymentAnonymous?.stepTwo?.instructions && (
                <InstructionsContainer
                  dangerouslySetInnerHTML={{
                    __html: i18n.makePaymentAnonymous.stepTwo.instructions,
                  }}
                />
              )}
              <h3>
                Choose a policy to pay
                <TooltipInfo
                  title={getText(i18n, 'makePaymentAnonymous.stepTwo.billToPayTooltip')}
                />
              </h3>
              <PolicyToPayContainer>
                {billingAccounts.map((account) => {
                  return (
                    <label key={account.billingAccount.id}>
                      <PolicyCard>
                        <PolicyAndCurrentDueContainer>
                          <RadioChoice
                            aria-label={`Choose a Policy to Pay ${account.billingAccount.accountDisplayNumber}`}
                            id={'RadioInput' + account.billingAccount.accountDisplayNumber}
                            checked={(billSelected && account.ref === billSelected.ref) || false}
                            onChange={() => {
                              setBill(account);
                              resetAmountToPay();
                              setPendingPayments(getPendingPayments(account));
                            }}
                          />
                          <PolicyHeader>{account.billingAccount.accountDisplayNumber}</PolicyHeader>
                          <CurrentDueContainer>
                            <PolicySubHeaders>Current Due</PolicySubHeaders>
                            <CurrentDueAmount>
                              {account.billingAccount.openAmt
                                ? `$${account.billingAccount.openAmt}`
                                : null}
                            </CurrentDueAmount>
                          </CurrentDueContainer>
                        </PolicyAndCurrentDueContainer>
                        {isValidPayment(account) ? (
                          <DatesAndAmountsContainer>
                            <GridSpacingDesktopOne />
                            <GridSpacingDesktopTwo />
                            <div>
                              <PolicySubHeaders>Bill To</PolicySubHeaders>
                              <PolicyValueHeader>
                                {account.billingAccount.billToCd
                                  ? account.billingAccount.billToCd
                                  : null}
                              </PolicyValueHeader>
                            </div>
                            <div>
                              <PolicySubHeaders>Payoff Amount</PolicySubHeaders>
                              <PayoffAmountHeader>
                                {account.billingAccount.totalAmt
                                  ? `$${account.billingAccount.totalAmt}`
                                  : null}
                              </PayoffAmountHeader>
                            </div>
                          </DatesAndAmountsContainer>
                        ) : (
                          <NoPaymentDueText>
                            Your account does not currently have a payment due.
                          </NoPaymentDueText>
                        )}
                      </PolicyCard>
                    </label>
                  );
                })}
              </PolicyToPayContainer>
              {pendingPayments && (
                <PendingPaymentBanner
                  isReview={false}
                  accountToPay={billSelected.fullAccount.pendingReceipts}
                />
              )}
            </>
          ) : (
            <>
              {errors ? (
                <>
                  <h3>
                    {' '}
                    We are unable to verify your registration at this time, please contact your
                    agent or try again later.
                  </h3>
                  <ReturnHomeButton to="/">Return Home</ReturnHomeButton>
                </>
              ) : (
                <>
                  <h3>There are no bills that need to be paid at this time.</h3>
                  <ReturnHomeButton to="/">Return Home</ReturnHomeButton>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

Step2.displayName = 'MakePaymentAnonymousStep2';
Step2.propTypes = {
  activeStep: PropTypes.number,
  amountToPay: PropTypes.string,
  billSelected: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.any,
  nextButtonClicked: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
  dispatch: PropTypes.any,
  paymentConfigIds: PropTypes.any,
  registrationToken: PropTypes.string,
  handleNext: PropTypes.func,
  billingAccounts: PropTypes.array,
  customerId: PropTypes.string,
  i18n: PropTypes.object,
  hasOpenBalance: PropTypes.bool,
  billingAccountsCountLimit: PropTypes.string,
};
Step2.displayName = 'MakePaymentAnonymousStep2';
export const mapStateToProps = (state) => ({
  billingAccounts: getAccountsWithABill(state.billingSlice.billingAccounts),
  activeStep: state.makePaymentAnonymousSlice.activeStep,
  amountToPay: state.makePaymentAnonymousSlice.amountToPay,
  billSelected: state.makePaymentAnonymousSlice.billSelected,
  isLoading: state.makePaymentAnonymousSlice.isLoading,
  nextButtonClicked: state.makePaymentAnonymousSlice.nextButtonClicked,
  nextButtonDisabled: state.makePaymentAnonymousSlice.nextButtonDisabled,
  customerId: state.loginSlice.customerId,
  registrationToken: state.loginSlice.registrationToken,
  errors: state.loginSlice.errors,
  paymentConfigIds: state.configurationSlice.features.paymentConfigIds,
  hasOpenBalance: state.configurationSlice.features.features.hasOpenBalance,
  i18n: state.i18nSlice.i18n,
  billingAccountsCountLimit: state.configurationSlice.features.billingAccountsCountLimit,
  state,
});
export default connect(mapStateToProps)(Step2);
