import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { Helmet } from 'react-helmet';

import Loader from '../../../common/loader/index';
import {
  AmountContainer,
  AmountHeader,
  AmountText,
  DateChoice,
  DateContainer,
  FullAmountCard,
  InformationArea,
  InstructionsContainer,
  MinimumAmountCard,
  OtherAmountCard,
  PartialAmountInput,
  PayDateHeader,
  PaymentContainer,
  PaymentTypeContainer,
  PaymentTypeHeader,
  RadioChoice,
  StyledInfoIcon,
  SubHeader,
} from './styles';
import {
  setIsLoading,
  setPayDate,
  setPaymentAmountToPay,
  setPaymentInstrument,
  setRadioAmountType,
} from './makePaymentStepTwoReducer';
import { AmountDisclaimerContainer, AmountDisclaimerText } from '../styles';
import { ISO_DATE_FORMAT } from '../../../common/constants';
import FundingInstruments from './fundingInstruments';
import TooltipInfo from '../../../common/tooltip';
import { getText } from '../../../../utils/i18n';
import { setNextButtonDisabled, setNextButtonClicked, setActiveStep } from '../makePaymentReducer';
import { useSnackbar } from 'notistack';

export const MakeAPaymentStepTwo = (props) => {
  const {
    accountToPayWith,
    activeStep,
    amountToPay,
    dispatch,
    instrumentToPayWith,
    isLoading,
    nextButtonClicked,
    otherAmountAllowed,
    radioAmountType,
    schedulePaymentsAllowed,
    i18n,
  } = props;

  React.useEffect(() => {
    dispatch(setIsLoading(true));
  }, [accountToPayWith]);

  const isStatementAccount = accountToPayWith.billingAccount.accountNumber.slice(0, 2) === 'SA';
  let [paymentAmount, setPaymentAmount] = React.useState('');
  let [radioAmountChecked, setRadioAmountChecked] = React.useState(radioAmountType);
  let [dateChoice, setDateChoice] = React.useState(moment().format(ISO_DATE_FORMAT));
  const { openAmt, totalAmt } = accountToPayWith.billingAccount;
  let [partialAmount, setPartialAmount] = React.useState(
    amountToPay && amountToPay !== totalAmt ? amountToPay : '',
  );
  const { enqueueSnackbar } = useSnackbar();
  const isValidPartialAmt = (amount) => {
    return (
      Number(amount) >= Number(openAmt) &&
      Number(amount) <= Number(totalAmt) &&
      Number(amount) !== 0
    );
  };
  useEffect(() => {
    if (
      radioAmountChecked === -1 ||
      (radioAmountChecked === 2 && !isValidPartialAmt(partialAmount)) ||
      !instrumentToPayWith
    ) {
      dispatch(setNextButtonDisabled(true));
    } else {
      dispatch(setNextButtonDisabled(false));
    }
  }, [radioAmountChecked, instrumentToPayWith, partialAmount]);
  const nextButtonClickHandler = () => {
    if (radioAmountChecked === 2) {
      dispatch(setPaymentAmountToPay(partialAmount));
      dispatch(setPayDate(dateChoice));
    } else if (radioAmountChecked === 1) {
      dispatch(setPaymentAmountToPay(openAmt));
      dispatch(setPayDate(dateChoice));
    } else {
      dispatch(setPaymentAmountToPay(totalAmt));
      dispatch(setPayDate(dateChoice));
    }

    let newInstrument = cloneDeep(instrumentToPayWith);
    dispatch(setPaymentInstrument(newInstrument));
    dispatch(setNextButtonClicked(false));
    dispatch(setRadioAmountType(radioAmountChecked));
    dispatch(setActiveStep(2));
  };
  useEffect(() => {
    if (activeStep === 1 && nextButtonClicked === true) {
      nextButtonClickHandler();
    }
  }, [nextButtonClicked, activeStep]);
  return (
    <PaymentContainer>
      <Helmet>
        <title>Make Payment - Select Amount and Payment Method</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <SubHeader>Select Date</SubHeader>
          {i18n?.makePayment?.stepTwo?.instructions && (
            <InstructionsContainer
              dangerouslySetInnerHTML={{ __html: i18n.makePayment.stepTwo.instructions }}
            />
          )}
          <DateContainer>
            <FullAmountCard>
              <PayDateHeader>
                Payment Date
                <TooltipInfo title={getText(i18n, 'makePayment.stepTwo.paymentDateTooltip')} />
                <DateChoice
                  id={'PaymentDate'}
                  disabled={!schedulePaymentsAllowed}
                  value={dateChoice}
                  min={moment().format(ISO_DATE_FORMAT)}
                  max={'3000-01-01'}
                  onChange={(e) => setDateChoice(e.target.value)}
                />
              </PayDateHeader>
            </FullAmountCard>
          </DateContainer>
          <SubHeader>Select Amount</SubHeader>
          {i18n?.makePayment?.stepTwo?.selectAmountInstructions && (
            <InstructionsContainer
              dangerouslySetInnerHTML={{
                __html: i18n.makePayment.stepTwo.selectAmountInstructions,
              }}
            />
          )}
          <AmountContainer>
            <label htmlFor="PayoffAmountRadio">
              <FullAmountCard>
                <RadioChoice
                  aria-label="Select Amount Payoff Amount"
                  id={'PayoffAmountRadio'}
                  checked={radioAmountChecked === 0}
                  onChange={() => {
                    setPaymentAmount(totalAmt);
                    setRadioAmountChecked(0);
                  }}
                />
                <AmountHeader>
                  Payoff Amount
                  <TooltipInfo title={getText(i18n, 'makePayment.stepTwo.payOffAmountTooltip')} />
                </AmountHeader>
                <AmountText id={'PayoffAmount'}>${totalAmt}</AmountText>
              </FullAmountCard>
            </label>
            {openAmt !== '0.00' && (
              <label htmlFor="CurrentAmountDueRadio">
                <MinimumAmountCard>
                  <RadioChoice
                    aria-label="Select Amount Current Due"
                    id={'CurrentAmountDueRadio'}
                    checked={radioAmountChecked === 1}
                    onChange={() => {
                      setPaymentAmount(openAmt);
                      setRadioAmountChecked(1);
                    }}
                  />
                  <AmountHeader>Current Due</AmountHeader>
                  <AmountText id={'CurrentDueAmount'}>${openAmt}</AmountText>
                </MinimumAmountCard>
              </label>
            )}
            {otherAmountAllowed && !isStatementAccount && (
              <label htmlFor="PartialAmountRadio">
                <OtherAmountCard>
                  <RadioChoice
                    aria-label="Select Amount Partial Amount"
                    id={'PartialAmountRadio'}
                    checked={radioAmountChecked === 2}
                    onChange={() => {
                      setRadioAmountChecked(2);
                    }}
                  />
                  <AmountHeader>
                    {' '}
                    Partial Amount
                    <TooltipInfo
                      title={getText(i18n, 'makePayment.stepTwo.partialAmountTooltip')}
                    />
                  </AmountHeader>
                  <PartialAmountInput
                    type="number"
                    aria-label="Partial Amount"
                    value={partialAmount}
                    onChange={(e) => {
                      setPartialAmount(e.target.value);
                      setRadioAmountChecked(2);
                    }}
                    onBlur={() => {
                      setPartialAmount(parseFloat(partialAmount).toFixed(2));
                      if (!isValidPartialAmt(partialAmount)) {
                        enqueueSnackbar(
                          i18n?.payment?.partialAmountMessage
                            ? i18n?.payment?.partialAmountMessage
                            : 'Partial Amount must be between current due and payoff amount',
                          {
                            variant: 'error',
                            anchorOrigin: {
                              horizontal: 'center',
                              vertical: 'top',
                            },
                          },
                        );
                      }
                    }}
                  />
                  <InformationArea>
                    <StyledInfoIcon />
                    Partial amount must be between payoff amount and current due.
                  </InformationArea>
                </OtherAmountCard>
              </label>
            )}
          </AmountContainer>
          <PaymentTypeContainer>
            <PaymentTypeHeader>Payment Type</PaymentTypeHeader>
            {i18n?.makePayment?.stepTwo?.genericPaymentTypeInstructions && (
              <InstructionsContainer
                dangerouslySetInnerHTML={{
                  __html: i18n.makePayment.stepTwo.genericPaymentTypeInstructions,
                }}
              />
            )}
            {instrumentToPayWith &&
              (instrumentToPayWith?.methodCd == 'Credit Card' ||
                instrumentToPayWith?.sourceType === 'CreditCard') &&
              i18n?.makePayment?.stepTwo?.creditCardSpecificInstructions && (
                <InstructionsContainer
                  dangerouslySetInnerHTML={{
                    __html: i18n.makePayment.stepTwo.creditCardSpecificInstructions,
                  }}
                />
              )}
            <FundingInstruments />
          </PaymentTypeContainer>
          <AmountDisclaimerContainer>
            <AmountDisclaimerText>
              <StyledInfoIcon />
              {getText(
                i18n,
                'general.paymentAmountDisclaimer',
                'Final payment amount may change depending on the payment type selected and applicable fees.',
              )}
            </AmountDisclaimerText>
          </AmountDisclaimerContainer>
        </>
      )}
    </PaymentContainer>
  );
};
MakeAPaymentStepTwo.propTypes = {
  accountToPayWith: PropTypes.object,
  activeStep: PropTypes.number,
  amountToPay: PropTypes.string,
  dispatch: PropTypes.func,
  instrumentToPayWith: PropTypes.object,
  isLoading: PropTypes.bool,
  nextButtonClicked: PropTypes.bool,
  otherAmountAllowed: PropTypes.bool,
  paymentTenantName: PropTypes.string,
  paymentConfigId: PropTypes.object,
  paymentApiUrl: PropTypes.string,
  radioAmountType: PropTypes.number,
  schedulePaymentsAllowed: PropTypes.any,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  accountToPayWith: state.makePaymentSlice.accountToPayWith,
  activeStep: state.makePaymentSlice.activeStep,
  amountToPay: state.makePaymentStepTwoSlice.amountToPay,
  otherAmountAllowed: state.configurationSlice.features.features.otherPaymentAmountAllowed,
  instrumentToPayWith: state.makePaymentStepTwoSlice.instrumentToPayWith,
  nextButtonClicked: state.makePaymentSlice.nextButtonClicked,
  schedulePaymentsAllowed: state.configurationSlice.features.features.scheduledPayments,
  isLoading: state.makePaymentStepTwoSlice.isLoading,
  paymentTenantName: state.configurationSlice.features.paymentTenantName,
  paymentConfigId: state.configurationSlice.features.paymentConfigIds,
  paymentApiUrl: state.configurationSlice.features.paymentApiUrl,
  radioAmountType: state.makePaymentStepTwoSlice.radioAmountType,
  i18n: state.i18nSlice.i18n,
});
export default connect(mapStateToProps)(MakeAPaymentStepTwo);
