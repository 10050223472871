import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setEditingRegistrationEmail, setEmailErrors } from './profileReducer';
import {
  EditRegistrationEmailAddressLink,
  EditEmailButtonContainer,
  EmailAddressLabel,
  EmailContainer,
  EmailError,
  ProfileRegistrationEmailsAddress,
} from './styles';
import TooltipInfo from '../../common/tooltip';
import EditRegistrationEmail from './editRegistrationEmail';

export const RegistrationEmailDetails = (props) => {
  const { dispatch, editingEmail, emailErrors, isDashboard, userEmail } = props;

  React.useEffect(() => {
    props.dispatch(setEmailErrors(null));
  }, []);

  return (
    <>
      {emailErrors && <EmailError>{emailErrors}</EmailError>}
      <EmailContainer>
        <EmailAddressLabel>
          Registration Email
          <TooltipInfo title="This email address is used for account registration and may be used to send information for authentication purposes." />
          <ProfileRegistrationEmailsAddress>
            {userEmail && userEmail !== '' ? userEmail : 'None'}
          </ProfileRegistrationEmailsAddress>
        </EmailAddressLabel>
        <EditEmailButtonContainer>
          {!editingEmail && (
            <EditRegistrationEmailAddressLink
              onClick={() => dispatch(setEditingRegistrationEmail(true))}
            >
              Edit
            </EditRegistrationEmailAddressLink>
          )}
        </EditEmailButtonContainer>
      </EmailContainer>
      {editingEmail ? <EditRegistrationEmail isDashboard={isDashboard} /> : null}
    </>
  );
};

RegistrationEmailDetails.propTypes = {
  dispatch: PropTypes.func,
  emailAddress: PropTypes.string,
  emailErrors: PropTypes.any,
  editingEmail: PropTypes.bool,
  userEmail: PropTypes.string,
  isDashboard: PropTypes.bool,
  isLoading: PropTypes.bool,
  notificationEmail: PropTypes.string,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  isLoading: state.profileSlice.isLoading,
  emailErrors: state.profileSlice.emailErrors,
  editingEmail: state.profileSlice.editingRegistrationEmail,
  userEmail: state.loginSlice.user.email,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(RegistrationEmailDetails);
