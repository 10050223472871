import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { getPendingPayments, getCarrierCd } from '../../../common/payment/selectors';
import {
  AmountContainer,
  AmountDetails,
  AmountHeader,
  DateHeader,
  DateOfPayment,
  InstructionsContainer,
  InstrumentContainer,
  InstrumentDetails,
  InstrumentHeader,
  PayDateContainer,
  PaymentContainer,
  StepThreeCards,
  StyledACHIcon,
  StyledCreditIcon,
  StyledInfoIcon,
} from './styles';

import { AmountDisclaimerContainer, AmountDisclaimerText } from '../styles';
import { makePayment } from './makePaymentApi';
import { configurePaymentBody } from './utils';
import { DISPLAY_DATE_FORMAT } from '../../../common/constants';
import { getText } from '../../../../utils/i18n';
import PendingPaymentBanner from '../../../common/payment/pending-payment';
import { setActiveStep, setNextButtonClicked, setNextButtonDisabled } from '../makePaymentReducer';
import MakeAPaymentStepFour from '../step-four-recap';

export const MakeAPaymentStepThree = (props) => {
  const {
    activeStep,
    acceptedPendingPaymentMessage,
    amountToPay,
    instrumentToPayWith,
    nextButtonClicked,
    pendingPayments,
    dispatch,
    accountId,
    customerId,
    payDate,
    paymentServiceAccountId,
    i18n,
  } = props;

  useEffect(() => {
    if (amountToPay <= 0 || (pendingPayments && !acceptedPendingPaymentMessage)) {
      dispatch(setNextButtonDisabled(true));
    } else {
      dispatch(setNextButtonDisabled(false));
    }
  }, [pendingPayments, acceptedPendingPaymentMessage]);
  const paymentInfo = () => {
    return instrumentToPayWith.methodCd === 'ACH' || instrumentToPayWith.sourceType === 'ACH'
      ? instrumentToPayWith.achBankAccountTypeCd
      : `${instrumentToPayWith.cardType ? instrumentToPayWith.cardType : 'Credit Card'} ${
          instrumentToPayWith.lastFour
            ? ` - ${instrumentToPayWith.lastFour}`
            : instrumentToPayWith.creditCardNumber
            ? ` - ${instrumentToPayWith.creditCardNumber}`
            : ''
        }`;
  };
  useEffect(() => {
    if (activeStep === 2 && nextButtonClicked === true) {
      const paymentBody = configurePaymentBody(
        instrumentToPayWith,
        payDate,
        amountToPay,
        customerId,
        accountId,
        paymentServiceAccountId,
      );

      dispatch(makePayment(accountId, paymentBody, payDate));
      dispatch(setNextButtonClicked(false));
      dispatch(setActiveStep(3));
    }
  }, [nextButtonClicked]);
  return (
    <>
      {activeStep === 2 ? (
        <PaymentContainer>
          <Helmet>
            <title>Make Payment - Review and Make Payment</title>
          </Helmet>
          {i18n?.makePayment?.stepThree?.instructions && (
            <InstructionsContainer
              dangerouslySetInnerHTML={{ __html: i18n.makePayment.stepThree.instructions }}
            />
          )}
          <StepThreeCards>
            <AmountContainer>
              <AmountHeader>Amount To Be Paid </AmountHeader>
              <AmountDetails> ${parseFloat(amountToPay).toFixed(2)}</AmountDetails>
            </AmountContainer>
            <InstrumentContainer>
              <InstrumentHeader>Will be paid by</InstrumentHeader>

              <InstrumentDetails>
                {instrumentToPayWith.methodCd === 'ACH' ||
                instrumentToPayWith.sourceType === 'ACH' ? (
                  <StyledACHIcon />
                ) : (
                  <StyledCreditIcon />
                )}
                {paymentInfo()}
              </InstrumentDetails>
            </InstrumentContainer>
            <PayDateContainer>
              <DateHeader>Payment Date</DateHeader>
              <DateOfPayment>
                {payDate
                  ? moment(payDate).format(DISPLAY_DATE_FORMAT)
                  : moment().format(DISPLAY_DATE_FORMAT)}
              </DateOfPayment>
            </PayDateContainer>
          </StepThreeCards>
          <AmountDisclaimerContainer>
            <AmountDisclaimerText>
              <StyledInfoIcon />
              {getText(
                i18n,
                'general.paymentAmountDisclaimer',
                'Final payment amount may change depending on the payment type selected and applicable fees.',
              )}
            </AmountDisclaimerText>
          </AmountDisclaimerContainer>
          {pendingPayments && !acceptedPendingPaymentMessage ? (
            <PendingPaymentBanner isReview={true} />
          ) : null}
        </PaymentContainer>
      ) : (
        <MakeAPaymentStepFour />
      )}
    </>
  );
};
MakeAPaymentStepThree.propTypes = {
  acceptedPendingPaymentMessage: PropTypes.bool,
  accountId: PropTypes.string,
  activeStep: PropTypes.number,
  amountToPay: PropTypes.string,
  billingAccounts: PropTypes.array,
  customerId: PropTypes.string,
  dispatch: PropTypes.func,
  instrumentToPayWith: PropTypes.object,
  nextButtonClicked: PropTypes.bool,
  payDate: PropTypes.string,
  pendingPayments: PropTypes.bool,
  paymentServiceAccountId: PropTypes.string,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  activeStep: state.makePaymentSlice.activeStep,
  acceptedPendingPaymentMessage: state.makePaymentSlice.acceptedPendingPaymentMessage,
  accountId: state.makePaymentSlice.accountToPayWith.billingAccount.systemId,
  amountToPay: state.makePaymentStepTwoSlice.amountToPay,
  billingAccounts: state.billingSlice.billingAccounts,
  customerId: state.customerSlice.customer.systemId,
  instrumentToPayWith: state.makePaymentStepTwoSlice.instrumentToPayWith,
  isLoading: state.makePaymentStepFourSlice.isLoading,
  nextButtonClicked: state.makePaymentSlice.nextButtonClicked,
  payDate: state.makePaymentStepTwoSlice.payDate,
  pendingPayments: getPendingPayments(state.makePaymentSlice.accountToPayWith),
  paymentServiceAccountId: getCarrierCd(state.makePaymentSlice.accountToPayWith),
  i18n: state.i18nSlice.i18n,
});
export default connect(mapStateToProps)(MakeAPaymentStepThree);
