import React from 'react';
import Home from '../components/index';
import IdCards from '../components/features/id-cards';
import AccountDetails from '../components/features/account-details';
import UserProfile from '../components/features/user-profile';
import PolicyDetails from '../components/features/policy-details';
import ClaimDetails from '../components/features/claim-details';
import NeedHelp from '../components/features/need-help';
import ReportClaim from '../components/features/report-claim';
import ChangeCoverage from '../components/features/change-coverage';
import MakeAPayment from '../components/features/make-payment';
import PendingRequests from '../components/features/pending-requests';
import OpenQuotes from '../components/features/open-quotes';
import CustomPage1 from '../components/features/custom-pages/custom-page-1';
import CustomPage2 from '../components/features/custom-pages/custom-page-2';
import CustomerInformation from '../components/features/customer-information';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import ErrorPage from '../components/common/error';
import { LoginCallback } from '@okta/okta-react';
import CreateAccount from '../components/features/create-account';
import MakePaymentAnonymous from '../components/features/make-payment-anonymous';
import ReportClaimAnonymous from '../components/features/report-claim-anonymous';
import ForgotUsername from '../components/features/forgot-username';
import ForgotPassword from '../components/features/forgot-password';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Home />}>
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/" element={<Home />} />
      <Route path="/login/callback" element={<LoginCallback />} />
      <Route path="/createAccount" element={<CreateAccount />} />
      <Route path="/makePaymentAnonymous" element={<MakePaymentAnonymous />} />
      <Route path="/reportClaimNotLoggedIn" element={<ReportClaimAnonymous />} />
      <Route path="/forgotUsername" element={<ForgotUsername />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/dashboard" element={<Home />} />
      <Route path="/accountDetails" element={<AccountDetails />} />
      <Route path="/idCards" element={<IdCards />} />
      <Route path="/policyDetails" element={<PolicyDetails />} />
      <Route path="/userProfile" element={<UserProfile />} />
      <Route path="/claimDetails" element={<ClaimDetails />} />
      <Route path="/needHelp" element={<NeedHelp />} />
      <Route path="/reportClaim" element={<ReportClaim />} />
      <Route path="/changeCoverage" element={<ChangeCoverage />} />
      <Route path="/makePayment" element={<MakeAPayment />} />
      <Route path="/pendingRequests" element={<PendingRequests />} />
      <Route path="/openQuotes" element={<OpenQuotes />} />
      <Route path="/customPage1" element={<CustomPage1 />} />
      <Route path="/customePage2" element={<CustomPage2 />} />
      <Route path="/customerInformation" element={<CustomerInformation />} />
    </Route>,
  ),
);
