import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import {
  AnonymousReportClaimsStepSixContainer,
  HomeScreenButton,
  RecapDesktopLabel,
  RecapText,
  StyledReportClaimHeader,
  StyledSubSubHeader,
} from './styles';
import { useNavigate } from 'react-router';
import { gaEvent } from '../../../../utils/google-analytics';
import { LoaderMargin } from '../recap-and-submit/styles';
import Loader from '../../../common/loader';
import { ClaimEventNameLabel } from '../step4/styles';
import { ga4Event } from '../../../../utils/google-analytics-4';
export const Submitted = (props) => {
  const { claimDescription, claimEvent, claimSubmission, isClaimEventValidated, referenceNumber } =
    props;

  useEffect(() => {
    gaEvent({ category: 'report-a-claim', action: 'complete' });
    ga4Event({ category: 'report-a-claim', action: 'complete' });
  }, []);

  const navigate = useNavigate();

  const navigateToSignInPage = () => {
    navigate('/');
  };

  return (
    <>
      <Helmet>
        <title>Report Claim - Confirmation</title>
      </Helmet>
      {!isClaimEventValidated && (
        <LoaderMargin>
          <Loader />
          <RecapDesktopLabel>Uploading file(s)...</RecapDesktopLabel>
        </LoaderMargin>
      )}
      <AnonymousReportClaimsStepSixContainer>
        {claimSubmission === 'Success' ? (
          <>
            <StyledReportClaimHeader>Report Claim</StyledReportClaimHeader>
            <ClaimEventNameLabel>{claimEvent && claimDescription}</ClaimEventNameLabel>
            <StyledSubSubHeader>Confirmation</StyledSubSubHeader>
            <RecapText>
              Thank you for submitting your claim report. Your claims adjuster will be contacting
              you shortly. If you have any questions, please contact your Agent. Your reference
              number is {referenceNumber}
            </RecapText>
          </>
        ) : claimSubmission === 'Failure' ? (
          <>
            <StyledReportClaimHeader>Report Claim</StyledReportClaimHeader>
            <ClaimEventNameLabel>{claimEvent && claimDescription}</ClaimEventNameLabel>
            <StyledSubSubHeader id="Error">Error</StyledSubSubHeader>
            <RecapText>
              Your claim report was unable to be submitted at this time. Please try again later or
              contact your Agent.
            </RecapText>
          </>
        ) : null}
        <HomeScreenButton
          id="return"
          onClick={() => {
            navigateToSignInPage();
          }}
        >
          Return Home
        </HomeScreenButton>
      </AnonymousReportClaimsStepSixContainer>
    </>
  );
};
Submitted.propTypes = {
  claimDescription: PropTypes.string,
  claimSubmission: PropTypes.string,
  claimEvent: PropTypes.object,
  dispatch: PropTypes.func,
  isClaimEventValidated: PropTypes.bool,
  referenceNumber: PropTypes.string,
};
const mapStateToProps = (state) => ({
  claimEvent: state.anonymousReportClaimStepThreeSlice.claimEvent,
  claimDescription: state.anonymousReportClaimStepTwoSlice.selectedClaimEventTemplate.description,
  claimSubmission: state.anonymousReportClaimStepSixSlice.claimSubmission,
  isClaimEventValidated: state.anonymousReportClaimStepSixSlice.isClaimEventValidated,
  referenceNumber: state.anonymousReportClaimStepSixSlice.referenceNumber,
});
export default connect(mapStateToProps)(Submitted);
