import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  claimEvent: null,
  updatedClaimEventTemplate: null, // contains dynamic questions
  claimEventContacts: {
    contact: {
      id: '',
      partyTypeCd: '',
      emailInfo: {},
      addresses: [
        {
          addr1: '',
          addr2: '',
          addr3: '',
          addr4: '',
          city: '',
          county: '',
          regionCd: '',
          stateProvCd: '',
          postalCode: '',
        },
      ],
      phoneInfo: [
        { phoneNumber: '', preferredInd: false },
        { phoneNumber: '', preferredInd: false },
        { phoneNumber: '', preferredInd: false },
      ],
    },
  },
  claimEventTemplates: [],
  errors: null,
  isLoading: false,
  selectedClaimEventTemplate: null,
  zipValue: '',
  businessPhoneTemp: '',
  homePhoneTemp: '',
  otherPhoneTemp: '',
  mobilePhoneTemp: '',
  phoneTemp: '',
  phoneTypeField: null,
  bestWayToContact: null,
};

const getAddress1 = (state, action) => {
  return state.claimEventContacts.contact.addresses.map((address) => {
    if (address.addrTypeCd === 'InsuredMailingAddr') {
      return {
        ...address,
        addr1: action.payload,
      };
    }
    return { ...address };
  });
};
const getAddress2 = (state, action) => {
  return state.claimEventContacts.contact.addresses.map((address) => {
    if (address.addrTypeCd === 'InsuredMailingAddr') {
      return {
        ...address,
        addr2: action.payload,
      };
    }
    return { ...address };
  });
};
const getAddress3 = (state, action) => {
  return state.claimEventContacts.contact.addresses.map((address) => {
    if (address.addrTypeCd === 'InsuredMailingAddr') {
      return {
        ...address,
        addr3: action.payload,
      };
    }
    return { ...address };
  });
};
const getAddress4 = (state, action) => {
  return state.claimEventContacts.contact.addresses.map((address) => {
    if (address.addrTypeCd === 'InsuredMailingAddr') {
      return {
        ...address,
        addr4: action.payload,
      };
    }
    return { ...address };
  });
};
const getCountry = (state, action) => {
  return state.claimEventContacts.contact.addresses.map((address) => {
    if (address.addrTypeCd === 'InsuredMailingAddr') {
      return {
        ...address,
        regionCd: action.payload,
      };
    }
    return { ...address };
  });
};
const getBestWayToContact = (state, action) => {
  return state.claimEventContacts.contact.phoneInfo.map((phone) => {
    if (action.payload === phone.phoneName) {
      return {
        ...phone,
        preferredInd: true,
      };
    }
    return { ...phone, preferredInd: false };
  });
};
const setBestWayPhonesFalse = (state) => {
  return state.claimEventContacts.contact.phoneInfo.map((phone) => {
    return { ...phone, preferredInd: false };
  });
};
const getBestWayToContactEmail = (state) => {
  return {
    ...state.claimEventContacts.contact.emailInfo,
    preferredInd: true,
  };
};
const getCounty = (state, action) => {
  return state.claimEventContacts.contact.addresses.map((address) => {
    if (address.addrTypeCd === 'InsuredMailingAddr') {
      return {
        ...address,
        county: action.payload,
      };
    }
    return { ...address };
  });
};
const getCity = (state, action) => {
  return state.claimEventContacts.contact.addresses.map((address) => {
    if (address.addrTypeCd === 'InsuredMailingAddr') {
      return {
        ...address,
        city: action.payload,
      };
    }
    return { ...address };
  });
};
const getState = (state, action) => {
  return state.claimEventContacts.contact.addresses.map((address) => {
    if (address.addrTypeCd === 'InsuredMailingAddr') {
      return {
        ...address,
        stateProvCd: action.payload.value,
      };
    }
    return { ...address };
  });
};
const getPostal = (state, action) => {
  return state.claimEventContacts.contact.addresses.map((address) => {
    if (address.addrTypeCd === 'InsuredMailingAddr') {
      return {
        ...address,
        postalCode: action.payload,
      };
    }
    return { ...address };
  });
};
export const anonymousReportClaimStepFourSlice = createSlice({
  name: 'anonymousReportClaimStepFourSlice',
  initialState,
  reducers: {
    resetStepFourState: () => {
      return initialState;
    },
    setBestWayToContact: (state, action) => {
      if (action.payload === 'Email') {
        return {
          ...state,
          claimEventContacts: {
            ...state.claimEventContacts,
            contact: {
              ...state.claimEventContacts.contact,
              emailInfo: getBestWayToContactEmail(state, action),
              phoneInfo: setBestWayPhonesFalse(state), //set all phones preferred as false
              personInfo: {
                ...state.claimEventContacts.contact.personInfo,
                bestWayToContact: action.payload,
              },
            },
          },
          bestWayToContact: action.payload,
        };
      }
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            phoneInfo: getBestWayToContact(state, action),
            emailInfo: {
              ...state.claimEventContacts.contact.emailInfo,
              preferredInd: false,
            },
            personInfo: {
              ...state.claimEventContacts.contact.personInfo,
              bestWayToContact: action.payload,
            },
          },
        },
        bestWayToContact: action.payload,
      };
    },
    setCityText: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            addresses: getCity(state, action),
          },
        },
      };
    },
    setClaimEventTemplates: (state, action) => {
      return { ...state, claimEventTemplates: action.payload };
    },
    setClaimEvent: (state, action) => {
      return { ...state, claimEvent: action.payload };
    },
    setClaimEventContacts: (state, action) => {
      return { ...state, claimEventContacts: action.payload };
    },
    setClaimEventContactsLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setClaimEventContactsErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setCountyText: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            addresses: getCounty(state, action),
          },
        },
      };
    },
    setCountryText: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            addresses: getCountry(state, action),
          },
        },
      };
    },
    setEmail: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            emailInfo: {
              ...state.claimEventContacts.contact.emailInfo,
              emailAddr: action.payload,
            },
          },
        },
      };
    },
    setLocation1Text: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            addresses: getAddress1(state, action),
          },
        },
      };
    },
    setLocation2Text: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            addresses: getAddress2(state, action),
          },
        },
      };
    },
    setLocation3Text: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            addresses: getAddress3(state, action),
          },
        },
      };
    },
    setLocation4Text: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            addresses: getAddress4(state, action),
          },
        },
      };
    },
    setNewPhones: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            phoneInfo: action.payload,
          },
        },
      };
    },
    setPhoneTemp: (state, action) => {
      return { ...state, phoneTemp: action.payload };
    },
    setPhoneType: (state, action) => {
      return { ...state, phoneType: action.payload };
    },

    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },

    setBusinessPhoneTemp: (state, action) => {
      return { ...state, businessPhoneTemp: action.payload };
    },
    setHomePhoneTemp: (state, action) => {
      return { ...state, homePhoneTemp: action.payload };
    },
    setMobilePhoneTemp: (state, action) => {
      return { ...state, mobilePhoneTemp: action.payload };
    },
    setOtherPhoneTemp: (state, action) => {
      return { ...state, otherPhoneTemp: action.payload };
    },

    setSelectedClaimEventTemplate: (state, action) => {
      return { ...state, selectedClaimEventTemplate: action.payload };
    },
    setSelectedState: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            addresses: getState(state, action),
          },
        },
      };
    },
    setStateText: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            addresses: getState(state, action),
          },
        },
      };
    },
    setPostalCode: (state, action) => {
      return {
        ...state,
        claimEventContacts: {
          ...state.claimEventContacts,
          contact: {
            ...state.claimEventContacts.contact,
            addresses: getPostal(state, action),
          },
        },
      };
    },
    setUpdatedClaimEventTemplate: (state, action) => {
      return { ...state, updatedClaimEventTemplate: action.payload };
    },
    setClaimEventDynamicQuestions: (state, action) => {
      return { ...state, claimEventDynamicQuestions: action.payload };
    },
    setClaimEventDynamicQuestionsErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
  },
});
export const {
  resetStepFourState,
  setBestWayToContact,
  setCityText,
  setCountyText,
  setCountryText,
  setClaimEventContacts,
  setErrors,
  setEmail,
  setLocation1Text,
  setLocation2Text,
  setLocation3Text,
  setLocation4Text,
  setPhoneTemp,
  setPhoneType,
  setNewPhones,
  setPostalCode,
  setSelectedState,
  setStateText,
  setUpdatedClaimEventTemplate,
  setClaimEventDynamicQuestions,
  setClaimEventDynamicQuestionsErrors,
} = anonymousReportClaimStepFourSlice.actions;
export default anonymousReportClaimStepFourSlice.reducer;
