import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../../config/i18n/en.json';

const initialState = {
  i18n: i18n,
};

export const i18nSlice = createSlice({
  name: 'i18nSlice',
  initialState,
  reducers: {
    setI18n: (state, action) => {
      return { ...state, i18n: action.payload };
    },
  },
});

export const { setI18n } = i18nSlice.actions;
export default i18nSlice.reducer;
