import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { getClaimEventTemplates } from './anonymousReportClaimStepTwoApi';
import {
  EmergencyMessageComponent,
  LocationEvent,
  LossEventMessage,
  ReportClaimsSelectionHeader,
} from './styles';

import {
  ReportClaimButton,
  ReportClaimButtonContainer,
  ReportClaimButtonForError,
  ReportClaimNextButton,
} from '../step1/styles';
import { setErrors, setSelectedClaimEventTemplate } from './anonymousReportClaimStepTwoReducer';
import {
  setClaimEvent,
  setDateValue,
  setTimeValue,
} from '../step3/anonymousReportClaimStepThreeReducer';
import {
  ReportClaimStepTwoField,
  ReportClaimsTitleHeader,
  ReportClaimTwoFormContainer,
  RequiredField,
} from '../step3/styles';
import { ISO_DATE_FORMAT, TIME_FORMAT_24_HRS } from '../../../common/constants';
import ApiError from '../../../common/api-error';
import { getText } from '../../../../utils/i18n';
import TooltipInfo from '../../../common/tooltip';
import { ReportClaimCancelButton } from '../styles';
import { useNavigate } from 'react-router';

export const Step2 = (props) => {
  const {
    claimEventTemplates,
    selectedClaimEventTemplate,
    handleNext,
    handleStep,
    registrationToken,
    customerId,
    errors,
    dateValue,
    timeValue,
    features,
    i18n,
  } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);

  useEffect(() => {
    props.dispatch(setClaimEvent(null));
    props.dispatch(setErrors(null));
    props.dispatch(getClaimEventTemplates(customerId, registrationToken));
    if (dateValue === '') {
      props.dispatch(
        setDateValue(features.defaultLossDateTime ? moment().format(ISO_DATE_FORMAT) : ''),
      );
    }
    if (timeValue === '') {
      props.dispatch(
        setTimeValue(features.defaultLossDateTime ? moment().format(TIME_FORMAT_24_HRS) : ''),
      );
    }
  }, []);

  const nextButtonClickHandler = () => {
    handleNext();
  };

  const onClaimEventTemplateChange = (selectedOption) => {
    if (selectedOption) {
      props.dispatch(setSelectedClaimEventTemplate(selectedOption));
    }
  };

  const isRequiredFieldsCompleted = () => {
    const selectedDateTime = moment(new Date(dateValue + 'T' + timeValue));
    return (
      dateValue.length > 0 &&
      timeValue.length > 0 &&
      !selectedDateTime.isAfter(moment()) &&
      !!selectedClaimEventTemplate
    );
  };
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Report Claim - Select Loss Event</title>
      </Helmet>
      {errors ? (
        <>
          <ApiError body="Your request cannot be processed at this time." />
          <ReportClaimButtonForError
            id="Previous"
            onClick={() => {
              handleStep();
            }}
          >
            Back
          </ReportClaimButtonForError>
        </>
      ) : (
        <>
          {i18n?.general?.emergencyMessage ? (
            <EmergencyMessageComponent
              dangerouslySetInnerHTML={{
                __html: getText(i18n, 'general.emergencyMessage', ''),
              }}
            />
          ) : null}
          {i18n?.general?.lossMessage ? (
            <LossEventMessage
              dangerouslySetInnerHTML={{
                __html: getText(i18n, 'general.lossMessage', ''),
              }}
            />
          ) : null}
          <ReportClaimsSelectionHeader>
            Loss Event <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'reportClaimAnonymous.stepTwo.selectLossEventTooltip')}
            />
          </ReportClaimsSelectionHeader>
          <LocationEvent
            name="lossEventList"
            id="claimEventTemplate"
            options={claimEventTemplates}
            value={
              selectedClaimEventTemplate
                ? {
                    label: selectedClaimEventTemplate.label,
                    value: selectedClaimEventTemplate,
                  }
                : null
            }
            onChange={(claimEventTemplate) => {
              onClaimEventTemplateChange(claimEventTemplate);
            }}
          />
          <ReportClaimTwoFormContainer>
            <ReportClaimStepTwoField>
              <ReportClaimsTitleHeader htmlFor="lossDate">
                Date of Incident <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'reportClaimAnonymous.stepTwo.dateOfIncidentTooltip')}
                />
              </ReportClaimsTitleHeader>
              <input
                type="date"
                value={dateValue}
                aria-label="Date of Incident"
                max={todayDt}
                onChange={(e) => {
                  props.dispatch(setDateValue(e.target.value));
                }}
              />
            </ReportClaimStepTwoField>
            <ReportClaimStepTwoField>
              <ReportClaimsTitleHeader htmlFor="timeOfIncidentTextBox">
                Time of Incident Event <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'reportClaimAnonymous.stepTwo.timeOfIncidentTooltip')}
                />
              </ReportClaimsTitleHeader>
              <input
                type="time"
                name="timeOfIncidentTextBox"
                value={timeValue}
                onChange={(e) => {
                  props.dispatch(
                    setTimeValue(moment(e.target.value, 'h:mm a').format(TIME_FORMAT_24_HRS)),
                  );
                }}
              />
            </ReportClaimStepTwoField>
          </ReportClaimTwoFormContainer>
          <ReportClaimButtonContainer>
            <ReportClaimCancelButton onClick={() => navigate('/')}>Cancel</ReportClaimCancelButton>
            <ReportClaimNextButton
              id="Next"
              disabled={!isRequiredFieldsCompleted()}
              onClick={() => {
                nextButtonClickHandler();
              }}
            >
              Next
            </ReportClaimNextButton>
            <ReportClaimButton
              id="Previous"
              onClick={() => {
                handleStep();
              }}
            >
              Back
            </ReportClaimButton>
          </ReportClaimButtonContainer>
        </>
      )}
    </>
  );
};

Step2.propTypes = {
  dispatch: PropTypes.any,
  claimEventTemplates: PropTypes.array,
  selectedClaimEventTemplate: PropTypes.object,
  handleNext: PropTypes.func,
  handleStep: PropTypes.func,
  registrationToken: PropTypes.string,
  customerId: PropTypes.string,
  errors: PropTypes.string,
  dateValue: PropTypes.string,
  timeValue: PropTypes.string,
  features: PropTypes.object,
  i18n: PropTypes.object,
};

export const mapStateToProps = (state) => ({
  claimEventTemplates: state.anonymousReportClaimStepTwoSlice.claimEventTemplates,
  errors: state.anonymousReportClaimStepTwoSlice.errors,
  selectedClaimEventTemplate: state.anonymousReportClaimStepTwoSlice.selectedClaimEventTemplate,
  registrationToken: state.loginSlice.registrationToken,
  customerId: state.loginSlice.customerId,
  dateValue: state.anonymousReportClaimStepThreeSlice.dateValue,
  timeValue: state.anonymousReportClaimStepThreeSlice.timeValue,
  features: state.configurationSlice.features.features,
  i18n: state.i18nSlice.i18n,
  state,
});
export default connect(mapStateToProps)(Step2);
