import styled from 'styled-components';

export const ChangePropertyMortgageeContainer = styled.div.attrs({
  id: 'ChangePropertyMortgageeContainer',
})`
  width: 100%;
`;
export const TwoColumnContainer = styled.div.attrs({})`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ChangePropertyMortgageeField = styled.div.attrs({})`
  width: 50%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ChangePropertyMortgageeFieldHeader = styled.h4.attrs({
  id: 'ChangePropertyMortgageeFieldHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const ChangeMortgageeButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
  display: flex;
  margin-top: 15px;
  @media (max-width: 384px) {
    text-align: center;
  }
`;
export const ChangeMortgageeButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 384px) {
    margin-bottom: 10px;
  }
`;

export const ChangeMortgageeNextButton = styled(ChangeMortgageeButton).attrs({ id: 'Next' })``;
export const ChangeMortgageeSubmitButton = styled(ChangeMortgageeButton).attrs({ id: 'Submit' })``;

export const ChangeMortgageeTextAreaField = styled.textarea.attrs((props) => ({
  id: props.id,
}))`
  height: 100px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ChangeMortgageeSubHeader = styled.h4.attrs({
  id: 'ChangeMortgageeSubHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.001rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ChangeMortgageeTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ChangeMortgageeNameInputField = styled(ChangeMortgageeTextInputField).attrs({
  id: 'name',
})``;
export const ChangeMortgageeCityInputField = styled(ChangeMortgageeTextInputField).attrs({
  id: 'city',
})``;
export const ChangeMortgageeZipInputField = styled(ChangeMortgageeTextInputField).attrs({
  id: 'zip',
})``;
export const ChangeMortgageLoanNumberInputField = styled(ChangeMortgageeTextInputField).attrs({
  id: 'loanNumber',
})``;

export const ChangeMortgageeStateSelectorContainer = styled.div.attrs({
  id: 'ChangeMortgageeStateSelectorContainer',
})`
  display: grid;
  @media (max-width: 599px) {
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const RadioAddressLabel = styled.div.attrs((props) => ({
  id: props.id + ' Label',
}))`
  display: inline-block;
  margin-left: 8px;
`;

export const MortgageeLabel = styled.div.attrs({})`
  display: inline-block;
`;
export const ChangeMortgageePropertyComponent = styled.div.attrs({})`
  margin-bottom: 12px;
`;
export const ChangeMortgageeRadioButton = styled.input.attrs((props) => ({
  id: props.id,
  type: 'radio',
}))`
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
`;
export const MortgageeListLoaderContainer = styled.div.attrs({})`
  position: relative;
  display: inline-block;
  top: 20px;
  left: 4px;
`;
export const LinkStyleButton = styled.button.attrs({
  id: 'Cancel',
})`
  background-color: initial;
  color: ${(props) => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 0;

  :hover {
    background-color: initial;
    color: ${(props) => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${(props) => props.theme.primaryActiveColor};
  }
`;
