import styled from 'styled-components';
import Select from 'react-select';
export const ChangeContactInformationContainer = styled.div.attrs({
  id: 'ChangeContactInformationContainer',
})`
  margin-top: 1%;
`;
export const TwoColumnContainer = styled.div.attrs({})`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 699px) {
  }
  @media (min-width: 700px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ChangeContactInformationField = styled.div.attrs({})`
  width: 50%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ChangeContactInformationFieldHeader = styled.h4.attrs({
  id: 'ChangeContactInformationFieldHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ChangeContactInformationBlankFieldHeader = styled.h4.attrs({
  id: 'ChangeContactInformationBlankFieldHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 44px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const ChangeContactInformationTextAreaField = styled.textarea.attrs({
  id: 'ChangeContactInformationTextAreaField',
})`
  height: 28px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 45%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ChangeContactInformationButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
  margin-top: 10%;
  display: flex;
  @media (max-width: 384px) {
    text-align: center;
  }
`;
export const ChangeContactInformationButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;
export const ChangeContactInformationTextField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  width: 80%;
  padding-right: 0px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ChangeContactInformationNumericInputField = styled.input.attrs({
  type: 'number',
})`
  height: 30px;
  width: 80%;
  padding-right: 0px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ChangeContactInformationTextAreaInputField = styled.textarea.attrs({})`
  height: 30px;
  width: 80%;
  padding-right: 0px;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const YesNoSelect = styled(Select)`
  width: 80%;
  @media (max-width: 599px) {
    width: 100%;
  }
`;
export const ListSelect = styled(Select)`
  width: 80%;
  @media (max-width: 599px) {
    width: 100%;
  }
`;
export const LinkStyleButton = styled.button.attrs({
  id: 'cancelButton',
})`
  background-color: initial;
  color: ${(props) => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 0;

  :hover {
    background-color: initial;
    color: ${(props) => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${(props) => props.theme.primaryActiveColor};
  }
`;
