import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { router } from './routes';
import { OktaAuth } from '@okta/okta-auth-js';
import { Normalize } from 'styled-normalize';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/index';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { setFeatures } from './components/common/configuration/configurationReducer';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

// eslint-disable-next-line no-undef
import { GlobalStyle, theme } from './config/style/style';

const { features } = require(`./config/config`);
const store = configureStore({ reducer: rootReducer });

window.gwreSelenideConfigs = store;

const App = () => {
  if (window.location.href.includes('registrationToken=')) {
    // if it is a signup token url, clear okta session
    sessionStorage.removeItem('okta-token-storage');
  }
  const authHandler = () => {
    // navigate('/');
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(originalUri || '/', window.location.origin);
  };
  useEffect(() => {
    store.dispatch(setFeatures());
  }, []);

  const oktaAuth = new OktaAuth({
    issuer: features.tokenIssuer,
    devMode: true,
    clientId: features.clientId,
    storageManager: {
      token: {
        storageTypes: ['sessionStorage'],
      },
      cache: {
        storageTypes: ['sessionStorage'],
      },
      transaction: {
        storageTypes: ['sessionStorage'],
      },
    },
  });

  const thisTheme = createTheme();

  return (
    <Provider store={store}>
      <Normalize />
      <MuiThemeProvider theme={thisTheme}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <SnackbarProvider>
            <Security
              oktaAuth={oktaAuth}
              onAuthRequired={authHandler}
              restoreOriginalUri={restoreOriginalUri}
            >
              <RouterProvider router={router} />
            </Security>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App store={store} />);
