import styled from 'styled-components';

export const AddVehicleCompanyContainer = styled.div.attrs({
  id: 'AddVehicleCompanyContainer',
})`
  width: 100%;
`;
export const AddVehicleCompanyField = styled.div.attrs({})`
  width: 60%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const AddVehicleCompanyFieldHeader = styled.h4.attrs({
  id: 'AddVehicleCompanyFieldHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;

export const AddVehicleCompanyTextAreaField = styled.textarea.attrs({
  id: 'AddVehicleCompanyTextAreaField',
})`
  height: 100px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const VehicleComponent = styled.div.attrs({})`
  margin-bottom: 12px;
`;
export const VehicleRadioButton = styled.input.attrs({
  type: 'radio',
})`
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  float: left;
`;
export const RadioLabel = styled.div.attrs({})`
  display: block;
  margin-left: 22px;
`;
export const AddVehicleCompanySubHeader = styled.h4.attrs({
  id: 'AddVehicleCompanySubHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.95rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: 25px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const AddVehicleFinanceCompanyTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const AddVehicleFinanceCompanyButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
  margin-top: 10%;
  width: 100%;
  display: flex;
  @media (max-width: 384px) {
    text-align: center;
  }
`;
export const AddVehicleFinanceCompanyButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;
export const LinkStyleButton = styled.button.attrs({
  id: 'cancelButton',
})`
  background-color: initial;
  color: ${(props) => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 0;

  :hover {
    background-color: initial;
    color: ${(props) => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${(props) => props.theme.primaryActiveColor};
  }
`;
