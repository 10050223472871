import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveNotificationPhone } from './profileAPI';
import { getCustomerParty, getPrimaryPhoneInfo, getSecondaryPhoneInfo } from './selectors';
import { useSnackbar } from 'notistack';
import {
  CancelButton,
  PhoneEditContainer,
  PhoneRowContainer,
  PrimaryPhoneNumberInput,
  SavePhonesButton,
} from './styles';
import { formatPhoneNumber, isPhone } from '../../../utils/validators/generalValidators';

const EditingPhones = (props) => {
  const {
    ariaLabel,
    customerId,
    customerSubscriptionsFull,
    dispatch,
    isDashboard,
    standardPhoneLength,
    notificationPhone,
    phoneNumberPrimaryRetrieved,
    cancelPhone,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = React.useState(
    isDashboard
      ? phoneNumberPrimaryRetrieved
        ? phoneNumberPrimaryRetrieved
        : ''
      : notificationPhone
      ? notificationPhone
      : '',
  );

  const arePhoneInputsValid = () => {
    let isValid = primaryPhoneNumber !== '' && isPhone(primaryPhoneNumber);
    return isValid;
  };

  return (
    <>
      <PhoneEditContainer isDashboard={isDashboard}>
        <PhoneRowContainer isDashboard={isDashboard}>
          <PrimaryPhoneNumberInput isDashboard={isDashboard}>
            <input
              aria-label={ariaLabel}
              value={primaryPhoneNumber}
              onChange={(e) => setPrimaryPhoneNumber(formatPhoneNumber(e.target.value))}
              maxLength={standardPhoneLength ? 14 : 20}
            />
          </PrimaryPhoneNumberInput>
        </PhoneRowContainer>
      </PhoneEditContainer>
      <SavePhonesButton
        onClick={() => {
          dispatch(
            saveNotificationPhone(
              customerId,
              customerSubscriptionsFull,
              primaryPhoneNumber,
              enqueueSnackbar,
            ),
          );
        }}
        disabled={!arePhoneInputsValid()}
      >
        Apply
      </SavePhonesButton>
      <CancelButton
        onClick={() => {
          cancelPhone();
        }}
      >
        Cancel
      </CancelButton>
    </>
  );
};

EditingPhones.propTypes = {
  ariaLabel: PropTypes.string,
  cancelPhone: PropTypes.func,
  customerParty: PropTypes.object,
  customerSubscriptionsFull: PropTypes.any,
  customer: PropTypes.object,
  customerId: PropTypes.string,
  dispatch: PropTypes.func,
  isDashboard: PropTypes.bool,
  notificationPhone: PropTypes.string,
  phoneNumberPrimary: PropTypes.string,
  phoneNumberPrimaryId: PropTypes.string,
  phoneNumberPrimaryRetrieved: PropTypes.string,
  phoneNumberSecondary: PropTypes.string,
  phoneNumberSecondaryId: PropTypes.string,
  primaryPhoneName: PropTypes.string,
  primaryPhoneNameType: PropTypes.string,
  secondaryPhoneName: PropTypes.string,
  secondaryPhoneNameType: PropTypes.string,
  standardPhoneLength: PropTypes.bool,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  phoneErrors: state.profileSlice.phoneErrors,
  standardPhoneLength: state.configurationSlice.features.features.standardPhoneLength,
  editingPhone: state.profileSlice.editingPhone,
  customerId: state.loginSlice.user.preferred_username,
  systemId: state.customerSlice.customer.systemId,
  customer: state.customerSlice.customer,
  customerParty: getCustomerParty(state),
  customerSubscriptionsFull: state.notificationSlice.customerSubscriptionsFull,
  notificationPhone: state.notificationSlice.notificationPhone,
  phoneNumberPrimaryRetrieved: getPrimaryPhoneInfo(state).phoneNumber,
  phoneNumberSecondary: getSecondaryPhoneInfo(state).phoneNumber,
  phoneNumberSecondaryRetrieved: getSecondaryPhoneInfo(state).phoneNumber,
  phoneNumberPrimaryId: getPrimaryPhoneInfo(state).id,
  phoneNumberSecondaryId: getSecondaryPhoneInfo(state).id,
  primaryPhoneName: getPrimaryPhoneInfo(state).phoneName,
  secondaryPhoneName: getSecondaryPhoneInfo(state).phoneName,
});

export default connect(mapStateToProps)(EditingPhones);
