import React from 'react';
import PropTypes from 'prop-types';
import { EditEmailContainer, EmailAddressInput } from './styles';

const EditingEmail = (props) => {
  const { email, setEmail } = props;
  return (
    <>
      <EditEmailContainer>
        <>
          <EmailAddressInput>
            <input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              aria-label="Email Address"
            />
          </EmailAddressInput>
        </>
      </EditEmailContainer>
    </>
  );
};

EditingEmail.propTypes = {
  dispatch: PropTypes.func,
  emailAddress: PropTypes.string,
  email: PropTypes.string,
  setEmail: PropTypes.func,
};

export default EditingEmail;
