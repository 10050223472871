import styled from 'styled-components';
import Car from './svg/Car.svg';
import Home from './svg/Home.svg';

export const OpenQuotesContainer = styled.div.attrs({
  id: 'idOpenQuotesContainer',
})``;

export const GetAQuoteContainer = styled.div.attrs({
  id: 'GetAQuoteContainer',
})`
  padding: 0 30px 30px 30px;
`;

export const OpenQuotesHeader = styled.h1.attrs({
  id: 'OpenQuotesHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  margin: initial;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
    /* margin-top: 2%; */
  }
  @media (min-width: 600px) and (max-width: 959px) {
    /* margin-top: 2%; */
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    /* margin-top: 2%; */
  }
  @media (min-width: 1280px) {
    /* margin-top: 2%; */
  }
`;
export const InsuranceQuotesSubheader = styled.div.attrs({
  id: 'InsuranceQuotesSubheader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  float: left;
  @media (max-width: 599px) {
    margin-top: 2%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 2%;
  }
  @media (min-width: 1280px) {
    margin-top: 2%;
  }
`;
export const GetAQuoteSubheader = styled.div.attrs({
  id: 'GetAQuoteSubheader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
    margin-top: 2%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 2%;
  }
  @media (min-width: 1280px) {
    margin-top: 2%;
  }
`;
export const CarIcon = styled(Car)`
  width: 45px;
  font-size: ${(props) => (props.icon === 'auto' ? '2.461em' : '3.029em')};
  @media (min-width: 700px) {
    grid-area: icon;
    align-self: end;
  }
  @media (max-width: 460px) {
    grid-area: icon;
    align-self: end;
  }
  @media (min-width: 461px) and (max-width: 700px) {
    grid-area: icon;
    align-self: end;
  }
`;
export const HomeIcon = styled(Home)`
  width: 45px;
  font-size: ${(props) => (props.icon === 'auto' ? '2.461em' : '3.029em')};
  @media (min-width: 700px) {
    grid-area: icon;
    align-self: end;
  }
  @media (max-width: 460px) {
    grid-area: icon;
    align-self: end;
  }
  @media (min-width: 461px) and (max-width: 700px) {
    grid-area: icon;
    align-self: end;
  }
`;
export const OpenQuotesRowContainer = styled.div.attrs({
  id: 'OpenQuotesRowContainer',
})`
  display: grid;
  border: 2px solid;
  padding: 8px;
  border-color: ${(props) => props.theme.primaryColor};
  margin-bottom: 20px;
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 60% 40%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 60% 40%;
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 60% 40%;
    margin-left: 5%;
  }
`;

export const GetAQuoteFormContainer = styled.div.attrs({
  id: 'GetAQuoteFormContainer',
})`
  display: grid;
  padding: 8px;
  margin-top: 15px;
  text-align: center;
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 50% 50%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 50% 50%;
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
    margin-left: 5%;
  }
`;
export const OpenQuotesHeaderContainer = styled.div.attrs({
  id: 'OpenQuotesHeaderContainer',
})`
  display: grid;

  @media (max-width: 599px) {
    grid-template-columns: 100%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 50% 50%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 50% 50%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
  }
`;
export const OpenQuotesContentContainer = styled.div.attrs({
  id: 'openQuotesContainer',
})`
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
  }
  margin: 30px;
`;
export const OpenQuotesLeftRowContainer = styled.div.attrs({
  id: 'OpenQuotesLeftRowContainer',
})``;
export const OpenQuotesRightRowContainer = styled.div.attrs({
  id: 'OpenQuotesRightRowContainer',
})`
  text-align: right;
`;
export const QuoteAndIconContainer = styled.div.attrs({
  id: 'QuoteAndIconContainer',
})`
  @media (max-width: 460px) {
    display: grid;
    grid-template-columns: 45px auto;
    grid-template-rows: auto auto;
  }
  @media (min-width: 461px) and (max-width: 1124px) {
    /* margin-bottom: 12px; */
    display: grid;
    grid-template-columns: 45px auto;
    grid-template-rows: auto auto;
  }
  @media (min-width: 1125px) {
    display: inline-grid;
    grid-template-columns: 45px auto;
    grid-template-rows: auto auto;
  }
`;
export const ApplicationNumberContainer = styled.div.attrs({
  id: 'ApplicationNumberContainer',
})`
  margin-top: 7px;
  margin-left: 5px;
  color: ${(props) => props.theme.primaryColor};
`;
export const IconContainer = styled.div.attrs({
  id: 'IconContainer',
})`
  margin-top: 23px;
`;
export const PolicyTermContainer = styled.div.attrs({
  id: 'PolicyTermContainer',
})`
  float: center;
  margin-top: 10px;
`;
export const QuoteNumberContainer = styled.div.attrs({
  id: 'QuoteNumberContainer',
})`
  display: flex;
`;
export const QuoteTextContainer = styled.div.attrs({
  id: 'QuoteTextContainer',
})`
  margin-left: 10px;
`;
export const ApplicationStatusContainer = styled.div.attrs({
  id: 'ApplicationStatusContainer',
})`
  margin-top: 15px;
`;
export const PremiumAmountContainer = styled.div.attrs({
  id: 'PremiumAmountContainer',
})`
  font-weight: bold;
`;

export const AvailabilityContainer = styled.div.attrs({
  id: 'AvailabilityContainer',
})`
  font-weight: bold;
  padding-top: 15px;
`;
export const GetAQuoteButtonContainer = styled.div.attrs({
  id: 'getAQuoteButtonContainer',
})`
  text-align: right;
  @media (max-width: 599px) {
    display: none;
  }
`;
export const Button = styled.button.attrs({
  id: 'buttonContainer',
})`
  padding: 10px 15px;
  border: 0;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  margin: 0 0 10px 0;
  @media (max-width: 899px) {
    margin: 0 0 10px 0;
  }
  :disabled {
    background-color: gray;
  }
`;
export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-top: 16px;
  }
`;
export const ZipCodeLabel = styled.div`
  text-align: left;
  font-weight: bold;
  margin-bottom: 8px;
`;
export const ProductLabel = styled.div`
  text-align: left;
  font-weight: bold;
  margin-left: 32px;
  margin-bottom: 8px;
`;
export const RadioChoice = styled.input.attrs({
  type: 'radio',
})`
  display: inline-block;
  margin-right: 5px;
`;
export const ProductAvailabilityButtonContainer = styled.div.attrs({
  id: 'ProductAvailabilityButtonContainer',
})`
  text-align: right;
  margin-top: 8px;
`;
export const CheckAvailButton = styled.button.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  text-align: center;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  padding: 10px 15px;
  border: 0;
  margin-top: 10px;

  :disabled {
    background-color: gray;
  }
`;
export const CancelButton = styled.button.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  text-align: center;
  padding: 10px 15px;
  border: 0;
  margin-top: 10px;
  margin-right: 10px;
  color: white;
  background-color: ${(props) => props.theme.primaryColor};
  :disabled {
    background-color: gray;
  }
`;
export const CloserContainer = styled.div``;
export const ModalCloserSpaced = styled.button`
  margin: 10px;
  cursor: pointer;
  text-decoration: none;
  background: 0 0;
  border: 0;
  box-shadow: none;
  -webkit-appearance: none;
  font-size: 21px;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  float: right;
  :hover {
    background: none;
  }
`;
export const TextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 25px;
  width: 96.6%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    width: 98.6%;
  }
`;

export const ZipCodeField = styled(TextInputField).attrs({
  id: 'ZipCodeInput',
})``;
export const ProductListContainer = styled.div.attrs({
  id: 'ProductListContainer',
})`
  text-align: left;
  margin-left: 32px;
`;
export const NoQuotesMessage = styled.div`
  margin-top: 2%;
  font-size: 18px;
`;
