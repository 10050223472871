import styled from 'styled-components';

export const OtherContainer = styled.div.attrs({
  id: 'OtherContainer',
})`
  margin-top: 30px;
`;
export const TwoColumnContainer = styled.div.attrs({})`
  display: grid;
  @media (max-width: 449px) {
    grid-template-columns: 100%;
  }
  @media (min-width: 450px) and (max-width: 699px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 46% 8% 46%;
    grid-template-rows: 1fr;
  }
`;
export const OtherField = styled.div.attrs({})`
  min-width: 200px;
`;

export const OtherFieldHeader = styled.h4.attrs({
  id: 'OtherFieldHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const OtherButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
  display: flex;
  width: 100%;
  @media (max-width: 384px) {
    margin-top: 5px;
    text-align: center;
  }
`;
export const OtherButton = styled.button.attrs({})`
  margin-top: 10px;
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 384px) {
    margin-bottom: 5px;
  }
`;
export const OtherTextAreaField = styled.textarea.attrs({
  id: 'ReportClaimsTextInputField',
})`
  height: 100px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const OtherSubHeader = styled.h4.attrs({
  id: 'OtherSubHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.001rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const OtherTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const OtherStateSelectorContainer = styled.div.attrs({
  id: 'OtherStateSelectorContainer',
})`
  display: grid;
  @media (max-width: 599px) {
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const RadioAddressLabel = styled.div.attrs({})`
  display: inline-block;
  margin-left: 8px;
`;
export const OtherComponent = styled.div.attrs({})`
  margin-bottom: 12px;
`;
export const OtherRadioButton = styled.input.attrs({
  type: 'radio',
})`
  display: inline-block;
  vertical-align: top;
  margin-top: 12px;
`;
export const LinkStyleButton = styled.button.attrs({
  id: 'Cancel',
})`
  background-color: initial;
  color: ${(props) => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 0;

  :hover {
    background-color: initial;
    color: ${(props) => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${(props) => props.theme.primaryActiveColor};
  }
`;
