import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { StyledFooter, BuildInfoContainer, FooterContainer, LinksContainer } from './styles';
import AboutUs from './aboutUs';
import PrivacyStatement from './privacyStatement';
import TermsAndConditions from './termsAndConditions';

const Footer = (props) => {
  const { features } = props;
  return (
    <StyledFooter>
      <FooterContainer>
        <LinksContainer>
          <TermsAndConditions />
          <PrivacyStatement />
          <AboutUs />
        </LinksContainer>
        <BuildInfoContainer>
          {features.companyName} Version {process.env.BUILD_VERSION}{' '}
          {features.features.showImageTag && <>: {features._meta.imageTag}</>}
        </BuildInfoContainer>
      </FooterContainer>
    </StyledFooter>
  );
};
Footer.propTypes = {
  features: PropTypes.object,
};
export const mapStateToProps = (state) => ({
  features: state.configurationSlice.features,
});
export default connect(mapStateToProps)(Footer);
