import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateRegistrationEmail, saveNotificationEmail } from './profileAPI';
import { setEditingRegistrationEmail, setEmailErrors } from './profileReducer';
import { getCustomerParty } from './selectors';
import {
  CancelButton,
  EditEmailContainer,
  RegistrationEmailAddressInputContainer,
  RegistrationEmailAddressInput,
  SaveEmailButton,
} from './styles';
import { isEmail } from '../../../utils/validators/generalValidators';
import { useSnackbar } from 'notistack';

const EditingRegistrationEmail = (props) => {
  const { dispatch, customerId, userEmail, user } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = React.useState(userEmail ? userEmail : '');
  const cancelEmail = () => {
    dispatch(setEditingRegistrationEmail(false));
    dispatch(setEmailErrors(null));
  };

  const updateEmail = () => {
    dispatch(updateRegistrationEmail(customerId, { email: email }, user, enqueueSnackbar));
  };

  const isValidEmail = () => {
    return email !== '' && isEmail(email);
  };
  return (
    <>
      <EditEmailContainer>
        <RegistrationEmailAddressInputContainer>
          <RegistrationEmailAddressInput
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            aria-label="Email Address"
          />
        </RegistrationEmailAddressInputContainer>
      </EditEmailContainer>
      <SaveEmailButton disabled={!isValidEmail()} onClick={() => updateEmail(props, email)}>
        Apply
      </SaveEmailButton>
      <CancelButton
        onClick={() => {
          cancelEmail();
        }}
      >
        Cancel
      </CancelButton>
    </>
  );
};

EditingRegistrationEmail.propTypes = {
  customerSubscriptionsFull: PropTypes.any,
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  customerParty: PropTypes.object,
  customer: PropTypes.object,
  customerId: PropTypes.string,
  userEmail: PropTypes.string,
  cancelEmail: PropTypes.func,
  user: PropTypes.object,
};
const mapStateToProps = (state) => ({
  isLoading: state.profileSlice.isLoading,
  emailErrors: state.profileSlice.emailErrors,
  editingEmail: state.profileSlice.editingEmail,
  customerId: state.loginSlice.user.preferred_username,
  userEmail: state.loginSlice.user.email,
  user: state.loginSlice.user,
  systemId: state.customerSlice.customer.systemId,
  customer: state.customerSlice.customer,
  customerSubscriptionsFull: state.notificationSlice.customerSubscriptionsFull,
  customerParty: getCustomerParty(state),
});

export default connect(mapStateToProps)(EditingRegistrationEmail);
