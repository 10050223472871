import { setIsLoading, setResponse } from './forgotUsernameReducer';
import { refreshToken, TOKENIZED_API } from '../../../utils/api';

export const retrieveUsername = (body, notify) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    await refreshToken();
    TOKENIZED_API.post(`/customerLogins/forgotUsernameRequest`, body)
      .then((response) => {
        if (response.status === 202) {
          dispatch(setResponse(body));
        }
      })
      .catch((err) => {
        const errorMsg =
          err.response.status > 399 && err.response.status < 500 && err?.response?.data?.message
            ? err.response.data.message
            : 'Your request cannot be processed at this time. Please try again later.';
        notify(errorMsg, {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };
};
