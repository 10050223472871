import styled from 'styled-components';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export const MyAgentHeader = styled.h3.attrs({
  id: `agentHeader`,
})`
  font-size: 14px;
  color: black;
  margin-bottom: 6px;
`;

export const MyAgentContainer = styled.div.attrs((props) => ({
  id: `agentPanel${props.id}`,
}))`
  margin-bottom: 16px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 900px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
  ${(props) => props.template === 'template1' && `padding-left: 8px`};
`;
export const MyAgentAddress = styled.div.attrs({
  id: 'agentsAddress',
})``;
export const AddressLineOne = styled.div.attrs({})`
  color: black;
`;
export const AddressLineTwo = styled.div.attrs({})`
  color: black;
`;
export const TemplateOnePanel = styled.h2.attrs({
  id: 'templateOnePanel',
})`
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  text-align: center;
  padding-top: 16px;
  margin: 0;
  font-weight: 400;
  padding-bottom: 16px;
  font-size: 24px;
`;
export const AgentContainer = styled.div.attrs({
  id: 'outshellAgentContainer',
})`
  max-width: 310px;
  @media (max-width: 599px) {
    margin-left: 6%;
    padding-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 899px) {
    padding-bottom: 16px;
    ${(props) => (props.template === 'template1' ? `margin-left: 1px` : 'margin-left: 30px')};
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
  ${(props) =>
    props.template === 'template1'
      ? `border: 3px solid ${props.theme.primaryColor}`
      : `border: none`};
  margin: ${(props) => (props.template === 'template1' ? `2% 6%` : `0`)};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const PoliciesSubHeader = styled.h4.attrs({})`
  margin-bottom: 0;
`;
export const AgentsEmailHeader = styled.h4.attrs({
  id: 'agentEmailHeader',
})`
  color: black;
  font-size: 14px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;

  @media (max-width: 599px) {
    margin-top: 16px;
    margin-bottom: initial;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    margin-top: 16px;
    margin-bottom: initial;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 16px;
    margin-bottom: initial;
  }
  @media (min-width: 1280px) {
    margin-top: 16px;
    margin-bottom: initial;
  }
`;
export const AgentsPhoneHeader = styled.h4.attrs({
  id: 'agentsPhoneHeader',
})`
  color: black;
  font-size: 14px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  @media (max-width: 599px) {
    margin-top: 16px;
    margin-bottom: initial;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    margin-top: 16px;
    margin-bottom: initial;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 16px;
    margin-bottom: initial;
  }
  @media (min-width: 1280px) {
    margin-top: 16px;
    margin-bottom: initial;
  }
`;
export const AgentTitleContainer = styled.div.attrs({
  id: 'agentTitleContainer',
})`
  display: grid;
  grid-template-columns: 1% 99%;
  margin-bottom: 5px;
  height: 40px;
`;
export const Spacer = styled.div.attrs({
  id: 'agentTitleSpacer',
})`
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
`;
export const MyAgentWithPictureHeader = styled.h3.attrs({
  id: `myAgentWithPictureHeader`,
})`
  margin-left: 45px;
  margin-top: 0;
  font-size: 14px;
  color: black;
  margin-bottom: 6px;
  @media (max-width: 899px) and (min-width: 600px) {
    margin-left: 40px;
  }
  @media (max-width: 599px) {
    margin-left: 42px;
  }
`;
export const AgentImage = styled.img.attrs({})`
  width: 40px;
  height: 40px;

  :hover {
    transform: scale(2);
    transition: all 1s ease-in-out;
    z-index: 100;
    position: relative;
  }
  :not(:hover) {
    transform: scale(1);
    transition: all 1s ease-in-out;
    z-index: 0;
  }
`;
export const ContactInformation = styled.a.attrs({})`
  word-wrap: break-word;
`;
export const AgentEmailIcon = styled(EmailIcon)`
  position: relative;
  @media (max-width: 599px) {
    top: 8px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    top: 8px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    top: 8px;
  }
  @media (min-width: 1280px) {
    top: 8px;
  }
`;
export const AgentPhoneIcon = styled(LocalPhoneIcon)`
  position: relative;
  @media (max-width: 599px) {
    top: 8px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    top: 8px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    top: 8px;
  }
  @media (min-width: 1280px) {
    top: 8px;
  }
`;
