import styled from 'styled-components';
export const ReportClaimConfirmationContainer = styled.div.attrs({
  id: 'reportClaimConfirmationContainer',
})`
  width: 52%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-left: 60px;
    margin-right: 60px;
    width: 100%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 60px;
    margin-right: 60px;
    width: 100%;
  }
  @media (min-width: 1280px) {
    margin-left: 65px;
    margin-right: 65px;
  }
`;
export const HomeScreenButton = styled.button.attrs({})`
  padding: 10px 45px;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  float: right;
  margin-top: 25px;
  @media (max-width: 380px) {
    margin-top: 16px;
  }
  @media (min-width: 600px) and (max-width: 899px) {
    margin-right: 25px;
  }
`;
export const StyledReportClaimHeader = styled.h1.attrs({})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 36px;
  font-weight: 500;
  @media (max-width: 599px) {
    margin-top: 2%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 2%;
  }
  @media (min-width: 1280px) {
    margin-top: 40px;
  }
`;
export const StyledSubSubHeader = styled.h3.attrs({
  id: 'confirmationSubHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
`;
export const InfoContainer = styled.div.attrs({
  id: 'confirmationInfoContainer',
})`
  max-width: 725px;
`;
export const ClaimEventNameLabel = styled.div.attrs({
  id: 'claimEventNameLabel',
})`
  font-weight: 600;
  margin-top: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
