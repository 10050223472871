import { API } from '../../../../utils/api';
import { setLoading, setAgents, setErrors } from './myAgentReducer';
import find from 'lodash/find';

export const getMyAgents = systemId => {
  let providerMap = new Map();
  return dispatch => {
    dispatch(setLoading(true));
    API.get(`/customers/${systemId}/summary`)
      .then(async response => {
        let promises = response.data.providerInfo.map(provider => {
          return API.get(
            `/providers?providerTypeCd=Producer&providerNumber=${provider.providerNumber}`,
          )
            .then(response => {
              let agentInfo = find(response.data.providerListItems, item => {
                return item.providerMini.indexName === provider.indexName;
              });
              return {
                ...agentInfo.providerMini.partyInfo,
                ref: agentInfo.ref,
                profilePictureAvailableInd: provider.profilePictureAvailableInd};
            })
            .catch(err => {
              return err.message ? err.message : 'errors';
            });
        });
        await Promise.all(promises)
          .then(async providersResponse => {
            let providerImagePromise = providersResponse.map(provider => {
              if (provider.profilePictureAvailableInd === true) {
                return API.get(
                  `/customers/${systemId}/producers/${provider.ref}/profilePicture/content`,
                  { responseType: 'blob' },
                )
                  .then((imageResponse) => {
                    const imageData = URL.createObjectURL(imageResponse.data);
                    return {
                      ref: provider.ref,
                      imageData,
                    };
                  })
                  .catch((e) => {
                    console.error('Error getting producer image', e);
                  });
              }
            })
            await Promise.allSettled(providerImagePromise)
              .then(images => {
                images.forEach(image => {
                  if (image?.value?.ref) {
                    providerMap.set(image.value.ref, image.value.imageData)
                  }
                })
              });
            // update provideResponse with images
            providersResponse.forEach(provider => {
              provider.imageData = providerMap.get(provider.ref)
            })
            dispatch(setAgents(providersResponse));
          })
          .catch(err => dispatch(setErrors(err.message ? err.message : 'errors')));
        // we await promise all so we can finally set loading false from top level call
      })
      .catch(err => dispatch(setErrors(err.message ? err.message : 'errors')))
      .finally(() => dispatch(setLoading(false)));
  };
};
