import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  PhoneEditContainer,
  PhoneRowContainer,
  PhoneSelect,
  PhoneSelectorContainer,
  PrimaryPhoneLabel,
  PrimaryPhoneNumberInput,
  PrimaryPhoneRequiredField,
  SecondaryPhoneNumberInput,
  SecondaryPhoneNumberLabel,
  SecondaryPhoneSelect,
} from './styles';
import { formatPhoneNumber } from '../../../utils/validators/generalValidators';
import TooltipInfo from '../../common/tooltip';
import { getText } from '../../../utils/i18n';

const EditingPhones = (props) => {
  const {
    ariaLabel,
    standardPhoneLength,
    phoneNumberPrimary,
    phoneNumberSecondary,
    setPhoneNumberPrimary,
    setPhoneNumberSecondary,
    setPhoneNumberPrimaryName,
    setPhoneNumberSecondaryName,
    phoneNumberPrimaryName,
    phoneNumberSecondaryName,
    i18n,
  } = props;
  const handlePrimaryPhoneChange = (phone) => {
    setPhoneNumberPrimaryName(phone);
  };
  const handleSecondaryPhoneChange = (phone) => {
    setPhoneNumberSecondaryName(phone);
  };
  const phoneTypeSelector = [
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Home', label: 'Home' },
    { value: 'Business', label: 'Business' },
    { value: 'Other', label: 'Other' },
  ];
  return (
    <>
      <PhoneEditContainer>
        <PhoneRowContainer>
          <PrimaryPhoneLabel>
            Primary phone <TooltipInfo title={getText(i18n, 'profile.primaryPhoneTooltip')} />
          </PrimaryPhoneLabel>
          <PrimaryPhoneRequiredField>*</PrimaryPhoneRequiredField>
          <PrimaryPhoneNumberInput>
            <input
              aria-label={ariaLabel}
              value={phoneNumberPrimary}
              onChange={(e) => {
                setPhoneNumberPrimary(formatPhoneNumber(e.target.value));
              }}
              maxLength={standardPhoneLength ? 14 : 20}
            />
          </PrimaryPhoneNumberInput>
          <PhoneSelectorContainer>
            <PhoneSelect
              id="phoneTypeSelector"
              options={phoneTypeSelector}
              value={phoneNumberPrimaryName}
              onChange={handlePrimaryPhoneChange}
            />
          </PhoneSelectorContainer>
        </PhoneRowContainer>
        <PhoneRowContainer>
          <SecondaryPhoneNumberLabel>
            Secondary phone <span style={{ color: '#657580' }}>(Optional)</span>
            <TooltipInfo title={getText(i18n, 'profile.secondaryPhoneTooltip')} />
          </SecondaryPhoneNumberLabel>
          <SecondaryPhoneNumberInput>
            <input
              value={phoneNumberSecondary}
              onChange={(e) => {
                setPhoneNumberSecondary(formatPhoneNumber(e.target.value));
              }}
            />
          </SecondaryPhoneNumberInput>
          <PhoneSelectorContainer>
            <SecondaryPhoneSelect
              options={phoneTypeSelector}
              value={phoneNumberSecondaryName}
              onChange={handleSecondaryPhoneChange}
            />
          </PhoneSelectorContainer>
        </PhoneRowContainer>
      </PhoneEditContainer>
    </>
  );
};

EditingPhones.propTypes = {
  ariaLabel: PropTypes.string,
  cancelPhone: PropTypes.func,
  customerSubscriptionsFull: PropTypes.any,
  customer: PropTypes.object,
  customerId: PropTypes.string,
  dispatch: PropTypes.func,
  notificationPhone: PropTypes.string,
  phoneNumberPrimary: PropTypes.string,
  phoneNumberPrimaryName: PropTypes.object,
  phoneNumberSecondary: PropTypes.string,
  phoneNumberSecondaryName: PropTypes.object,
  standardPhoneLength: PropTypes.bool,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  phoneErrors: state.profileSlice.phoneErrors,
  standardPhoneLength: state.configurationSlice.features.features.standardPhoneLength,
  editingPhone: state.profileSlice.editingPhone,
  customerId: state.loginSlice.user.preferred_username,
  systemId: state.customerSlice.customer.systemId,
  customer: state.customerSlice.customer,
  customerSubscriptionsFull: state.notificationSlice.customerSubscriptionsFull,
  notificationPhone: state.notificationSlice.notificationPhone,
  i18n: state.i18nSlice.i18n,
});
export default connect(mapStateToProps)(EditingPhones);
