import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  LinkStyleButton,
  NextButton,
  RemoveDriverButtonsContainer,
  RemoveDriverComponent,
  RemoveDriverDateInputField,
  RemoveDriverField,
  RemoveDriverHeader,
  RemoveDriverRadioButton,
  RemoveDriverSelectDriverContainer,
  RemoveDriverTextAreaField,
  RemoveLabel,
  RequiredField,
} from './styles';
import moment from 'moment';
import { getDrivers, getLatestPolicyInfo, preSubmitPolicyChange } from '../../policyChangeAPI';
import Loader from '../../../../common/loader';
import { createField, formatRecapFieldDate } from '../selector';
import {
  setLoading,
  setPolicyChangeCoverageRecapFields,
  setPolicyChangePreview,
  setPolicyChangePreviewData,
} from '../../policyChangeReducer';
import PolicyChangePreview from '../policy-change-preview';
import { DISPLAY_DATE_FORMAT, ISO_DATE_FORMAT } from '../../../../common/constants';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useNavigate } from 'react-router';

const todayDt = moment().format(ISO_DATE_FORMAT);
export const RemoveDriver = (props) => {
  const {
    dispatch,
    selectedPolicy,
    policyRefSelected,
    customerId,
    selectedPolicyChangeTemplate,
    isLoading,
    drivers,
    policyChangePreview,
    i18n,
  } = props;
  // local state
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [reason, setReason] = useState('');
  const [selectedDriverId, setSelectedDriverId] = useState('');
  let selectedDriverName = '';

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    return effectiveDate.length > 0 && reason.length > 0 && selectedDriverId.length > 0;
  };
  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(
      createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
    );
    fields.push(createField('Reason for Remove', reason, 'textArea'));
    fields.push(createField('Driver to Remove', selectedDriverName, 'text'));
    return fields;
  };

  useEffect(() => {
    dispatch(getDrivers(policyRefSelected));
  }, []);

  const onNextButtonClick = () => {
    const driver = drivers.find((driver) => driver.id === selectedDriverId);
    selectedDriverName = driver.nameInfo.givenName + ' ' + driver.nameInfo.surname;
    const payload = {
      name: selectedPolicyChangeTemplate.name,
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: policyRefSelected,
        policyDescription: selectedPolicy.label.substring(selectedPolicy.label.indexOf(' ') + 1),
      },
      changeRequest: [
        {
          detailDescription: reason,
          partyInfo: [
            {
              id: driver.id,
              partyTypeCd: driver.partyTypeCd,
              personInfo: {
                id: driver.personInfo.id,
              },
              driverInfo: {
                id: driver.driverInfo.id,
              },
              nameInfo: {
                id: driver.nameInfo.id,
              },
            },
          ],
        },
      ],
      questionReplies: [{ questionReply: [{}] }],
      sourceCd: 'ServicePortal',
    };

    const fields = createPolicyChangeCoverageSummary();

    dispatch(
      setPolicyChangePreviewData({
        fields,
        payload,
        recapFields: createPolicyChangeCoverageSummary(),
      }),
    );

    // get current policy deductible
    dispatch(getLatestPolicyInfo(selectedPolicy.value));

    // submit API call
    dispatch(preSubmitPolicyChange(payload));
  };

  const getDriverBirthDate = (driverId) => {
    const driverBirthDate = drivers.find((driver) => driver.id === driverId).personInfo.birthDt;
    return moment(driverBirthDate, ISO_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
  };

  const navigate = useNavigate();

  return (
    <>
      {policyChangePreview ? (
        <PolicyChangePreview />
      ) : (
        <>
          <RemoveDriverField>
            <RemoveDriverHeader>
              Requested Effective Date<RequiredField>*</RequiredField>
              <TooltipInfo
                title={getText(i18n, 'changeCoverage.removeDriver.effectiveDateTooltip')}
              />
            </RemoveDriverHeader>
            <RemoveDriverDateInputField
              id="effectiveDate"
              value={effectiveDate}
              onChange={(e) => {
                setEffectiveDate(e.target.value);
              }}
            />
          </RemoveDriverField>
          <RemoveDriverField>
            <RemoveDriverHeader>
              Reason<RequiredField>*</RequiredField>
              <TooltipInfo title={getText(i18n, 'changeCoverage.removeDriver.reasonTooltip')} />
            </RemoveDriverHeader>
            <RemoveDriverTextAreaField
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </RemoveDriverField>
          <RemoveDriverSelectDriverContainer>
            <RemoveDriverHeader>
              Select Driver to Remove <RequiredField>*</RequiredField>
              <TooltipInfo
                title={getText(i18n, 'changeCoverage.removeDriver.driverToRemoveTooltip')}
              />
            </RemoveDriverHeader>
            {drivers.map((driver, index) => {
              return (
                <RemoveDriverComponent key={driver.id}>
                  <label htmlFor={'driver' + index}>
                    <RemoveDriverRadioButton
                      id={'driver' + index}
                      name="driver"
                      value={driver.id}
                      onChange={(e) => {
                        setSelectedDriverId(e.target.value);
                      }}
                    />{' '}
                    <RemoveLabel>
                      {driver.nameInfo.givenName + ' ' + driver.nameInfo.surname}
                    </RemoveLabel>
                    <RemoveLabel>
                      {'License: ' +
                        driver.driverInfo.licensedStateProvCd +
                        ' - ' +
                        driver.driverInfo.licenseNumber}
                    </RemoveLabel>
                    <RemoveLabel>{'Birth Date: ' + getDriverBirthDate(driver.id)}</RemoveLabel>
                  </label>
                </RemoveDriverComponent>
              );
            })}
          </RemoveDriverSelectDriverContainer>
          <RemoveDriverButtonsContainer>
            <LinkStyleButton
              onClick={() => {
                navigate('/dashboard');
                dispatch(setPolicyChangeCoverageRecapFields([]));
                dispatch(setPolicyChangePreviewData([]));
                dispatch(setPolicyChangePreview(null));
              }}
            >
              Cancel
            </LinkStyleButton>
            <NextButton
              id="Next"
              disabled={!checkRequiredFields()}
              onClick={() => {
                onNextButtonClick();
              }}
            >
              Next
            </NextButton>
          </RemoveDriverButtonsContainer>
        </>
      )}
    </>
  );
};

RemoveDriver.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicy: PropTypes.object,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  isLoading: PropTypes.bool,
  uploadFileSize: PropTypes.any,
  policyRefSelected: PropTypes.string,
  drivers: PropTypes.array,
  policyChangePreview: PropTypes.object,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  customerId: state.customerSlice.customer.systemId,
  isLoading: state.policyChangeSlice.isLoading,
  drivers: state.policyChangeSlice.drivers,
  uploadFileSize: state.configurationSlice.features.features.UploadFileSize,
  policyRefSelected: state.policyChangeSlice.selectedPolicy.policyRef,
  policyChangePreview: state.policyChangeSlice.policyChangePreview,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(RemoveDriver);
