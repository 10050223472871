import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  LinkStyleButton,
  RadioLabel,
  RemoveVehicleButton,
  RemoveVehicleButtonsContainer,
  RemoveVehicleComponent,
  RemoveVehicleContainer,
  RemoveVehicleField,
  RemoveVehicleFieldHeader,
  RemoveVehicleRadioButton,
  RemoveVehicleTextAreaField,
  RequiredField,
} from './styles';
import moment from 'moment';
import {
  getLatestPolicyInfo,
  getVehicleRisks,
  preSubmitPolicyChange,
  submitPolicyChange,
} from '../../policyChangeAPI';
import { createField, formatRecapFieldDate } from '../selector';
import {
  setPolicyChangeCoverageRecapFields,
  setPolicyChangePreview,
  setPolicyChangePreviewData,
} from '../../policyChangeReducer';
import Loader from '../../../../common/loader';
import { Spacer, StyledLoaderContainer } from '../../styles';
import PolicyChangePreview from '../policy-change-preview';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useNavigate } from 'react-router';

export const RemoveVehicle = (props) => {
  const {
    dispatch,
    selectedPolicy,
    vehicleRisks,
    isVehicleRisksLoading,
    customerId,
    selectedPolicyChangeTemplate,
    changeCoverageShowNewPremium,
    policyChangePreview,
    i18n,
  } = props;
  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [reason, setReason] = useState('');
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [selectedRiskId, setSelectedRiskId] = useState('');
  const [selectedRiskLabel, setSelectedRiskLabel] = useState('');
  const [selectedRiskModel, setSelectedRiskModel] = useState('');
  const [selectedRiskModelYear, setSelectedRiskModelYear] = useState('');
  const [selectedRiskVin, setSelectedRiskVin] = useState('');
  const [selectedRiskMake, setSelectedRiskMake] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    const areRequiredFieldsCompleted =
      effectiveDate.length > 0 && reason.length > 0 && selectedRiskId.length > 0;
    if (areRequiredFieldsCompleted !== isSubmitButtonEnabled) {
      setIsSubmitButtonEnabled(areRequiredFieldsCompleted);
    }
  };

  checkRequiredFields();

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(
      createField(
        'Policy',
        selectedPolicyChangeTemplate.policyDetails[0].policyDescription,
        'text',
      ),
    );
    fields.push(
      createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
    );
    fields.push(createField('Reason for Removal', reason, 'text'));
    fields.push(createField('Vehicle to be removed', selectedRiskLabel, 'text'));
    return fields;
  };

  const createPayLoad = () => {
    return {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
          vehicle: [
            {
              id: selectedRiskId,
              modelYr: selectedRiskModelYear,
              manufacturer: selectedRiskMake,
              model: selectedRiskModel,
              vehIdentificationNumber: selectedRiskVin,
            },
          ],
        },
      ],
      sourceCd: 'ServicePortal',
    };
  };

  const onSubmitButtonClick = () => {
    setIsSubmitting(true);
    setIsSubmitButtonEnabled(false);

    const payload = createPayLoad();

    // set fields for recap/result
    dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));

    // API calls
    dispatch(submitPolicyChange(payload));
  };

  const onNextButtonClick = () => {
    setIsSubmitting(true);
    const fields = [];
    fields.push(
      createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
    );

    fields.push(createField('Reason', reason, 'text'));
    fields.push(createField('Vehicle to Remove', selectedRiskLabel, 'text'));

    const payload = createPayLoad();

    dispatch(
      setPolicyChangePreviewData({
        fields,
        payload,
        recapFields: createPolicyChangeCoverageSummary(),
      }),
    );

    // get current policy deductible
    dispatch(getLatestPolicyInfo(selectedPolicy.value));

    // submit API call
    dispatch(preSubmitPolicyChange(payload));
  };

  useEffect(() => {
    dispatch(getVehicleRisks(selectedPolicy.value));
    setIsSubmitting(false);
  }, []);

  useEffect(() => {
    setIsSubmitting(false);
  }, [policyChangePreview]);

  const navigate = useNavigate();

  return (
    <>
      {policyChangePreview ? (
        <PolicyChangePreview />
      ) : (
        <RemoveVehicleContainer>
          <RemoveVehicleField>
            <RemoveVehicleFieldHeader>
              Requested Effective Date <RequiredField>*</RequiredField>
              <TooltipInfo
                title={getText(i18n, 'changeCoverage.removeVehicle.effectiveDateTooltip')}
              />
            </RemoveVehicleFieldHeader>
          </RemoveVehicleField>
          <input
            type="date"
            aria-label="Requested Effective Date"
            value={effectiveDate}
            min={todayDt}
            onChange={(e) => {
              setEffectiveDate(e.target.value);
            }}
          />
          <RemoveVehicleField>
            <RemoveVehicleFieldHeader>
              Reason for Removal <RequiredField>*</RequiredField>
              <TooltipInfo title={getText(i18n, 'changeCoverage.removeVehicle.reasonTooltip')} />
            </RemoveVehicleFieldHeader>
            <RemoveVehicleTextAreaField
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </RemoveVehicleField>
          <RemoveVehicleField>
            <RemoveVehicleFieldHeader>
              Vehicle to be removed <RequiredField>*</RequiredField>
              <TooltipInfo
                title={getText(i18n, 'changeCoverage.removeVehicle.vehicleToBeRemovedTooltip')}
              />
            </RemoveVehicleFieldHeader>
            {isVehicleRisksLoading ? (
              <Loader />
            ) : (
              vehicleRisks.map((risk) => {
                return (
                  <RemoveVehicleComponent key={risk.id}>
                    <label htmlFor={risk.description}>
                      <RemoveVehicleRadioButton
                        id={risk.description}
                        name="vehicle"
                        value={risk.id}
                        onChange={(e) => {
                          setSelectedRiskId(e.target.value);
                          setSelectedRiskLabel(risk.description);
                          setSelectedRiskMake(risk.make);
                          setSelectedRiskModel(risk.model);
                          setSelectedRiskModelYear(risk.modelYear);
                          setSelectedRiskVin(risk.vin);
                        }}
                      />{' '}
                      <RadioLabel>{risk.description}</RadioLabel>
                    </label>
                  </RemoveVehicleComponent>
                );
              })
            )}
          </RemoveVehicleField>
        </RemoveVehicleContainer>
      )}
      <RemoveVehicleButtonsContainer>
        <LinkStyleButton
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          Cancel
        </LinkStyleButton>
        {changeCoverageShowNewPremium ? (
          <>
            <RemoveVehicleButton
              id="Next"
              disabled={!isSubmitButtonEnabled}
              onClick={() => onNextButtonClick()}
            >
              Next
            </RemoveVehicleButton>
            {isSubmitting ? (
              <StyledLoaderContainer>
                <Loader loaderheight="20px" loaderwidth="20px" />
              </StyledLoaderContainer>
            ) : (
              <Spacer />
            )}
          </>
        ) : (
          <>
            <RemoveVehicleButton
              disabled={!isSubmitButtonEnabled}
              onClick={() => onSubmitButtonClick()}
            >
              Submit
            </RemoveVehicleButton>
            {isSubmitting ? (
              <StyledLoaderContainer>
                <Loader loaderheight="20px" loaderwidth="20px" />
              </StyledLoaderContainer>
            ) : (
              <Spacer />
            )}
          </>
        )}
      </RemoveVehicleButtonsContainer>
    </>
  );
};
RemoveVehicle.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicyChangeTemplate: PropTypes.object,
  customerId: PropTypes.string,
  selectedPolicy: PropTypes.object,
  changeCoverageShowNewPremium: PropTypes.bool,
  vehicleRisks: PropTypes.array,
  isVehicleRisksLoading: PropTypes.bool,
  policyChangePreview: PropTypes.object,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  vehicleRisks: state.policyChangeSlice.vehicleRisks,
  isVehicleRisksLoading: state.policyChangeSlice.isVehicleRisksLoading,
  customerId: state.customerSlice.customer.systemId,
  changeCoverageShowNewPremium:
    // state.configurationSlice.features.features.changeCoverageShowNewPremium,
    !!state.policyChangeSlice.selectedPolicyChangeTemplate.showPremChgInd,
  policyChangePreview: state.policyChangeSlice.policyChangePreview,
  i18n: state.i18nSlice.i18n,
});
export default connect(mapStateToProps)(RemoveVehicle);
