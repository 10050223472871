import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  getAddress,
  getBestWayToContact,
  getMailingCity,
  getMailingState,
  getMailingZip,
  getPhoneNumber,
  getPhoneType,
} from './selectors';

import ReportClaimFormContainer from './reportClaimForm';
import { getClaimEventContacts, getDynamicQuestions } from './reportClaimApi';
import { LoaderMargin } from '../../id-cards/styles';
import { Loader } from '../../../common/loader';
import { setUpdatedClaimEventTemplate } from '../reportClaimReducer';
import ApiError from '../../../common/api-error';
import { setClaimEventContactsLoading } from './reportClaimStepThreeReducer';
import { ReportClaimPreviousButton } from '../report-claim-button-container/styles';

export const ReportClaimStepThree = (props) => {
  const {
    errors,
    customerId,
    claimEvent,
    claimEventContacts,
    dispatch,
    isClaimEventContactsLoading,
    dateValue,
    timeValue,
    selectedVehicle,
    selectedLocation,
    claimEventDynamicQuestions,
    handleStep,
    activeStep,
  } = props;

  React.useEffect(() => {
    //  next button from step Three calls get contacts
    dispatch(setClaimEventContactsLoading(true));
    async function getQuestions() {
      await dispatch(getDynamicQuestions(props));
    }
    getQuestions();
    // validate claim event and get dynamic questions
    async function getClaimContacts() {
      if (!claimEventContacts?.id) {
        if (selectedLocation && selectedLocation.policyNumber) {
          await dispatch(
            getClaimEventContacts(
              claimEvent.templateIdRef,
              customerId,
              selectedLocation.policyNumber,
              selectedLocation.id,
              dateValue,
              timeValue,
            ),
          );
        } else {
          await dispatch(
            getClaimEventContacts(
              claimEvent.templateIdRef,
              customerId,
              selectedVehicle.policyNumber,
              selectedVehicle.id,
              dateValue,
              timeValue,
            ),
          );
        }
      } else {
        dispatch(setClaimEventContactsLoading(false));
      }
    }
    getClaimContacts();
  }, []);

  React.useEffect(() => {
    const originalClaimEvent = { ...props.state.reportClaimSlice.selectedClaimEventTemplate };
    if (
      claimEventDynamicQuestions &&
      claimEventDynamicQuestions.eventSections &&
      claimEventDynamicQuestions.eventSections.length > 0 &&
      props.state.reportClaimSlice.selectedClaimEventTemplate
    ) {
      // reassign expanded values since originals are read-only
      originalClaimEvent.eventSections = [...originalClaimEvent.eventSections];

      // using hash map to avoid using brute force for better performance
      const sectionsMap = new Map();
      // loop through original claim eventSections and create a map {sectionCd:index}
      for (let i = 0; i < originalClaimEvent.eventSections.length; i++) {
        const sectionCd = originalClaimEvent.eventSections[i].sectionCd;
        if (!sectionsMap.has(sectionCd)) {
          sectionsMap.set(sectionCd, i);
        }
      }

      // loop through validate claim eventSections
      for (let j = 0; j < claimEventDynamicQuestions.eventSections.length; j++) {
        const section = claimEventDynamicQuestions.eventSections[j];
        const sectionCd = section.sectionCd;
        // check for sectionCd in map
        if (sectionsMap.has(sectionCd)) {
          // if present, push questions
          const index = sectionsMap.get(sectionCd);

          // reassign expanded values since originals are read-only
          originalClaimEvent.eventSections[index] = {
            ...originalClaimEvent.eventSections[index],
          };
          originalClaimEvent.eventSections[index].questions = {
            ...originalClaimEvent.eventSections[index].questions,
          };
          originalClaimEvent.eventSections[index].questions.question = [
            ...originalClaimEvent.eventSections[index].questions.question,
          ];

          originalClaimEvent.eventSections[index].questions.question.push(
            ...section.questions.question,
          );
        } else {
          // else push section and update map {sectionCd:index}
          originalClaimEvent.eventSections.push(section);
          sectionsMap.set(sectionCd, originalClaimEvent.eventSections.length - 1);
        }
      }
    }
    props.dispatch(setUpdatedClaimEventTemplate(originalClaimEvent));
  }, [claimEventDynamicQuestions]);

  return (
    <>
      <Helmet>
        <title>Report Claim - Contact Information</title>
      </Helmet>
      {isClaimEventContactsLoading ? (
        <LoaderMargin>
          <Loader />
        </LoaderMargin>
      ) : errors ? (
        <>
          <ApiError body="Unable to get coverage information." />
          <ReportClaimPreviousButton
            onClick={() => {
              handleStep(activeStep);
            }}
          >
            Back
          </ReportClaimPreviousButton>
        </>
      ) : (
        <ReportClaimFormContainer {...props} />
      )}
    </>
  );
};

ReportClaimStepThree.propTypes = {
  activeStep: PropTypes.any,
  dispatch: PropTypes.any,
  isClaimEventContactsLoading: PropTypes.bool,
  claimEventContacts: PropTypes.object,
  errors: PropTypes.any,
  customerId: PropTypes.any,
  claimEvent: PropTypes.any,
  dateValue: PropTypes.any,
  handleNext: PropTypes.any,
  handleStep: PropTypes.any,
  phoneNumber: PropTypes.any,
  phoneType: PropTypes.string,
  timeValue: PropTypes.any,
  selectedLocation: PropTypes.any,
  selectedVehicle: PropTypes.shape({
    policyNumber: PropTypes.string,
    id: PropTypes.string,
  }),
  claimDynamicQuestions: PropTypes.bool,
  state: PropTypes.any,
  claimEventDynamicQuestions: PropTypes.any,
  i18n: PropTypes.any,
};

const mapStateToProps = (state) => ({
  bestWayToContact: getBestWayToContact(state),
  cityText: getMailingCity(state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses),
  claimEvent: state.reportClaimSlice.claimEvent,
  claimEventContacts: state.reportClaimStepThreeSlice.claimEventContacts,
  claimEventContactsErrors: false,
  countyText: state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses[0].county,
  countryText: state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses[0].regionCd,
  customerId: state.customerSlice.customer.systemId,
  dateValue: state.reportClaimStepTwoSlice.dateValue,
  descriptionText: state,
  drivers: state,
  email: state.reportClaimStepThreeSlice.claimEventContacts.contact.emailInfo.emailAddr,
  errors: state.reportClaimStepThreeSlice.errors,
  isClaimEventContactsLoading: state.reportClaimStepThreeSlice.isClaimEventContactsLoading,
  isReadyForNextStep: state,
  locations: state,
  location1Text: getAddress(state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses),
  location2Text: state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses[0].addr2,
  location3Text: state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses[0].addr3,
  location4Text: state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses[0].addr4,
  manualDriverName: state,
  manualLicenseNumber: state,
  phoneNumber: getPhoneNumber(
    state.reportClaimStepThreeSlice.claimEventContacts.contact.phoneInfo,
    state.reportClaimStepThreeSlice.phoneTemp,
  ),
  phoneType: getPhoneType(
    state.reportClaimStepThreeSlice.claimEventContacts.contact.phoneInfo,
    state.reportClaimStepThreeSlice.phoneTemp,
  ),
  phoneInfo: state.reportClaimStepThreeSlice.claimEventContacts.contact.phoneInfo,
  postalValue: getMailingZip(state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses),
  selectedClaimEventTemplate: state,
  selectedClaimEventDescription: state.reportClaimSlice.selectedClaimEventTemplate.description,
  selectedDriver: state.reportClaimStepTwoSlice.selectedDriver,
  selectedLocation: state.reportClaimStepTwoSlice.selectedLocation,
  selectedState: state,
  selectedVehicle: state.reportClaimStepTwoSlice.selectedVehicle,
  state,
  stateText: getMailingState(state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses),
  stateValue: getMailingState(state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses),
  timeValue: state.reportClaimStepTwoSlice.timeValue,
  vehicles: state,
  stepTwoZipValue: state.reportClaimStepTwoSlice.zipValue,
  claimEventDynamicQuestions: state.reportClaimStepThreeSlice.claimEventDynamicQuestions,
  i18n: state.i18nSlice.i18n,
});
export default connect(mapStateToProps)(ReportClaimStepThree);
