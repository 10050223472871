import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { updatePolicyDeliveryPreference } from '../user-profile/profileAPI';
import { getPolicyEmail, getPolicyMailingAddress } from '../user-profile/selectors';
import {
  ProfileEditorsContainer,
  PolicyLabelMobile,
  PreferenceLabelMobile,
  PolicySummary,
  PolicyNumberLabel,
  PolicyMailingAddress,
  PolicyEmailAddress,
  ProfileRadioButtonContainer,
} from '../user-profile/styles';
import {
  GoPaperlessContainer,
  GoPaperlessPolicyPreferenceContainer,
  PreferenceRadioEmail,
  PreferenceRadioMail,
} from './styles';
import { getText } from '../../../utils/i18n';
import { useSnackbar } from 'notistack';
import { setPolicyPreference } from './policyDetailsReducer';

export const GoPaperless = (props) => {
  const { dispatch, i18n, isLoading, policies, policyNumber, policyPreference, policySystemId } =
    props;
  const { enqueueSnackbar } = useSnackbar();
  let policy = find(policies, ['policyNumber', policyNumber]);
  useEffect(() => {
    dispatch(setPolicyPreference(policy.insured.preferredDeliveryMethod));
  }, []);
  let onDeliveryPreferenceChange = (preference, policy) => {
    return () => {
      props.dispatch(updatePolicyDeliveryPreference(policies, policy, preference, enqueueSnackbar));
    };
  };

  return (
    <GoPaperlessContainer>
      <p
        dangerouslySetInnerHTML={{
          __html: getText(
            i18n,
            'general.paperlessSubtitle',
            'Enjoy convenient delivery of your policy-related documents to your email.',
          ),
        }}
      />
      <br />
      {policies.map((policy, index) => {
        return (
          policy.policyRef === policySystemId && (
            <GoPaperlessPolicyPreferenceContainer key={policy.policyRef}>
              <PolicySummary>
                <PolicyLabelMobile>Policy</PolicyLabelMobile>
                <PolicyNumberLabel
                  aria-label={`Policy Number ${policy.policyNumber} Status ${policy.statusCd}`}
                >
                  {policy.policyNumber + ' (' + policy.statusCd + ')'}
                </PolicyNumberLabel>
                <PolicyEmailAddress>Email: {getPolicyEmail(policy)}</PolicyEmailAddress>
                <PolicyMailingAddress>
                  Address: {getPolicyMailingAddress(policy)}
                </PolicyMailingAddress>
              </PolicySummary>
              <ProfileEditorsContainer>
                <PreferenceLabelMobile>Preference</PreferenceLabelMobile>
                <ProfileRadioButtonContainer>
                  <PreferenceRadioEmail>
                    <label htmlFor="radioEmail">
                      <input
                        aria-label={`Preference for ${policy.policyNumber} Paperless`}
                        type="radio"
                        name={'deliveryPref_' + index}
                        value="Email"
                        id="radioEmail"
                        onChange={onDeliveryPreferenceChange('Email', policy)}
                        checked={policyPreference === 'Email'}
                        disabled={isLoading}
                      />{' '}
                      Paperless
                    </label>
                  </PreferenceRadioEmail>
                  <PreferenceRadioMail>
                    <label htmlFor="radioBatch">
                      <input
                        aria-label={`Preference for ${policy.policyNumber} Mail`}
                        type="radio"
                        name={'deliveryPref_' + index}
                        value="Batch"
                        id="radioBatch"
                        onChange={onDeliveryPreferenceChange('Batch', policy)}
                        checked={policyPreference === 'Batch'}
                        disabled={isLoading}
                      />{' '}
                      Mail
                    </label>
                  </PreferenceRadioMail>
                </ProfileRadioButtonContainer>
              </ProfileEditorsContainer>
            </GoPaperlessPolicyPreferenceContainer>
          )
        );
      })}
    </GoPaperlessContainer>
  );
};

GoPaperless.propTypes = {
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  i18n: PropTypes.object,
  policies: PropTypes.array,
  policyNumber: PropTypes.string,
  policyPreference: PropTypes.string,
};

const mapStateToProps = (state) => ({
  i18n: state.i18nSlice.i18n,
  isLoading: state.profileSlice.isLoading,
  policies: state.policySlice.policies,
  policyPreference: state.policyDetailsSlice.policyPreference,
});

export default connect(mapStateToProps)(GoPaperless);
