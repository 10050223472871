import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPrimaryPhoneInfo, getSecondaryPhoneInfo } from '../user-profile/selectors';
import EditingPhones from './edit-phone';
import { setEditingPhone, setEmailErrors, setPhoneErrors } from '../user-profile/profileReducer';
import {
  EditPhoneButtonContainer,
  EditPhonesLink,
  PhoneDescription,
  PhoneNumberContainer,
  PhoneNumbersContainer,
  PhoneNumbersLabel,
  ProfileError,
  ProfilePhoneNumberLabel,
  StyledSecondaryPhoneContainer,
} from './styles';
import { getText } from '../../../utils/i18n';
import TooltipInfo from '../../common/tooltip';

export const PhoneDetails = (props) => {
  const {
    ariaLabel,
    editingEmailAndPhone,
    phoneErrors,
    phoneNumberPrimary,
    phoneNumberPrimaryFromCustomer,
    phoneNumberSecondaryFromCustomer,
    phoneNumberSecondary,
    phoneNumberPrimaryType,
    phoneNumberSecondaryType,
    setPhoneNumberPrimary,
    setPhoneNumberSecondary,
    setPhoneNumberPrimaryName,
    setPhoneNumberSecondaryName,
    phoneNumberPrimaryName,
    phoneNumberSecondaryName,
  } = props;

  React.useEffect(() => {
    props.dispatch(setEmailErrors(null));
    props.dispatch(setPhoneErrors(null));
  }, []);

  return (
    <>
      {phoneErrors && <ProfileError>{phoneErrors}</ProfileError>}
      <PhoneNumberContainer>
        {phoneNumberPrimaryFromCustomer && (
          <>
            <>
              <PhoneNumbersLabel>Phone Numbers</PhoneNumbersLabel>
              <PhoneNumbersContainer>
                Primary Phone {phoneNumberPrimaryFromCustomer}
                {phoneNumberPrimaryType?.value && ` (${phoneNumberPrimaryType.value})`}
                <StyledSecondaryPhoneContainer>
                  {phoneNumberSecondaryFromCustomer && (
                    <>
                      Secondary Phone {phoneNumberSecondaryFromCustomer}
                      {phoneNumberSecondaryType?.value && ` (${phoneNumberSecondaryType.value})`}
                    </>
                  )}
                </StyledSecondaryPhoneContainer>
              </PhoneNumbersContainer>
            </>
          </>
        )}
        {editingEmailAndPhone ? (
          <EditingPhones
            ariaLabel={ariaLabel}
            setPhoneNumberPrimary={setPhoneNumberPrimary}
            setPhoneNumberSecondary={setPhoneNumberSecondary}
            setPhoneNumberPrimaryName={setPhoneNumberPrimaryName}
            setPhoneNumberSecondaryName={setPhoneNumberSecondaryName}
            phoneNumberPrimary={phoneNumberPrimary}
            phoneNumberSecondary={phoneNumberSecondary}
            phoneNumberPrimaryName={phoneNumberPrimaryName}
            phoneNumberSecondaryName={phoneNumberSecondaryName}
          />
        ) : null}
      </PhoneNumberContainer>
    </>
  );
};

PhoneDetails.propTypes = {
  ariaLabel: PropTypes.string,
  dispatch: PropTypes.func,
  editingEmailAndPhone: PropTypes.bool,
  isDashboard: PropTypes.bool,
  notificationPhone: PropTypes.string,
  phoneErrors: PropTypes.any,
  phoneNumberPrimary: PropTypes.string,
  phoneNumberSecondary: PropTypes.string,
  primaryPhoneNameType: PropTypes.string,
  secondaryPhoneNameType: PropTypes.string,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  phoneErrors: state.profileSlice.phoneErrors,
  editingEmailAndPhone: state.profileSlice.editingEmailAndPhone,
  notificationPhone: state.notificationSlice.notificationPhone,
  phoneNumberPrimaryFromCustomer: getPrimaryPhoneInfo(state)?.phoneNumber
    ? getPrimaryPhoneInfo(state)?.phoneNumber
    : '',
  phoneNumberSecondaryFromCustomer: getSecondaryPhoneInfo(state)?.phoneNumber
    ? getSecondaryPhoneInfo(state)?.phoneNumber
    : '',
  phoneNumberPrimaryType: getPrimaryPhoneInfo(state)?.phoneName
    ? {
        value: getPrimaryPhoneInfo(state)?.phoneName,
        label: getPrimaryPhoneInfo(state)?.phoneName,
      }
    : { value: null, label: 'Select Type' },
  phoneNumberSecondaryType: getSecondaryPhoneInfo(state)?.phoneName
    ? {
        value: getSecondaryPhoneInfo(state)?.phoneName,
        label: getSecondaryPhoneInfo(state)?.phoneName,
      }
    : { value: null, label: 'Select Type' },

  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(PhoneDetails);
