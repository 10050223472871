import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ChangePropertyDeductibleButton,
  ChangePropertyDeductibleButtonsContainer,
  ChangePropertyDeductibleContainer,
  ChangePropertyDeductibleDateInput,
  ChangePropertyDeductibleField,
  ChangePropertyDeductibleFieldHeader,
  ChangePropertyDeductibleTextAreaField,
  LinkStyleButton,
  RequiredField,
  TwoColumnContainer,
} from './styles';
import Select from 'react-select';
import moment from 'moment';
import { createField, formatRecapFieldDate, getDeductibles } from '../selector';
import {
  setNewDeductible,
  setNewReason,
  setNewRequestedEffectiveDate,
  setPolicyChangeCoverageRecapFields,
  setPolicyChangePreviewData,
} from '../../policyChangeReducer';
import {
  getLatestPolicyInfo,
  preSubmitPolicyChange,
  submitPolicyChange,
} from '../../policyChangeAPI';
import Loader from '../../../../common/loader';
import PolicyChangePreview from '../policy-change-preview';
import { Spacer, StyledLoaderContainer } from '../../styles';
import { ISO_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../../common/constants';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useNavigate } from 'react-router';

export const ChangePropertyDeductible = (props) => {
  const {
    dispatch,
    customerId,
    newDeductible,
    newReason,
    newRequestedEffectiveDate,
    selectedPolicy,
    selectedPolicyChangeTemplate,
    changeCoverageShowNewPremium,
    policyChangePreview,
    i18n,
  } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(
    newRequestedEffectiveDate !== ''
      ? moment(newRequestedEffectiveDate).format(ISO_DATE_FORMAT)
      : todayDt,
  );
  const [selectedDeductible, setSelectedDeductible] = useState({
    label: newDeductible ? newDeductible?.label : '-- Select a deductible amount --',
    value: newDeductible ? newDeductible?.value : '',
  });
  const [reason, setReason] = useState(newReason);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    const areRequiredFieldsCompleted = effectiveDate.length > 0 && selectedDeductible.value !== '';
    if (areRequiredFieldsCompleted !== isNextButtonEnabled) {
      setIsNextButtonEnabled(areRequiredFieldsCompleted);
    }
  };

  checkRequiredFields();

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(createField('New Deductible', selectedDeductible.label, 'text'));
    fields.push(
      createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
    );
    fields.push(createField('Reason for Change', reason, 'text'));
    return fields;
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, []);

  useEffect(() => {
    setIsSubmitting(false);
  }, [policyChangePreview]);

  const createPayload = () => {
    return {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
        },
      ],
      questionReplies: [
        {
          questionReply: [
            {
              name: 'Task.Note001',
              visibleInd: true,
              value: selectedDeductible.value,
            },
          ],
          id: 'QuestionReplies-001',
        },
      ],
      sourceCd: 'ServicePortal',
    };
  };

  const onSubmitButtonClick = () => {
    setIsSubmitting(true);
    setIsNextButtonEnabled(false);
    const payload = createPayload();

    // set fields for recap/result
    props.dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));

    // submit API call
    props.dispatch(submitPolicyChange(payload));
  };

  const onNextButtonClick = () => {
    dispatch(setNewDeductible(selectedDeductible));
    dispatch(setNewReason(reason));
    dispatch(setNewRequestedEffectiveDate(moment(effectiveDate).format(DISPLAY_DATE_FORMAT)));
    setIsSubmitting(true);

    const fields = [];
    fields.push(
      createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
    );
    fields.push(createField('Reason', reason, 'text'));
    const payload = createPayload();

    props.dispatch(
      setPolicyChangePreviewData({
        fields,
        payload,
        recapFields: createPolicyChangeCoverageSummary(),
      }),
    );

    // get current policy deductible
    props.dispatch(getLatestPolicyInfo(selectedPolicy.value));

    // submit API call
    props.dispatch(preSubmitPolicyChange(payload));
  };

  const navigate = useNavigate();
  return (
    <>
      {policyChangePreview ? (
        <PolicyChangePreview />
      ) : (
        <ChangePropertyDeductibleContainer>
          <TwoColumnContainer>
            <ChangePropertyDeductibleField>
              <ChangePropertyDeductibleFieldHeader>
                New Deductible <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(
                    i18n,
                    'changeCoverage.changePropertyDeductible.newDeductibleTooltip',
                  )}
                />
              </ChangePropertyDeductibleFieldHeader>
              <Select
                name="deductibleList"
                id="deductibleList"
                aria-label="New Deductible"
                defaultValue={selectedDeductible}
                options={getDeductibles(
                  selectedPolicyChangeTemplate.policyChangeSections[0].questions[0].question[0]
                    .constraints,
                )}
                onChange={(selectedDeductible) => setSelectedDeductible(selectedDeductible)}
              />
            </ChangePropertyDeductibleField>
            <ChangePropertyDeductibleField>
              <ChangePropertyDeductibleFieldHeader>
                Requested Effective Date <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(
                    i18n,
                    'changeCoverage.changePropertyDeductible.effectiveDateTooltip',
                  )}
                />
              </ChangePropertyDeductibleFieldHeader>
              <ChangePropertyDeductibleDateInput
                aria-label="Requested Effective Date"
                value={effectiveDate}
                min={todayDt}
                onChange={(e) => {
                  setEffectiveDate(e.target.value);
                }}
              />
            </ChangePropertyDeductibleField>
          </TwoColumnContainer>
          <ChangePropertyDeductibleField>
            <ChangePropertyDeductibleFieldHeader>
              Reason{' '}
              <TooltipInfo
                title={getText(i18n, 'changeCoverage.changePropertyDeductible.reasonTooltip')}
              />
            </ChangePropertyDeductibleFieldHeader>
            <ChangePropertyDeductibleTextAreaField
              value={reason}
              aria-label="Reason"
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </ChangePropertyDeductibleField>
          <ChangePropertyDeductibleButtonsContainer>
            <LinkStyleButton
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Cancel
            </LinkStyleButton>
            {changeCoverageShowNewPremium ? (
              <>
                <ChangePropertyDeductibleButton
                  id="Next"
                  disabled={!isNextButtonEnabled}
                  onClick={() => {
                    onNextButtonClick();
                  }}
                >
                  Next
                </ChangePropertyDeductibleButton>
                {isSubmitting ? (
                  <StyledLoaderContainer>
                    <Loader loaderheight="20px" loaderwidth="20px" />
                  </StyledLoaderContainer>
                ) : (
                  <Spacer />
                )}
              </>
            ) : (
              <>
                <ChangePropertyDeductibleButton
                  disabled={!isNextButtonEnabled}
                  onClick={() => {
                    onSubmitButtonClick();
                  }}
                >
                  Submit
                </ChangePropertyDeductibleButton>
                {isSubmitting ? (
                  <StyledLoaderContainer>
                    <Loader loaderheight="20px" loaderwidth="20px" />
                  </StyledLoaderContainer>
                ) : (
                  <Spacer />
                )}
              </>
            )}
          </ChangePropertyDeductibleButtonsContainer>
        </ChangePropertyDeductibleContainer>
      )}
    </>
  );
};
ChangePropertyDeductible.propTypes = {
  dispatch: PropTypes.func,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  selectedPolicy: PropTypes.object,
  policyChangePreview: PropTypes.object,
  changeCoverageShowNewPremium: PropTypes.bool,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  customerId: state.customerSlice.customer.systemId,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  newDeductible: state.policyChangeSlice.newDeductible,
  newReason: state.policyChangeSlice.newReason,
  newRequestedEffectiveDate: state.policyChangeSlice.newRequestedEffectiveDate,
  policyChangePreview: state.policyChangeSlice.policyChangePreview,
  changeCoverageShowNewPremium:
    // state.configurationSlice.features.features.changeCoverageShowNewPremium,
    !!state.policyChangeSlice.selectedPolicyChangeTemplate.showPremChgInd,
  i18n: state.i18nSlice.i18n,
});
export default connect(mapStateToProps)(ChangePropertyDeductible);
