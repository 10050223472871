import styled from 'styled-components';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export const NewPropertyCoverageContainer = styled.div.attrs({
  id: 'NewPropertyCoverageContainer',
})`
  margin-top: 10px;
  width: 100%;
`;
export const NewPropertyCoverageField = styled.div.attrs({})`
  min-width: 200px;
`;

export const NewPropertyCoverageFieldHeader = styled.h4.attrs({
  id: 'NewPropertyCoverageFieldHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const NewPropertyCoverageTextAreaField = styled.textarea.attrs({
  id: 'NewPropertyCoverageTextAreaField',
})`
  height: 100px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const NewPropertyCoverageButtonsContainer = styled.div.attrs({})`
  text-align: right;
  display: flex;
  margin-right: 2%;
  margin-top: 16px;
  @media (max-width: 384px) {
    margin-top: 5px;
    text-align: center;
  }
`;
export const NewPropertyCoverageButton = styled.button.attrs({})`
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 384px) {
    margin-bottom: 5px;
  }
`;
export const NewPropertyCoverageFileUploadButton = styled.div.attrs({
  id: 'newPropertyCoverageFileUploadButton',
})`
  padding: 8px 24px;
  height: 18px;
  display: inline-block;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :hover {
    background-color: ${(props) =>
      props.theme.primaryHoverColor
        ? props.theme.primaryHoverColor
        : props.theme.primaryColor
        ? props.theme.primaryColor
        : 'gray'};
  }
  :active {
    background-color: ${(props) => props.theme.primaryActiveColor};
  }
  @media (max-width: 384px) {
    margin-bottom: 5px;
  }
`;
export const PitcureIcon = styled(InsertPhotoIcon).attrs({
  id: 'pictureIcon',
})`
  position: relative;
  bottom: 3px;
  right: 4px;
  @media (max-width: 299px) {
  }
`;
export const SelectFileText = styled.span`
  position: relative;
  bottom: 9px;
  left: 4px;
`;
export const PicturesContainer = styled.div.attrs({})`
  border: 2px solid ${(props) => props.theme.secondaryColor};
  padding: 10px 0 5px 10px;
`;
export const ThumbnailContainer = styled.div.attrs({})`
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
`;
export const ImgThumbnail = styled.img.attrs({})`
  width: 120px;
  height: 120px;
`;
export const FileThumbnailContainer = styled.div.attrs({})`
  display: inline-block;
  margin-right: 20px;
`;
export const GarbageIconContainer = styled.div.attrs({})`
  margin-left: 15px;
`;
export const GarbageIcon = styled(DeleteOutlineIcon).attrs({
  className: 'fa fa-trash-o',
})`
  cursor: pointer;
  margin-left: -15px;
`;
export const FileName = styled.span`
  word-break: break-word;
  overflow: hidden;
  height: 40px;
  display: inline-block;
  max-width: 120px;
`;
export const FileThumbnail = styled(InsertDriveFileIcon).attrs({
  className: 'fa fa-file',
})`
  font-size: 80px;
  display: block;
`;
export const LinkStyleButton = styled.button.attrs({
  id: 'cancelButton',
})`
  background-color: initial;
  color: ${(props) => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 0;
  margin-top: 16px;

  :hover {
    background-color: initial;
    color: ${(props) => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${(props) => props.theme.primaryActiveColor};
  }
`;
