import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submitClaim } from '../report-claim-step-five-review-submit/reportClaimApi';
import {
  ReportClaimButtonContainer,
  ReportClaimCancelButton,
  ReportClaimNextButton,
  ReportClaimPreviousButton,
} from './styles';
import {
  requiredQuestionsCompleteCheck,
  stepOneRequiredFieldCheck,
  stepThreeRequiredFieldCheck,
  stepTwoRequiredFieldCheck,
} from './utils';
import { useNavigate } from 'react-router';
import {
  setBestWayToContact,
  setPhoneTemp,
  setPhoneType,
} from '../report-claim-step-three/reportClaimStepThreeReducer';

export const ReportClaimButtons = (props) => {
  const {
    activeStep,
    bestWayToContact,
    body,
    dispatch,
    handleNext,
    handleStep,
    phoneField,
    phoneTypeField,
    setFinalLocation,
    submitForReview,
  } = props;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const updatePhones = () => {
    dispatch(setPhoneTemp(phoneField));
    dispatch(setPhoneType(phoneTypeField));

    if (bestWayToContact === '') {
      dispatch(setBestWayToContact(''));
    } else {
      dispatch(setBestWayToContact(bestWayToContact));
    }
  };
  const nextButtonClickHandler = () => {
    switch (activeStep) {
      case 0:
        break;
      case 1:
        setFinalLocation();
        break;
      case 2:
        updatePhones();
        break;
      case 3:
        submitForReview();
        break;
      case 4:
        setButtonDisabled(true);
        dispatch(submitClaim(body));
        break;
      case 5:
        break;

      default:
        break;
    }
    handleNext();
  };

  const areRequiredFieldsForStepCompleted = () => {
    switch (activeStep) {
      case 0:
        return !stepOneRequiredFieldCheck(props);
      case 1:
        return stepTwoRequiredFieldCheck(props);
      case 2:
        return !stepThreeRequiredFieldCheck(props);
      case 3:
        return !requiredQuestionsCompleteCheck(props?.question);
      case 4:
        return buttonDisabled;
      default:
        break;
    }
  };
  const navigate = useNavigate();
  return (
    <ReportClaimButtonContainer>
      <ReportClaimCancelButton onClick={() => navigate('/dashboard')}>
        Cancel
      </ReportClaimCancelButton>
      <ReportClaimNextButton
        id="Next"
        disabled={areRequiredFieldsForStepCompleted()}
        onClick={() => {
          nextButtonClickHandler();
        }}
      >
        {activeStep !== 4 ? `Next` : `Submit`}
      </ReportClaimNextButton>
      {activeStep !== 0 ? (
        <ReportClaimPreviousButton
          onClick={() => {
            handleStep(activeStep);
          }}
        >
          Back
        </ReportClaimPreviousButton>
      ) : null}
    </ReportClaimButtonContainer>
  );
};
ReportClaimButtons.propTypes = {
  activeStep: PropTypes.any,
  body: PropTypes.any,
  bestWayToContact: PropTypes.any,
  BEST_WAY_TO_CONTACT: PropTypes.any,
  dateValue: PropTypes.any,
  dispatch: PropTypes.any,
  handleNext: PropTypes.any,
  handleStep: PropTypes.any,
  phoneField: PropTypes.string,
  phoneTypeField: PropTypes.any,
  question: PropTypes.any,
  selectedClaimEventTemplate: PropTypes.any,
  setFinalLocation: PropTypes.any,
  submitForReview: PropTypes.any,
  timeValue: PropTypes.any,
};
export const mapStateToProps = (state) => ({
  claimEventTemplates: state.reportClaimSlice.claimEventTemplates,
  errors: state.reportClaimSlice.errors,
  selectedClaimEventTemplate: state.reportClaimSlice.selectedClaimEventTemplate,
  customerId: state.customerSlice.customer.systemId,
  dateValue: state.reportClaimStepTwoSlice.dateValue,
  timeValue: state.reportClaimStepTwoSlice.timeValue,
  features: state.configurationSlice.features.features,
  i18n: state.i18nSlice.i18n,
  claimEvent: state.reportClaimSlice.selectedClaimEventTemplate?.description,
  claimEventContacts: state.reportClaimSlice.claimEventContacts,
  claimEventContactsErrors: state.reportClaimSlice.claimEventContactsErrors,
  //   drivers: getDrivers(state.reportClaimSlice.claimEvent),
  driverRelationshipOptions: state.reportClaimSlice.driverRelationshipOptions,
  isClaimEventLoading: state.reportClaimSlice.isClaimEventLoading,
  isReadyForNextStep: state.reportClaimStepTwoSlice.isReadyForNextStep,
  //   locations: getLocations(state.reportClaimSlice.claimEvent),
  manualLicenseNumber: state.reportClaimStepTwoSlice.manualLicenseNumber,
  selectedLocation: state.reportClaimStepTwoSlice.selectedLocation,
  //   vehicles: getVehicles(state.reportClaimSlice.claimEvent),

  state,
});
export default connect(mapStateToProps)(ReportClaimButtons);
