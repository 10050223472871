import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveCustomerEmail, saveNotificationEmail } from './profileAPI';
import { setEditingEmail, setEmailErrors } from './profileReducer';
import { getCustomerParty } from './selectors';
import { CancelButton, EditEmailContainer, EmailAddressInput, SaveEmailButton } from './styles';
import { isEmail } from '../../../utils/validators/generalValidators';
import { useSnackbar } from 'notistack';
const EditingEmail = (props) => {
  const { customerSubscriptionsFull, dispatch, customerId, notificationEmail } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = React.useState(notificationEmail ? notificationEmail : '');
  const cancelEmail = () => {
    dispatch(setEditingEmail(false));
    dispatch(setEmailErrors(null));
  };
  const saveEmail = () => {
    dispatch(saveNotificationEmail(customerId, customerSubscriptionsFull, email, enqueueSnackbar));
  };

  const isValidEmail = () => {
    return email !== '' && isEmail(email);
  };
  return (
    <>
      <EditEmailContainer>
        <>
          <EmailAddressInput>
            <input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              aria-label="Email Address"
            />
          </EmailAddressInput>
        </>
      </EditEmailContainer>
      <SaveEmailButton disabled={!isValidEmail()} onClick={() => saveEmail(props, email)}>
        Apply
      </SaveEmailButton>
      <CancelButton
        onClick={() => {
          cancelEmail();
        }}
      >
        Cancel
      </CancelButton>
    </>
  );
};

EditingEmail.propTypes = {
  customerSubscriptionsFull: PropTypes.any,
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  customerParty: PropTypes.object,
  customer: PropTypes.object,
  customerId: PropTypes.string,
  cancelEmail: PropTypes.func,
  notificationEmail: PropTypes.string,
};
const mapStateToProps = (state) => ({
  isLoading: state.profileSlice.isLoading,
  emailErrors: state.profileSlice.emailErrors,
  editingEmail: state.profileSlice.editingEmail,
  customerId: state.loginSlice.user.preferred_username,
  systemId: state.customerSlice.customer.systemId,
  customer: state.customerSlice.customer,
  customerSubscriptionsFull: state.notificationSlice.customerSubscriptionsFull,
  customerParty: getCustomerParty(state),
  notificationEmail: state.notificationSlice.notificationEmail,
});

export default connect(mapStateToProps)(EditingEmail);
