import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '../../common/loader';
import { getMailingAddress } from '../user-profile/selectors';
import { isEmail, isPhone } from '../../../utils/validators/generalValidators';
import { savePhones } from './edit-utils';
import { ApiError } from '../../common/api-error';
import { getCustomerParty, getPrimaryPhoneInfo, getSecondaryPhoneInfo } from './selectors';
import {
  resetState,
  setEmailErrors,
  setPhoneErrors,
  setLoading,
  setEditingEmailAndPhone,
  setEmailAddress,
} from '../user-profile/profileReducer';
import {
  AddressHeader,
  ApiErrorSection,
  CancelButton,
  CustomerSubHeader,
  EditButton,
  FullNameContainer,
  FullNameField,
  LoaderMargin,
  MailingAddressContainer,
  MailingAddressLabel,
  NameLabel,
  OnlineProfileHeader,
  OuterProfileContainer,
  ProfileDetailsContainer,
  ProfileMailingAddress,
  SaveCancelContainer,
  SavePhoneAndEmailButton,
} from './styles';
import PhoneDetails from './phone-details';
import EmailDetails from './email-details';
import { getText } from '../../../utils/i18n';

export const CustomerInformation = (props) => {
  const {
    dispatch,
    emailAddress,
    editingEmailAndPhone,
    formErrors,
    i18n,
    isLoading,
    profileMailingAddress,
    phoneNumberPrimaryFromCustomer,
    phoneNumberSecondaryFromCustomer,
    phoneNumberPrimaryType,
    phoneNumberSecondaryType,
    state,
    customerParty,
  } = props;

  const [email, setEmail] = useState(emailAddress);

  const [phoneNumberPrimary, setPhoneNumberPrimary] = useState(phoneNumberPrimaryFromCustomer);
  const [phoneNumberPrimaryName, setPhoneNumberPrimaryName] = useState(phoneNumberPrimaryType);
  const [phoneNumberSecondary, setPhoneNumberSecondary] = useState(
    phoneNumberSecondaryFromCustomer,
  );
  const [phoneNumberSecondaryName, setPhoneNumberSecondaryName] =
    useState(phoneNumberSecondaryType);

  useEffect(() => {
    dispatch(resetState());
    dispatch(setLoading(true));
    dispatch(setEmailErrors(null)); //may need to remove probably covered by reset state above
    dispatch(setPhoneErrors(null));

    dispatch(setLoading(false)); //either moved inside api call or left here for .await
  }, []);

  const savePhoneAndEmail = async () => {
    if (emailAddress === getCustomerParty(state)?.emailInfo) {
      return dispatch(setEmailErrors('Please enter a valid e-mail address.'));
    }
    dispatch(setEmailAddress(email));
    dispatch(
      savePhones(
        props,
        phoneNumberPrimary, //new Primary
        phoneNumberSecondary, //new Secondary
        phoneNumberPrimaryName.value, //new Primary Type
        phoneNumberSecondary !== '' ? phoneNumberSecondaryName.value : null, //new Secondary Type
        phoneNumberSecondaryFromCustomer ? phoneNumberSecondaryFromCustomer : null, //old SecondaryPhoneNumber
        phoneNumberSecondaryType ? phoneNumberSecondaryType : null, //old SecondaryPhoneType
        phoneNumberPrimaryFromCustomer ? phoneNumberPrimaryFromCustomer : null, //oldPrimaryPhoneNumber
        phoneNumberPrimaryType ? phoneNumberPrimaryType : null, //oldPrimaryPhoneType
        email,
      ),
    );
    dispatch(setEditingEmailAndPhone(false));
    dispatch(setEmailErrors(null));
    dispatch(setPhoneErrors(null));
  };

  const arePhoneInputsValid = () => {
    let isValid =
      phoneNumberPrimary !== '' &&
      isPhone(phoneNumberPrimary) &&
      phoneNumberPrimaryName.value !== null;
    if (phoneNumberSecondary.trim() !== '') {
      isValid = isValid && isPhone(phoneNumberSecondary) && phoneNumberSecondaryName.value !== null;
    }
    return isValid;
  };
  const isEmailValid = () => {
    return (emailAddress === '' && email === '') || isEmail(email);
  };
  const getMailingAddressLineOne = () => {
    const { addr1 } = profileMailingAddress;
    return `${addr1 || ''}`;
  };
  const getMailingAddressLineTwo = () => {
    const { city, stateProvCd, postalCode } = profileMailingAddress;
    return `${city || ''}${city ? ',' : ''} ${stateProvCd || ''} ${postalCode || ''}`;
  };

  return (
    <OuterProfileContainer>
      <OnlineProfileHeader>
        {getText(i18n, 'customerInformation.title', 'Customer Information')}
      </OnlineProfileHeader>
      {isLoading ? (
        <LoaderMargin>
          <Loader />
        </LoaderMargin>
      ) : (
        <>
          <CustomerSubHeader>
            {getText(
              i18n,
              'customerInformation.subHeading',
              'Please fill out all information accurately',
            )}
          </CustomerSubHeader>
          {formErrors && (
            <ApiErrorSection>
              <ApiError
                body={formErrors}
                variant="nomargin"
                titleColor="#d03434"
                title="Form Errors:"
              />
            </ApiErrorSection>
          )}
          <ProfileDetailsContainer>
            <FullNameContainer>
              <NameLabel>Customer Name</NameLabel>
              <FullNameField>{customerParty.nameInfo.commercialName}</FullNameField>
            </FullNameContainer>
            <EmailDetails email={email} setEmail={setEmail} />
            <PhoneDetails
              setPhoneNumberPrimary={setPhoneNumberPrimary}
              setPhoneNumberSecondary={setPhoneNumberSecondary}
              setPhoneNumberPrimaryName={setPhoneNumberPrimaryName}
              setPhoneNumberSecondaryName={setPhoneNumberSecondaryName}
              phoneNumberPrimary={phoneNumberPrimary}
              phoneNumberSecondary={phoneNumberSecondary}
              phoneNumberPrimaryName={phoneNumberPrimaryName}
              phoneNumberSecondaryName={phoneNumberSecondaryName}
            />
            <MailingAddressContainer>
              <AddressHeader>Address</AddressHeader>
              <MailingAddressLabel>Mailing Address</MailingAddressLabel>
              <ProfileMailingAddress>
                {getMailingAddressLineOne()} <br />
                {getMailingAddressLineTwo()}
              </ProfileMailingAddress>
            </MailingAddressContainer>
            {!editingEmailAndPhone && (
              <EditButton
                onClick={() => {
                  dispatch(setEditingEmailAndPhone(true));
                }}
              >
                Edit
              </EditButton>
            )}
            {editingEmailAndPhone && (
              <SaveCancelContainer>
                <CancelButton
                  onClick={() => {
                    dispatch(setEditingEmailAndPhone(false));
                    dispatch(setEmailErrors(null));
                    dispatch(setPhoneErrors(null));
                  }}
                >
                  Cancel
                </CancelButton>
                <SavePhoneAndEmailButton
                  onClick={() => {
                    savePhoneAndEmail();
                  }}
                  disabled={!arePhoneInputsValid() || !isEmailValid()}
                >
                  Apply
                </SavePhoneAndEmailButton>
              </SaveCancelContainer>
            )}
          </ProfileDetailsContainer>
        </>
      )}
    </OuterProfileContainer>
  );
};

CustomerInformation.propTypes = {
  dispatch: PropTypes.func,
  i18n: PropTypes.object,
  isLoading: PropTypes.bool,
  customer: PropTypes.object,
  emailAddress: PropTypes.string,
  formErrors: PropTypes.string,
  profileName: PropTypes.string,
  profileMailingAddress: PropTypes.object,
  phoneNumberPrimary: PropTypes.string,
  phoneNumberSecondary: PropTypes.string,
  phoneNumberPrimaryName: PropTypes.object,
  phoneNumberSecondaryName: PropTypes.object,
  phoneErrors: PropTypes.any,
  emailErrors: PropTypes.any,
  editingEmailAndPhone: PropTypes.bool,
  primaryPhoneNameType: PropTypes.string,
  secondaryPhoneNameType: PropTypes.string,
  state: PropTypes.object,
  customerParty: PropTypes.object,
};
const mapStateToProps = (state) => ({
  i18n: state.i18nSlice.i18n,
  isLoading: state.profileSlice.isLoading,
  systemId: state.customerSlice.customer.systemId,
  customer: state.customerSlice.customer, //do not remove: used in patch customer
  customerParty: getCustomerParty(state),
  formErrors: state.profileSlice.formErrors,
  phoneErrors: state.profileSlice.phoneErrors,
  emailErrors: state.profileSlice.emailErrors,
  editingEmailAndPhone: state.profileSlice.editingEmailAndPhone,
  profileMailingAddress: getMailingAddress(state),
  emailAddress: getCustomerParty(state).emailInfo
    ? getCustomerParty(state).emailInfo.emailAddr
    : '',
  oldEmailAddress: getCustomerParty(state)?.emailInfo?.emailAddr,
  phoneNumberPrimaryType: getPrimaryPhoneInfo(state)?.phoneName
    ? {
        value: getPrimaryPhoneInfo(state)?.phoneName,
        label: getPrimaryPhoneInfo(state)?.phoneName,
      }
    : { value: null, label: 'Select Type' },
  phoneNumberSecondaryType: getSecondaryPhoneInfo(state)?.phoneName
    ? {
        value: getSecondaryPhoneInfo(state)?.phoneName,
        label: getSecondaryPhoneInfo(state)?.phoneName,
      }
    : { value: null, label: 'Select Type' },
  phoneNumberPrimaryFromCustomer: getPrimaryPhoneInfo(state)?.phoneNumber
    ? getPrimaryPhoneInfo(state)?.phoneNumber
    : '',
  phoneNumberSecondaryFromCustomer: getSecondaryPhoneInfo(state)?.phoneNumber
    ? getSecondaryPhoneInfo(state)?.phoneNumber
    : '',
  phoneNumberPrimaryId: getPrimaryPhoneInfo(state)?.id, //do not remove: used in patch customer details call
  phoneNumberSecondaryId: getSecondaryPhoneInfo(state)?.id, //dnr
  emailId: getCustomerParty(state)?.emailInfo?.id, //dnr
});

export default connect(mapStateToProps)(CustomerInformation);
