import styled from 'styled-components';
import Select from 'react-select';

export const ChangeAddressContainer = styled.div.attrs({
  id: 'ChangeAddressContainer',
})`
  margin-top: 30px;
  width: 100%;
`;
export const TwoColumnContainer = styled.div.attrs({})`
  display: grid;
  @media (max-width: 599px) {
    grid-template-columns: 50% 50%;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 46% 8% 46%;
    grid-template-rows: 1fr;
  }
`;
export const RequestedEffectiveDateField = styled.div.attrs({})`
  min-width: 200px;
`;

export const ReasonForChangeField = styled.div.attrs({})`
  width: 100%;
  @media (min-width: 1280px) {
    width: 70%;
  }
`;

export const RequestedEffectiveDateHeader = styled.h4.attrs({
  id: 'requestedEffectiveDateHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ReasonForChangeHeader = styled.h4.attrs({
  id: 'reasonForChangeHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const FieldHeader = styled.h4.attrs({})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const AddressToBeChangedHeader = styled.h4.attrs({
  id: 'addressToBeChangedHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const ChangeAddressButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
  margin-top: 10%;
  display: flex;
`;

export const SubmitButton = styled.button.attrs({
  id: 'Submit',
})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;
export const ReasonForChangeTextArea = styled.textarea.attrs({
  id: 'reason',
})`
  width: 69%;
  @media (max-width: 599px) {
    width: 100%;
  }
`;
export const AddressToBeChangedSelect = styled(Select)`
  width: 75%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 900px) {
    width: 45%;
  }
`;
export const LinkStyleButton = styled.button.attrs({
  id: 'cancelButton',
})`
  background-color: initial;
  color: ${(props) => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 0;

  :hover {
    background-color: initial;
    color: ${(props) => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${(props) => props.theme.primaryActiveColor};
  }
`;
