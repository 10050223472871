import styled from 'styled-components';
import { Link } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const CreateAccountContainer = styled.div.attrs({ id: 'createAccountContainer' })`
  min-width: 200px;
  margin: 2% 2%;
  display: block;
  @media (max-width: 599px) {
    padding-bottom: 150px;
  }
`;

export const CreateAccountStepOneContainer = styled.div.attrs({
  id: 'CreateAccountStepOneContainer',
})`
  margin: 0 auto;
  padding: 15px;
  border: 2px solid ${(props) => props.theme.gray};
  max-width: 450px;
`;

export const CreateAccountStepTwoContainer = styled.div.attrs({
  id: 'CreateAccountStepTwoContainer',
})`
  margin: 0 auto;
  padding: 15px;
  border: 2px solid ${(props) => props.theme.gray};
  max-width: 450px;
`;

export const CreateAccountHeader = styled.label.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-weight: 700;
`;

export const CreateAccountMessageLabel = styled.div.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 10px;
`;

export const PasswordConstraintMessageLabel = styled.div.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-size: 12px;
  margin-top: 5px;
`;

export const RegistrationFieldHeader = styled.label.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-weight: 500;
  margin-top: 15px;
`;

export const RegistrationFieldTextInputField = styled.input.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
  type: 'text',
}))`
  width: 98%;
  height: 28px;
  margin-top: 5px;
`;

export const RegistrationFieldNumbersInputField = styled.input.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
  type: 'number',
}))`
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  width: 98%;
  height: 28px;
  margin-top: 5px;
`;

export const RegistrationFieldPasswordInputField = styled.input.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
  type: 'password',
}))`
  width: -webkit-fill-available;
  height: 28px;
  margin-top: 5px;
`;

export const RegistrationFieldContainer = styled.div.attrs({
  className: 'RegistrationFieldContainer',
})`
  display: grid;
  grid-template-columns: 100%;
`;

export const TermsAndConditionsContainer = styled.div.attrs({})`
  margin-top: 15px;
`;

export const CreateAccountButtonContainer = styled.div.attrs({
  id: 'CreateAccountButtonContainer',
})`
  text-align: center;
  margin-top: 8px;
`;

export const CreateAccountButton = styled.button.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  text-align: center;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  padding: 8px 100px;
  width: 100%;
  margin-top: 20px;
  :disabled {
    background-color: gray;
  }
`;

export const UncheckedCircle = styled(RadioButtonUncheckedIcon)`
  position: relative;
  top: 8px;
  color: ${(props) => props.theme.primaryColor};
`;
export const CheckedCircle = styled(CheckCircleOutlineIcon)`
  position: relative;
  top: 8px;
  color: ${(props) => props.theme.primaryColor};
`;

export const RequiredField = styled.span.attrs({})`
  color: red;
`;

export const TextLink = styled.a.attrs({})`
  text-decoration: underline;
  cursor: pointer;
  color: blue;
  :hover {
    color: #2b102e;
  }
`;

export const LogoLink = styled(Link)`
  grid-area: logo;
`;

export const LoginButton = styled.button.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  text-align: left;
  background-color: ${(props) => props.theme.primaryColor};
`;

export const CreateAccountLink = styled.a.attrs({})`
  cursor: pointer;
  color: blue;
  :hover {
    color: #2b102e;
  }
`;

export const AccountCreationErrorMessage = styled.div`
  color: red;
  @media (max-width: 599px) {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    margin-left: 1%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 1%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  @media (min-width: 1280px) {
    margin-left: 1%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
export const HelpText = styled.span.attrs({})`
  font-size: 12px;
  opacity: 0.6;
`;

export const UpdateButton = styled.button.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  text-align: center;
  padding: 10px 15px;
  border: 0;
  margin-top: 10px;
  margin-right: 10px;
  color: white;
  background-color: ${(props) => props.theme.primaryColor};
  :disabled {
    background-color: gray;
  }
`;
