import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CombinePolicesDashboardContainer = styled.div.attrs({
  id: 'combinePoliciesDashboardContainer',
})`
  border: 1px solid #dddddd;
  display: inline-block;
  background-color: #f7f7f7;
  padding: 15px;
  min-width: 275px;

  @media (max-width: 355px) {
    min-width: 82%;
  }
  @media (max-width: 599px) {
    margin-top: 16px;
    margin-bottom: 30px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 16px;
    margin-bottom: 30px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 30px;
    margin-left: 30px;
    margin-bottom: 30px;
  }
  @media (min-width: 1280px) {
    margin-top: 3%;
    margin-left: 5%;
    margin-bottom: 30px;
  }
`;

export const CombinePolicesTitle = styled.div.attrs({
  id: 'combinePoliciesTitle',
})`
  font-size: 16px;
`;

export const CombinePolicesInteractiveContainer = styled.div.attrs({
  id: 'combinePolicesInteractiveContainer',
})`
  margin-top: 2%;
  max-width: 300px;
  position: relative;
  bottom: 3px;
`;

export const CombinePolicesPromptLink = styled(Link).attrs({
  id: 'combinePoliciesPromptLink',
  to: '#',
})`
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: blue;
  padding-left: 0;
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  :hover {
    color: #00009c;
  }
`;

export const CombinePolicesText = styled.div.attrs({
  id: 'combinePoliciesText',
})`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
`;

export const PolicyNumberInput = styled.div.attrs({
  id: 'combinePoliciesPolicyNumberInput',
})`
  max-width: 200px;
  input {
    display: block;
    padding: 5px;
    margin-top: 5px;
    width: 100%;
    font-weight: normal;
  }
`;

export const CancelButtonColumn = styled.div.attrs({
  id: 'combinePoliciesCancelButtonColumn',
})`
  margin-top: 24px;
  display: inline-block;
  min-width: 50%;
  text-align: left;
  padding-left: 0;
`;

export const SubmitButtonColumn = styled.div.attrs({
  id: 'combinePoliciesSubmitButtonColumn',
})`
  display: inline-block;
  min-width: 50%;
  text-align: right;
  padding-right: 0;
`;

const Button = styled(Link).attrs({
  type: 'button',
  to: '#',
})`
  border: none;
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
  text-align: center;
  text-decoration: none;
`;

export const CancelButton = styled(Button).attrs({
  id: 'combinePoliciesCancelButton',
})`
  background-color: transparent;
  text-align: left;
  padding: 7px 0;
  color: blue;
  -webkit-appearance: initial;
`;

export const SubmitButton = styled(Button).attrs({
  id: 'combinePoliciesSubmitButton',
  tabIndex: 0,
})`
  background-color: ${(props) => props.theme.primaryColor};
  box-shadow: 0 1px 2px 1px rgb(40 51 63 / 24%);
  color: white;
  padding: 9px 30px;
  border-radius: 4px;
  -webkit-appearance: initial;
  display: block;
  :hover {
    background-color: ${(props) =>
      props.theme.primaryHoverColor
        ? props.theme.primaryHoverColor
        : props.theme.primaryColor
        ? props.theme.primaryColor
        : 'gray'};
  }
  :active {
    background-color: ${(props) => props.theme.primaryActiveColor};
  }
`;
