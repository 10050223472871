import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  AnonymousReportClaimsStepFourContainer,
  AnonymousReportClaimsStepFourField,
  AnonymousReportClaimsStepFourSubHeader,
  AnonymousReportClaimsStepFourSubHeaderDescription,
  BestWayToContactContainer,
  BestWayToContactSelect,
  ClaimEventNameLabel,
  EmailRow,
  ErrorMessage,
  LocationFieldsContainer,
  MobilePhoneRow,
  PhoneSelect,
  PhoneSelectorContainer,
  ReportClaimCityStateContainer,
  ReportClaimsAddressTitleHeader,
  ReportClaimsCountryTitleHeader,
  ReportClaimsFieldHeader,
  ReportClaimsTitleHeader,
  ReportClaimThreeFormContainerFullRow,
  ReportClaimZipCountyContainer,
  RequiredField,
  StateSelectComponent,
  ZipErrorMessage,
} from './styles';
import { ReportClaimsAddressTextInputField } from '../step3/styles';
import { ReportClaimsTextInputField } from '../../report-claim/styles';
import {
  getAddress,
  getBestWayToContact,
  getBestWayToContactSelection,
  getMailingCity,
  getMailingState,
  getMailingZip,
  getPhoneNumber,
  getPhoneType,
} from './selectors';
import Select from 'react-select';
import { US_STATES } from '../../../common/constants';
import {
  formatPhoneNumber,
  isEmail,
  isPhone,
} from '../../../../utils/validators/generalValidators';
import {
  ReportClaimButton,
  ReportClaimButtonContainer,
  ReportClaimNextButton,
} from '../step1/styles';
import {
  setBestWayToContact,
  setCityText,
  setCountryText,
  setCountyText,
  setEmail,
  setErrors,
  setLocation1Text,
  setLocation2Text,
  setLocation3Text,
  setLocation4Text,
  setPhoneTemp,
  setPhoneType,
  setPostalCode,
  setSelectedState,
  setStateText,
  setUpdatedClaimEventTemplate,
} from './anonymousReportClaimStepFourReducer';
import { getDynamicQuestions } from './anonymousReportClaimStepFourApi';
import { find } from 'lodash';
import TooltipInfo from '../../../common/tooltip';
import { getText } from '../../../../utils/i18n';
import { ReportClaimCancelButton } from '../styles';
import { useNavigate } from 'react-router';
import Addresses from '../../../common/addresses';
import { getStateByCode } from '../../../common/addresses/addressUtils';

export const Step4 = (props) => {
  const {
    claimEvent,
    countyText,
    countryText,
    email,
    errors,
    isLoading,
    location3Text,
    location4Text,
    phoneNumber,
    phoneType,
    postalValue,
    stateValue,
    stateText,
    handleNext,
    handleStep,
    registrationToken,
    claimEventDynamicQuestions,
    state,
    i18n,
  } = props;
  const bestWayToContact = props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact
    .personInfo
    ? props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.personInfo
        .bestWayToContact
    : '';
  const BEST_WAY_TO_CONTACT = [
    { label: 'Phone', value: 'Phone' },
    { label: 'Email', value: 'Email' },
  ];
  const phoneTypeSelector = [
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Home', label: 'Home' },
    { value: 'Business', label: 'Business' },
    { value: 'Other', label: 'Other' },
  ];
  const postalErrorMessage =
    postalValue && postalValue.length !== 0 && postalValue.length < 5 ? 'Invalid Entry' : '';
  const [isNonUSAddressState, setIsNonUSAddressState] = useState(false);
  const [phoneField, setPhoneFormField] = React.useState(phoneNumber);
  const [phoneTypeField, setPhoneTypeField] = React.useState(
    find(phoneTypeSelector, (state) => state.value === phoneType),
  );

  const [isPhoneRequired, setIsPhoneRequired] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = React.useState(false);
  const [isBestWayToContactSelected, setBestWayToContactInput] = React.useState(bestWayToContact);

  const [localLocation1Text, setLocalLocation1Text] = useState('');
  const [localLocation2Text, setLocalLocation2Text] = useState('');
  const [localCityText, setLocalCityText] = useState('');
  const [localPostalValue, setLocalPostalValue] = useState('');
  const [localStateValue, setLocalStateValue] = useState(
    find(
      US_STATES,
      (usState) =>
        usState.value ===
        getMailingState(
          state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses,
        ),
    ),
  );
  const isRequiredFieldsCompleted = () => {
    if (!isBestWayToContactSelected || errors?.length > 0) {
      return false;
    }

    let completedFlag = false;
    // phone validation
    completedFlag =
      completedFlag || (bestWayToContact === 'Phone' && phoneField && isPhone(phoneField));

    //email validation
    completedFlag = completedFlag || (bestWayToContact === 'Email' && email && isEmail(email));
    return completedFlag;
  };

  useEffect(() => {
    // validate claim event and get dynamic questions
    props.dispatch(getDynamicQuestions(props, registrationToken));
    props.dispatch(setErrors(null));
  }, []);

  React.useEffect(() => {
    const originalClaimEvent = {
      ...props.state.anonymousReportClaimStepTwoSlice.selectedClaimEventTemplate,
    };
    if (
      claimEventDynamicQuestions &&
      claimEventDynamicQuestions.eventSections &&
      claimEventDynamicQuestions.eventSections.length > 0 &&
      props.state.anonymousReportClaimStepTwoSlice.selectedClaimEventTemplate
    ) {
      // reassign expanded values since originals are read-only
      originalClaimEvent.eventSections = [...originalClaimEvent.eventSections];

      // using hash map to avoid using brute force for better performance
      const sectionsMap = new Map();
      // loop through original claim eventSections and create a map {sectionCd:index}
      for (let i = 0; i < originalClaimEvent.eventSections.length; i++) {
        const sectionCd = originalClaimEvent.eventSections[i].sectionCd;
        if (!sectionsMap.has(sectionCd)) {
          sectionsMap.set(sectionCd, i);
        }
      }

      // loop through validate claim eventSections
      for (let j = 0; j < claimEventDynamicQuestions.eventSections.length; j++) {
        const section = claimEventDynamicQuestions.eventSections[j];
        const sectionCd = section.sectionCd;
        // check for sectionCd in map
        if (sectionsMap.has(sectionCd)) {
          // if present, push questions
          const index = sectionsMap.get(sectionCd);

          // reassign expanded values since originals are read-only
          originalClaimEvent.eventSections[index] = {
            ...originalClaimEvent.eventSections[index],
          };
          originalClaimEvent.eventSections[index].questions = {
            ...originalClaimEvent.eventSections[index].questions,
          };
          originalClaimEvent.eventSections[index].questions.question = [
            ...originalClaimEvent.eventSections[index].questions.question,
          ];

          originalClaimEvent.eventSections[index].questions.question.push(
            ...section.questions.question,
          );
        } else {
          // else push section and update map {sectionCd:index}
          originalClaimEvent.eventSections.push(section);
          sectionsMap.set(sectionCd, originalClaimEvent.eventSections.length - 1);
        }
      }
    }
    props.dispatch(setUpdatedClaimEventTemplate(originalClaimEvent));
  }, [claimEventDynamicQuestions]);
  const navigate = useNavigate();

  const handlePlaceSelect = (address) => {
    const { addr1, addr2, city, stateProvCd, postalCode } = address;

    if (
      !(addr1.length === 0 || city.length === 0 || stateProvCd.length === 0 || postalCode === 0)
    ) {
      // update current state
      setLocalLocation1Text(addr1);
      setLocalLocation2Text(addr2);
      setLocalCityText(city);
      setLocalPostalValue(postalCode);
      setLocalStateValue(getStateByCode(stateProvCd, US_STATES));

      // update reducer
      props.dispatch(setLocation1Text(addr1));
      props.dispatch(setLocation2Text(addr2));
      props.dispatch(setCityText(city));
      props.dispatch(setPostalCode(postalCode));
      props.dispatch(setSelectedState(getStateByCode(stateProvCd, US_STATES)));
    }
  };
  const updatePhones = () => {
    props.dispatch(setPhoneTemp(phoneField));
    props.dispatch(setPhoneType(phoneTypeField));
    if (bestWayToContact === '') {
      props.dispatch(setBestWayToContact(''));
    } else {
      props.dispatch(setBestWayToContact(bestWayToContact));
    }
  };
  return (
    <>
      <Helmet>
        <title>Report Claim - Contact Information</title>
      </Helmet>
      {!isLoading && (
        <AnonymousReportClaimsStepFourContainer>
          {errors && <ErrorMessage>{errors}</ErrorMessage>}
          <ClaimEventNameLabel>{claimEvent && claimEvent.name}</ClaimEventNameLabel>
          <AnonymousReportClaimsStepFourSubHeader>
            Add your contact information.
          </AnonymousReportClaimsStepFourSubHeader>
          <AnonymousReportClaimsStepFourSubHeaderDescription>
            Please verify your contact information.
          </AnonymousReportClaimsStepFourSubHeaderDescription>
          <BestWayToContactContainer>
            <ReportClaimsFieldHeader htmlFor="bestwaytocontact">
              Best Way To Contact <RequiredField>*</RequiredField>
              <TooltipInfo
                title={getText(i18n, 'reportClaimAnonymous.stepFour.bestWayToContactTooltip')}
              />
            </ReportClaimsFieldHeader>
            <BestWayToContactSelect
              id="bestWayToContact"
              name="bestwaytocontact"
              options={BEST_WAY_TO_CONTACT}
              defaultValue={getBestWayToContactSelection(BEST_WAY_TO_CONTACT, bestWayToContact)}
              onChange={(option) => {
                props.dispatch(setBestWayToContact(option.value));
                if (option.value === 'Phone') {
                  setIsEmailRequired(false);
                  setIsPhoneRequired(true);
                  setBestWayToContactInput(true);
                }
                if (option.value === 'Email') {
                  setIsEmailRequired(true);
                  setIsPhoneRequired(false);
                  setBestWayToContactInput(true);
                }
              }}
            />
          </BestWayToContactContainer>
          <MobilePhoneRow>
            <ReportClaimsFieldHeader htmlFor="mobilePhone">
              Phone Number {isPhoneRequired ? <RequiredField>*</RequiredField> : null}
              <TooltipInfo
                title={getText(i18n, 'reportClaimAnonymous.stepFour.mobilePhoneTooltip')}
              />
            </ReportClaimsFieldHeader>
            <ReportClaimsTextInputField
              id="phone"
              name="phone"
              value={phoneField}
              aria-label="Phone Number"
              onChange={(e) => {
                const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                setPhoneFormField(formattedPhoneNumber);
              }}
            />
            <PhoneSelectorContainer>
              <PhoneSelect
                id="phoneTypeSelector"
                options={phoneTypeSelector}
                value={phoneTypeField}
                aria-label="Phone Type"
                onChange={(phone) => {
                  setPhoneTypeField(phone);
                }}
              />
            </PhoneSelectorContainer>
          </MobilePhoneRow>
          <EmailRow>
            <ReportClaimsFieldHeader htmlFor="email">
              Email {isEmailRequired ? <RequiredField>*</RequiredField> : null}
              <TooltipInfo title={getText(i18n, 'reportClaimAnonymous.stepFour.emailTooltip')} />
            </ReportClaimsFieldHeader>
            <ReportClaimsTextInputField
              name="email"
              defaultValue={email}
              aria-label="Email"
              onChange={(e) => {
                props.dispatch(setEmail(e.target.value));
              }}
            />
          </EmailRow>
          <AnonymousReportClaimsStepFourField>
            <ReportClaimsTitleHeader htmlFor="nonUSAddressCheckBox">
              Non-U.S. Address{' '}
              <input
                aria-label="Non US Address"
                type="checkbox"
                name="nonUSAddressCheckBox"
                checked={isNonUSAddressState}
                onChange={() => setIsNonUSAddressState(!isNonUSAddressState)}
              />
              <TooltipInfo
                title={getText(i18n, 'reportClaimAnonymous.stepFour.nonUSAddressTooltip')}
              />
            </ReportClaimsTitleHeader>
          </AnonymousReportClaimsStepFourField>
          <LocationFieldsContainer>
            {isNonUSAddressState && (
              <ReportClaimThreeFormContainerFullRow>
                <ReportClaimsCountryTitleHeader htmlFor="country">
                  Country
                  <TooltipInfo
                    title={getText(i18n, 'reportClaimAnonymous.stepFour.countryTooltip')}
                  />
                </ReportClaimsCountryTitleHeader>
                <ReportClaimsTextInputField
                  name="country"
                  aria-label="Country"
                  defaultValue={countryText}
                  onChange={(e) => {
                    props.dispatch(setCountryText(e.target.value));
                  }}
                />
              </ReportClaimThreeFormContainerFullRow>
            )}
            {!isNonUSAddressState ? (
              <ReportClaimThreeFormContainerFullRow>
                <Addresses
                  id="reportClaimContactLocationAddress"
                  size="large"
                  handlePlaceSelect={handlePlaceSelect}
                  isAnonymousUser={true}
                >
                  <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.addressTooltip')} />
                </Addresses>
              </ReportClaimThreeFormContainerFullRow>
            ) : (
              <>
                <ReportClaimThreeFormContainerFullRow>
                  <ReportClaimsAddressTitleHeader htmlFor="locationAddress1">
                    Address
                    <TooltipInfo
                      title={getText(i18n, 'reportClaimAnonymous.stepFour.addressTooltip')}
                    />
                  </ReportClaimsAddressTitleHeader>
                  <ReportClaimsAddressTextInputField
                    id="locationAddress1"
                    name="locationAddress1"
                    aria-label="Location Address Line 1"
                    value={localLocation1Text}
                    onChange={(e) => {
                      setLocalLocation1Text(e.target.value);
                      props.dispatch(setLocation1Text(e.target.value));
                    }}
                  />
                  <ReportClaimsAddressTextInputField
                    id="locationAddress2"
                    name="locationAddress2"
                    value={localLocation2Text}
                    aria-label="Location Address Line 2"
                    onChange={(e) => {
                      setLocalLocation2Text(e.target.value);
                      props.dispatch(setLocation2Text(e.target.value));
                    }}
                  />
                  {isNonUSAddressState && (
                    <ReportClaimThreeFormContainerFullRow>
                      <ReportClaimsAddressTextInputField
                        id="locationAddress3"
                        name="locationAddress3"
                        value={location3Text}
                        onChange={(e) => {
                          setLocation3Text(e.target.value);
                          props.dispatch(setLocation3Text(e.target.value));
                        }}
                      />
                      <ReportClaimsAddressTextInputField
                        id="locationAddress4"
                        name="locationAddress4"
                        value={location4Text}
                        onChange={(e) => {
                          setLocation4Text(e.target.value);
                          props.dispatch(setLocation4Text(e.target.value));
                        }}
                      />
                    </ReportClaimThreeFormContainerFullRow>
                  )}
                </ReportClaimThreeFormContainerFullRow>
                <ReportClaimCityStateContainer>
                  <AnonymousReportClaimsStepFourField>
                    <ReportClaimsFieldHeader htmlFor="city">
                      City
                      <TooltipInfo
                        title={getText(i18n, 'reportClaimAnonymous.stepFour.cityTooltip')}
                      />
                    </ReportClaimsFieldHeader>
                    <ReportClaimsTextInputField
                      input="city"
                      name="city"
                      value={localCityText}
                      aria-label="City"
                      onChange={(e) => {
                        setLocalCityText(e.target.value);
                        props.dispatch(setCityText(e.target.value));
                      }}
                    />
                  </AnonymousReportClaimsStepFourField>
                  <StateSelectComponent>
                    <AnonymousReportClaimsStepFourField>
                      <ReportClaimsFieldHeader htmlFor="state">
                        {isNonUSAddressState ? 'State/Province' : 'State'}{' '}
                        <TooltipInfo
                          title={getText(i18n, 'reportClaimAnonymous.stepFour.stateTooltip')}
                        />
                      </ReportClaimsFieldHeader>
                      {!isNonUSAddressState ? (
                        <Select
                          name="stateList"
                          aria-label="State"
                          id="stateList"
                          defaultValue={find(US_STATES, (state) => state.value === stateValue)}
                          options={US_STATES}
                          value={localStateValue}
                          onChange={(option) => {
                            setLocalStateValue(option);
                            // TODO call dispatch on Next
                            props.dispatch(setSelectedState(option));
                          }}
                        />
                      ) : (
                        <AnonymousReportClaimsStepFourField>
                          <ReportClaimsTextInputField
                            name="stateOrProvince"
                            aria-label="State or Province"
                            defaultValue={stateText}
                            onChange={(e) => {
                              props.dispatch(setStateText(e.target.value));
                            }}
                          />
                        </AnonymousReportClaimsStepFourField>
                      )}
                    </AnonymousReportClaimsStepFourField>
                  </StateSelectComponent>
                </ReportClaimCityStateContainer>
                <ReportClaimZipCountyContainer>
                  <AnonymousReportClaimsStepFourField>
                    <ReportClaimsFieldHeader htmlFor="zip">
                      {isNonUSAddressState ? 'Postal Code' : 'ZIP'}
                      <TooltipInfo
                        title={getText(i18n, 'reportClaimAnonymous.stepFour.zipTooltip')}
                      />
                    </ReportClaimsFieldHeader>
                    <ReportClaimsTextInputField
                      id="zip"
                      name="zip"
                      aria-label={isNonUSAddressState ? 'Postal Code' : 'Zip'}
                      value={localPostalValue}
                      onChange={(e) => {
                        setLocalPostalValue(e.target.value);
                        props.dispatch(setPostalCode(e.target.value));
                      }}
                    />
                    <ZipErrorMessage>{postalErrorMessage && postalErrorMessage}</ZipErrorMessage>
                  </AnonymousReportClaimsStepFourField>
                  {!isNonUSAddressState && (
                    <div>
                      <ReportClaimsFieldHeader htmlFor="county">
                        County{' '}
                        <TooltipInfo
                          title={getText(i18n, 'reportClaimAnonymous.stepFour.countyTooltip')}
                        />
                      </ReportClaimsFieldHeader>
                      <ReportClaimsTextInputField
                        name="county"
                        defaultValue={countyText}
                        aria-label="County"
                        onChange={(e) => {
                          props.dispatch(setCountyText(e.target.value));
                        }}
                      />
                    </div>
                  )}
                </ReportClaimZipCountyContainer>
              </>
            )}
          </LocationFieldsContainer>
          <ReportClaimButtonContainer>
            <ReportClaimCancelButton onClick={() => navigate('/')}>Cancel</ReportClaimCancelButton>
            <ReportClaimNextButton
              id="Next"
              disabled={!isRequiredFieldsCompleted()}
              onClick={() => {
                updatePhones();
                handleNext();
              }}
            >
              Next
            </ReportClaimNextButton>
            <ReportClaimButton
              id="Previous"
              onClick={() => {
                handleStep();
              }}
            >
              Back
            </ReportClaimButton>
          </ReportClaimButtonContainer>
        </AnonymousReportClaimsStepFourContainer>
      )}
    </>
  );
};
Step4.propTypes = {
  state: PropTypes.any,
  dispatch: PropTypes.any,
  stepTwoZipValue: PropTypes.any,
  isLoading: PropTypes.bool,
  errors: PropTypes.any,
  customerId: PropTypes.any,
  claimEvent: PropTypes.any,
  drivers: PropTypes.any,
  email: PropTypes.string,
  locations: PropTypes.any,
  manualDriverName: PropTypes.any,
  manualLicenseNumber: PropTypes.any,
  selectedClaimEventTemplate: PropTypes.any,
  claimEventContacts: PropTypes.any,
  descriptionText: PropTypes.any,
  isNonUSAddress: PropTypes.bool,
  location1Text: PropTypes.any,
  location2Text: PropTypes.any,
  location3Text: PropTypes.any,
  location4Text: PropTypes.any,
  postalValue: PropTypes.string,
  phoneInfo: PropTypes.any,
  phoneNumber: PropTypes.string,
  phoneType: PropTypes.string,
  stateValue: PropTypes.any,
  cityText: PropTypes.any,
  stateText: PropTypes.any,
  countyText: PropTypes.any,
  selectedState: PropTypes.any,
  countryText: PropTypes.any,
  dateValue: PropTypes.any,
  timeValue: PropTypes.any,
  isReadyForNextStep: PropTypes.any,
  selectedLocation: PropTypes.any,
  selectedDriver: PropTypes.any,
  selectedVehicle: PropTypes.any,
  vehicles: PropTypes.any,
  handleNext: PropTypes.func,
  handleStep: PropTypes.func,
  registrationToken: PropTypes.string,
  claimEventDynamicQuestions: PropTypes.any,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  bestWayToContact: getBestWayToContact(state),
  cityText: getMailingCity(
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses,
  ),
  claimEvent: state.anonymousReportClaimStepThreeSlice.claimEvent,
  claimEventContacts: state.anonymousReportClaimStepFourSlice.claimEventContacts,
  countyText:
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses[0].county,
  countryText:
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses[0].regionCd,
  dateValue: state.anonymousReportClaimStepThreeSlice.dateValue,
  descriptionText: state,
  drivers: state,
  email: state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.emailInfo.emailAddr,
  errors: state.anonymousReportClaimStepFourSlice.errors,
  isLoading: state.anonymousReportClaimStepFourSlice.isLoading,
  isNonUSAddress: state.anonymousReportClaimStepThreeSlice.isNonUSAddress,
  isReadyForNextStep: state,
  locations: state,
  location1Text: getAddress(
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses,
  ),
  location2Text:
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses[0].addr2,
  location3Text:
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses[0].addr3,
  location4Text:
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses[0].addr4,
  manualDriverName: state,
  manualLicenseNumber: state,
  phoneNumber: getPhoneNumber(
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.phoneInfo,
    state.anonymousReportClaimStepFourSlice.phoneTemp,
  ),
  phoneType: getPhoneType(
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.phoneInfo,
    state.anonymousReportClaimStepFourSlice.phoneTemp,
  ),
  phoneInfo: state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.phoneInfo,
  postalValue: getMailingZip(
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses,
  ),
  selectedClaimEventTemplate: state,
  selectedDriver: state.anonymousReportClaimStepThreeSlice.selectedDriver,
  selectedLocation: state.anonymousReportClaimStepThreeSlice.selectedLocation,
  selectedState: state,
  selectedVehicle: state.anonymousReportClaimStepThreeSlice.selectedVehicle,
  state,
  stateText: getMailingState(
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses,
  ),
  stateValue: getMailingState(
    state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses,
  ),
  timeValue: state.anonymousReportClaimStepThreeSlice.timeValue,
  vehicles: state,
  stepTwoZipValue: state.anonymousReportClaimStepThreeSlice.zipValue,
  customerId: state.loginSlice.customerId,
  registrationToken: state.loginSlice.registrationToken,
  claimEventDynamicQuestions: state.anonymousReportClaimStepFourSlice.claimEventDynamicQuestions,
  i18n: state.i18nSlice.i18n,
});
export default connect(mapStateToProps)(Step4);
