import styled from 'styled-components';

export const RemoveVehicleContainer = styled.div.attrs({
  id: 'RemoveVehicleContainer',
})`
  margin-top: 20px;
  width: 100%;
`;
export const RemoveVehicleField = styled.div.attrs({})`
  width: 40%;
  @media (max-width: 599px) {
    width: 100%;
  }

  @media (min-width: 600px) and (max-width: 959px) {
    width: 70%;
  }
`;

export const RemoveVehicleFieldHeader = styled.h4.attrs({
  id: 'RemoveVehicleFieldHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const RemoveVehicleButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
  margin-top: 10%;
  display: flex;
  width: 100%;
  @media (max-width: 600px) {
    text-align: center;
  }
`;
export const RemoveVehicleButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;
export const RemoveVehicleComponent = styled.div.attrs({})`
  margin-bottom: 12px;
`;
export const RemoveVehicleRadioButton = styled.input.attrs({
  type: 'radio',
})`
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  float: left;
`;
export const RadioLabel = styled.div.attrs({})`
  display: block;
  margin-left: 22px;
`;
export const RemoveVehicleTextAreaField = styled.textarea.attrs({
  id: 'removeVehicleTextAreaField',
})`
  height: 100px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const LinkStyleButton = styled.button.attrs({
  id: 'Cancel',
})`
  background-color: initial;
  color: ${(props) => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 0;

  :hover {
    background-color: initial;
    color: ${(props) => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${(props) => props.theme.primaryActiveColor};
  }
`;
