import {
  setClaimEventContacts,
  setClaimEventContactsErrors,
  setClaimEventContactsLoading,
  setClaimEventDynamicQuestions,
  setClaimEventDynamicQuestionsErrors,
} from './reportClaimStepThreeReducer';
import { API } from '../../../../utils/api';

export const getClaimEventContacts = (
  templateId,
  customerId,
  policyNumber,
  riskIdRef,
  lossDt,
  lossTm,
) => {
  return dispatch => {
    return API.get(
      `/claimEvents/${templateId}` +
        `?customerRef=${customerId}` +
        `&policyNumber=${policyNumber}` +
        `&riskIdRef=${riskIdRef}` +
        `&lossDt=${lossDt}` +
        `&lossTm=${lossTm}` +
        '&includeContacts=true',
    )
      .then(response => {
        dispatch(setClaimEventContacts(response.data));
      })
      .catch(err => {
        console.log(err.message);
        dispatch(
          setClaimEventContactsErrors(
            'Your request cannot be processed at this time.',
          ),
        );
      })
      .finally(() => {
        dispatch(setClaimEventContactsLoading(false));
      });
  };
};

export const getDynamicQuestions = props => {
  return dispatch => {
    const requestBody = {
      id: props.state.reportClaimSlice.claimEvent.id,
      sourceCd: 'ServicePortal_v4.0.0',
      customerNumber: props.claimEvent.customerNumber,
      eventCd: props.claimEvent.eventCd,
      accidentInd: false, // always false in SP 3 at this stage
      lossDt: props.dateValue,
      lossTm: props.timeValue,
      addr: props.state.reportClaimStepTwoSlice.addr,
      detailDescription: props.state.reportClaimStepTwoSlice.descriptionText,
      policyNumber:
        props.selectedLocation && props.selectedLocation.policyNumber
          ? props.selectedLocation.policyNumber
          : props.selectedVehicle.policyNumber,
      templateIdRef: props.claimEvent.templateIdRef,
    };

    if (props.state.reportClaimSlice.selectedClaimEventTemplate.view === 'Location') {
      // if HO policy, we use location
      requestBody.riskIdRef = props.selectedLocation.id;
    } else if (props.state.reportClaimSlice.selectedClaimEventTemplate.view === 'Vehicle') {
      // if PA policy, we use driver and vehicle info
      const selectedDriver = props.selectedDriver;
      const nameInfo = selectedDriver.partyInfo.nameInfo;
      requestBody.driverIdRef = selectedDriver.value;
      requestBody.driverName =
        props.state.reportClaimStepTwoSlice.manualDriverName !== ''
          ? props.state.reportClaimStepTwoSlice.manualDriverName
          : `${nameInfo.givenName} ${nameInfo.surname}`;
      requestBody.driverLicense = selectedDriver.partyInfo.driverInfo.licenseNumber;
      requestBody.vehicleIdRef = props.selectedVehicle.id;
      requestBody.permissiveDriverRelationshipToInsuredCd =
        props.state.reportClaimStepTwoSlice.driverRelationshipSelected;
    }

    return API.post('/claimEventValidate', requestBody)
      .then(response => {
        dispatch(setClaimEventDynamicQuestions(response.data));
      })
      .catch(() => {
        dispatch(
          setClaimEventDynamicQuestionsErrors(
            'Your request cannot be processed at this time.',
          ),
        );
      });
  };
};
