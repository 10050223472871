import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find, isEmpty } from 'lodash';
import {
  AddDriverButtonsContainer,
  AddDriverField,
  AddDriverFileUploadButton,
  AddDriverHeader,
  FileName,
  FileThumbnail,
  FileThumbnailContainer,
  GarbageIcon,
  GarbageIconContainer,
  ImgThumbnail,
  LinkStyleButton,
  NextButton,
  PhotoIcon,
  PicturesContainer,
  QuestionsContainer,
  ThumbnailContainer,
} from './styles';
import AdditionQuestionField from '../add-vehicle/additional-question-field';
import moment from 'moment';
import {
  getLatestPolicyInfo,
  preSubmitPolicyChange,
  uploadAddDriverFiles,
} from '../../policyChangeAPI';
import Loader from '../../../../common/loader';
import { DISPLAY_DATE_FORMAT, ISO_DATE_FORMAT } from '../../../../common/constants';
import { createField } from '../selector';
import { getAdditionalQuestions, filterReplies, getAllQuestionReplies } from './selector';
import {
  setLoading,
  setPolicyChangePreview,
  setPolicyChangePreviewData,
} from '../../policyChangeReducer';
import { checkFileSize } from '../../../../../utils/file-utils';
import PolicyChangePreview from '../policy-change-preview';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

export const AddDriver = (props) => {
  const {
    dispatch,
    selectedPolicy,
    policyRefSelected,
    customerId,
    selectedPolicyChangeTemplate,
    uploadFileSize,
    policyChangePreview,
  } = props;

  const [uploadFiles, setUploadFiles] = useState([]);
  const [additionalQuestions] = useState(getAdditionalQuestions(selectedPolicyChangeTemplate));
  const [answers, setAnswers] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setInitialAnswers(additionalQuestions);
  }, []);

  const setAdditionalQuestionsAnswers = (index, answer) => {
    const theAnswer = {};
    theAnswer[index] = { answer };
    setAnswers((prevAnswers) => {
      return { ...prevAnswers, ...theAnswer };
    });
  };

  const setAdditionalSubQuestionsAnswers = (parentIndex, index, answer) => {
    const subQuestionAnswers = [];
    subQuestionAnswers[index] = answer;

    const theAnswer = [];
    theAnswer[parentIndex] = {
      answer: answers[parentIndex].answer,
      subQuestionAnswers,
    };
    setAnswers((prevAnswers) => {
      return { ...prevAnswers, ...theAnswer };
    });
  };

  const checkRequiredFields = () => {
    if (isEmpty(answers)) {
      return false;
    }
    let birthDt;
    let licenseDt;
    for (let i = 0; i < additionalQuestions.length; i++) {
      const question = additionalQuestions[i];
      const answer = answers[i].answer;
      if (question.name === 'Birthdate') {
        birthDt = answer;
      }
      if (question.name === 'DateLicensed') {
        licenseDt = answer;
      }
      if (question.name === 'DriverCertifyQA' && answer !== 'Yes') {
        return false;
      }

      if (question.requiredInd === 'Yes') {
        if (answer !== '') {
          continue;
        } else {
          return false;
        }
      } else {
        continue;
      }
    }
    if (moment(licenseDt).isBefore(birthDt) || moment(licenseDt).isSame(birthDt)) {
      return false;
    }
    return true;
  };

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    const questions = additionalQuestions;

    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const answer = answers[i].answer;
      let value;
      if (question.dataType === 'YesNo') {
        value = answer;
      } else if (question.dataType === 'List') {
        value = answer;
      } else if (question.dataType === 'Numeric') {
        value = answer.toString();
      } else if (question.dataType === 'TextArea') {
        value = answer;
      } else if (question.dataType === 'Date') {
        value = moment(answer).format(DISPLAY_DATE_FORMAT);
      } else if (question.dataType === 'Text') {
        value = answer;
      } else {
        value = answer;
      }
      fields.push(
        createField(
          question.text ? question.text : question.shortDesc ? question.shortDesc : 'label',
          value,
          'text',
        ),
      );

      if (
        question.questions &&
        question.questions.question &&
        question.questions.question.length > 0 &&
        answers[i].subQuestionAnswers
      ) {
        for (let j = 0; j < question.questions.question.length; j++) {
          const subQuestion = question.questions.question[j];
          const subAnswer = answers[i].subQuestionAnswers[j];
          let subValue;
          if (subQuestion.dataType === 'YesNo') {
            subValue = subAnswer;
          } else if (subQuestion.dataType === 'List') {
            subValue = subAnswer;
          } else if (subQuestion.dataType === 'Numeric') {
            subValue = subAnswer.toString();
          } else if (subQuestion.dataType === 'TextArea') {
            subValue = subAnswer;
          }
          fields.push(createField(subQuestion.text, subValue, 'text'));
        }
      }
    }

    fields.push(
      createField(
        "Pictures of driver's license",
        uploadFiles.length > 0 ? 'Included' : 'Not included',
        'text',
      ),
    );
    return fields;
  };

  const handleFileSelection = () => {
    const fileInput = document.getElementById('uploadNewDriverFile');
    let { files } = fileInput;
    files = [...files];

    const goodFiles = checkFileSize(files, uploadFileSize, enqueueSnackbar);

    [...goodFiles].forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        file.url = reader.result;
        // setUploadFiles doesn't update the state immediately unless we follow the following technique
        setUploadFiles((oldList) => [...oldList, file]);
      };
      reader.readAsDataURL(file);
    });
  };

  const useFallbackThumbnail = (file) =>
    file.type.includes('document') || file.type.includes('pdf') || file.type.includes('text');

  const setInitialAnswers = (questions) => {
    let defaultAnswers = {};
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].dataType === 'YesNo') {
        defaultAnswers[i] = { answer: 'No' };
      } else if (questions[i].dataType === 'Date') {
        defaultAnswers[i] = { answer: '' };
      } else {
        defaultAnswers[i] = { answer: '' };
      }
    }
    setAnswers(defaultAnswers);
  };

  const onNextButtonClick = async () => {
    let questionReplies = getAllQuestionReplies(additionalQuestions, answers);
    let justAdditionalQuestionReplies = filterReplies(questionReplies);
    let payload = {
      sourceCd: 'ServicePortal',
      name: selectedPolicyChangeTemplate.name,
      customerNumber: customerId,
      changeDt: find(questionReplies, (o) => {
        return o.name === 'EffectiveDate';
      }).value,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: policyRefSelected,
        policyDescription: selectedPolicy.label.substring(selectedPolicy.label.indexOf(' ') + 1),
      },
      changeRequest: [
        {
          detailDescription: find(questionReplies, (o) => {
            return o.name === 'Reason';
          }).value,
          partyInfo: [
            {
              nameInfo: {
                surname: find(questionReplies, (o) => {
                  return o.name === 'DriverLastName';
                }).value,
                givenName: find(questionReplies, (o) => {
                  return o.name === 'DriverFirstName';
                }).value,
                otherGivenName: find(questionReplies, (o) => {
                  return o.name === 'DriverMiddleName';
                }).value,
              },
              personInfo: {
                birthDt: find(questionReplies, (o) => {
                  return o.name === 'Birthdate';
                }).value,
                genderCd: find(questionReplies, (o) => {
                  return o.name === 'Gender';
                }).value,
                maritalStatusCd: find(questionReplies, (o) => {
                  return o.name === 'MaritalStatus';
                }).value,
              },
              driverInfo: {
                relationshipToInsuredCd: find(questionReplies, (o) => {
                  return o.name === 'RelationshipToInsured';
                }).value,
                licenseDt: find(questionReplies, (o) => {
                  return o.name === 'DateLicensed';
                }).value,
                licensedStateProvCd: find(questionReplies, (o) => {
                  return o.name === 'StateProvince';
                }).value,
                licenseNumber: find(questionReplies, (o) => {
                  return o.name === 'LicenseNumber';
                }).value,
              },
            },
          ],
        },
      ],

      questionReplies: [{ questionReply: justAdditionalQuestionReplies }],
    };
    const fields = createPolicyChangeCoverageSummary();

    if (uploadFiles.length > 0) {
      dispatch(setLoading(true));
      await dispatch(uploadAddDriverFiles(uploadFiles));
      dispatch(setLoading(false));
    }

    dispatch(
      setPolicyChangePreviewData({
        fields,
        payload,
        recapFields: createPolicyChangeCoverageSummary(),
      }),
    );

    //get current policy deductible
    dispatch(getLatestPolicyInfo(selectedPolicy.value));

    if (selectedPolicyChangeTemplate.showPremChgInd) {
      // check whether or not to presubmit the policy change
      // since the api call won't work if this flag is false
      await dispatch(preSubmitPolicyChange(payload));
    }
  };

  const navigate = useNavigate();

  return (
    <>
      {policyChangePreview ? (
        <PolicyChangePreview />
      ) : (
        <>
          <QuestionsContainer>
            {additionalQuestions.map((q, index) => {
              return (
                <AdditionQuestionField
                  id={q.name}
                  key={index}
                  q={q}
                  index={index}
                  setAdditionalQuestionsAnswers={setAdditionalQuestionsAnswers}
                  setAdditionalSubQuestionsAnswers={setAdditionalSubQuestionsAnswers}
                />
              );
            })}
            <AddDriverField>
              <AddDriverHeader>Picture of Driver&#39;s License</AddDriverHeader>

              <label>
                <AddDriverFileUploadButton>
                  <PhotoIcon /> Select File(s)
                  <input
                    type="file"
                    id="uploadNewDriverFile"
                    multiple
                    accept="image/*,video/*,.pdf,.txt,.docx"
                    style={{
                      display: 'none',
                      width: '115px',
                    }}
                    onChange={() => {
                      handleFileSelection();
                    }}
                  />
                </AddDriverFileUploadButton>
              </label>
            </AddDriverField>
            {uploadFiles.length > 0 && (
              <PicturesContainer>
                {uploadFiles.map((file, index) => (
                  <ThumbnailContainer key={index}>
                    {useFallbackThumbnail(file) ? (
                      <FileThumbnailContainer>
                        <FileThumbnail />
                        <FileName>{file.name}</FileName>
                      </FileThumbnailContainer>
                    ) : (
                      <ImgThumbnail src={file.url} />
                    )}
                    <GarbageIconContainer>
                      <GarbageIcon
                        onClick={() => {
                          uploadFiles.splice(index, 1);
                          setUploadFiles((uploadFiles) => [...uploadFiles]);
                        }}
                      />
                    </GarbageIconContainer>
                  </ThumbnailContainer>
                ))}
              </PicturesContainer>
            )}
          </QuestionsContainer>
          <AddDriverButtonsContainer>
            <LinkStyleButton
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Cancel
            </LinkStyleButton>
            <NextButton
              disabled={!checkRequiredFields()}
              onClick={() => {
                onNextButtonClick();
              }}
            >
              Next
            </NextButton>
          </AddDriverButtonsContainer>
        </>
      )}
    </>
  );
};

AddDriver.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicy: PropTypes.object,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  uploadFileSize: PropTypes.any,
  policyRefSelected: PropTypes.string,
  attachmentDescription: PropTypes.string,
  attachmentTemplateId: PropTypes.string,
  policyChangePreview: PropTypes.object,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  customerId: state.customerSlice.customer.systemId,
  uploadFileSize: state.configurationSlice.features.features.UploadFileSize,
  policyRefSelected: state.policyChangeSlice.selectedPolicy.policyRef,
  policyChangePreview: state.policyChangeSlice.policyChangePreview,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(AddDriver);
