import React, { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { bankAccountTypes } from '../../make-payment/step-two-amount/constants';
import {
  FieldErrorMessage,
  RequiredField,
  StyledAccountNumber,
  StyledACHContainer,
  StyledACHInputContainer,
  StyledACHTitle,
  StyledAddACH,
  StyledLabel,
  StyledNameOnAccount,
  StyledRoutingNumber,
} from '../../make-payment/step-two-amount/styles';
import { verifyRoutingNumber } from './makePaymentStepThreeApi';
import { setBankInformation, setRoutingNumberError } from '../makePaymentAnonymousReducer';

export const ACHPayContainer = (props) => {
  const {
    achType,
    blockAchNumberOps,
    dispatch,
    firstName,
    lastName,
    routingNumberError,
    setACHTopLevel,
    bankName,
    isAnonymousPay,
  } = props;
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [accountName, setAccountName] = useState(`${firstName} ${lastName}`);
  const [bankAccountTypeSelected, setBankAccountSelected] = useState(bankAccountTypes[0]);
  const onBankAccountChange = (selectedBankAccountType) => {
    setBankAccountSelected(selectedBankAccountType);
  };
  const checkDisabled = () => {
    return (
      routingNumberError.length !== 0 ||
      !accountNumber ||
      !accountName ||
      accountNumber !== confirmAccountNumber
    );
  };

  React.useEffect(() => {
    dispatch(setRoutingNumberError(''));
    dispatch(setBankInformation(null));
  }, []);

  const handleCutCopyPaste = (e) => {
    return blockAchNumberOps ? e.preventDefault() : true;
  };

  const handleRoutingNumberTabOut = () => {
    dispatch(setRoutingNumberError(''));
    if (routingNumber.length !== 9) {
      dispatch(setRoutingNumberError('Routing number must contain 9 digits.'));
    } else {
      dispatch(verifyRoutingNumber(routingNumber, isAnonymousPay));
    }
  };

  const submitACHToPayWith = () => {
    let fundingInstrument = {
      achName: accountName,
      achBankAccountTypeCd:
        bankAccountTypeSelected.value === 'CCD' ? 'Checking' : bankAccountTypeSelected.value,
      achBankAccountNumber: accountNumber,
      achBankName: bankName !== '' ? bankName : null,
      achRoutingNumber: routingNumber,
      achStandardEntryClassCd:
        bankAccountTypeSelected.value === 'Checking'
          ? achType
            ? achType
            : 'PPD'
          : bankAccountTypeSelected.value === 'Savings'
          ? achType
            ? achType
            : 'PPD'
          : 'CCD', //ppd or ccd
      isTemporary: true,
      sourceType: 'ACH',
    };
    setACHTopLevel(fundingInstrument);
  };

  return (
    <StyledACHContainer>
      <StyledACHTitle>Enter Bank Account Details</StyledACHTitle>
      <StyledACHInputContainer>
        <StyledLabel id="routingNumberLabel" htmlFor="RoutingNumber">
          Routing Number
        </StyledLabel>
        <RequiredField> *</RequiredField>
        <StyledRoutingNumber
          id="RoutingNumber"
          mask="999999999"
          maskChar=""
          onCut={handleCutCopyPaste}
          onCopy={handleCutCopyPaste}
          onPaste={handleCutCopyPaste}
          onChange={(e) => setRoutingNumber(e.target.value)}
          onBlur={handleRoutingNumberTabOut}
        />
        <FieldErrorMessage>{routingNumberError}</FieldErrorMessage>
      </StyledACHInputContainer>
      <StyledACHInputContainer>
        <StyledLabel id="accountNumberLabel" htmlFor="AccountNumber">
          Account Number
        </StyledLabel>
        <RequiredField> *</RequiredField>
        <StyledAccountNumber
          id="AccountNumber"
          mask="99999999999999999"
          maskChar=""
          onCut={handleCutCopyPaste}
          onCopy={handleCutCopyPaste}
          onPaste={handleCutCopyPaste}
          onChange={(e) => setAccountNumber(e.target.value)}
        />
      </StyledACHInputContainer>
      <StyledACHInputContainer>
        <StyledLabel id="confirmAccountNumberLabel" htmlFor="ConfirmAccountNumber">
          Confirm Account Number
        </StyledLabel>
        <RequiredField> *</RequiredField>
        <StyledAccountNumber
          id="ConfirmAccountNumber"
          mask="99999999999999999"
          maskChar=""
          onCut={handleCutCopyPaste}
          onCopy={handleCutCopyPaste}
          onPaste={handleCutCopyPaste}
          onChange={(e) => setConfirmAccountNumber(e.target.value)}
        />
      </StyledACHInputContainer>
      <StyledACHInputContainer>
        <StyledLabel id="accountNameLabel" htmlFor="AccountName">
          Name On Account
        </StyledLabel>
        <RequiredField> *</RequiredField>
        <StyledNameOnAccount
          id="AccountName"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
        />
      </StyledACHInputContainer>
      <StyledACHInputContainer>
        <StyledLabel id="bankAccountTypeLabel" htmlFor="bankAccountTypes">
          Bank Account Type
        </StyledLabel>
        <RequiredField> *</RequiredField>
        <Select
          name="bankAccountList"
          id="bankAccountTypes"
          style={{ width: '80%' }}
          options={bankAccountTypes}
          defaultValue={bankAccountTypes[0]}
          onChange={onBankAccountChange.bind(this)}
        />
      </StyledACHInputContainer>
      <StyledAddACH onClick={() => submitACHToPayWith()} disabled={checkDisabled()}>
        Add Account
      </StyledAddACH>
    </StyledACHContainer>
  );
};
const mapStateToProps = (state) => ({
  state,
  achType: state.configurationSlice.features.ACHFormat,
  firstName: state.customerSlice.customer.partyInfo[0].nameInfo.givenName,
  lastName: state.customerSlice.customer.partyInfo[0].nameInfo.surname,
  blockAchNumberOps: state.configurationSlice.features.features.blockAchNumberOps,
  routingNumberError: state?.makePaymentAnonymousSlice?.routingNumberError,
  bankName: state.makePaymentAnonymousSlice?.bankInformation?.commercialName || '',
});

ACHPayContainer.propTypes = {
  achType: PropTypes.string,
  isAnonymousPay: PropTypes.bool,
  blockAchNumberOps: PropTypes.bool,
  dispatch: PropTypes.any,
  firstName: PropTypes.string,
  isUpdatingPaySchedule: PropTypes.bool,
  lastName: PropTypes.string,
  routingNumberError: PropTypes.string,
  setACHTopLevel: PropTypes.func,
  bankName: PropTypes.string,
};
export default connect(mapStateToProps)(ACHPayContainer);
