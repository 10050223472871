import styled from 'styled-components';

export const TemplateOnePanel = styled.h2.attrs({
  id: 'templateOnePanel',
})`
  background-color: ${(props) =>
    props.template === 'template1' ? props.theme.primaryColor : 'white'};
  color: ${(props) => (props.template === 'template1' ? 'white' : 'black')};
  text-align: ${(props) => (props.template === 'template1' ? 'center' : 'left')};
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 0;
  font-weight: 400;
  font-size: ${(props) => (props.template === 'template1' ? '24px' : '14px')};
  font-weight: ${(props) => (props.template === 'template1' ? '400' : '700')};
`;
export const GetQuoteContainer = styled.div.attrs({
  id: 'getQuoteContainer',
})`
  ${(props) =>
    props.template === 'template1'
      ? `border: 3px solid ${props.theme.primaryColor}`
      : `border: none`};
  ${(props) => (props.template === 'template1' ? `margin: 6%` : `margin: 0 0 0 30px`)};
  @media (min-width: 1280px) {
    ${(props) => (props.template === 'template1' ? `margin: 6%` : `margin: 0`)};
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    ${(props) => (props.template === 'template1' ? `margin: 2% 6%` : `margin: 0`)};
    ${(props) => props.template === 'template1' && `margin-bottom: 80px;`}
  }
  @media (min-width: 600px) and (max-width: 899px) {
    ${(props) =>
      props.template === 'template1' ? `margin: 6% 6% 80px 1px` : `margin: 0 0 0 30px`};
    ${(props) => (props.template === 'template1' ? `padding: 0` : `padding-bottom: 16px`)};
  }
  @media (max-width: 599px) {
    ${(props) =>
      props.template === 'template1'
        ? `margin-bottom: calc(20% + 80px); margin-left: 6%;`
        : `margin-left: 6%`};
  }
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  max-width: 310px;
`;

export const GetQuoteHeader = styled.div`
  font-family: 'Helvetica Neue', Lato, proxima-nova, Arial, Helvetica, sans-serif, sans-serif;
  font-size: 14px;
  font-weight: ${(props) => (props.template === 'template1' ? '600' : '400')};
  color: black;
  margin: ${(props) => (props.template === 'template1' ? '20px' : '0px 0 20px 0px')};
  text-align: ${(props) => (props.template === 'template1' ? 'center' : 'left')};
  line-height: 20px;
`;
export const GetAQuoteButtonContainer = styled.div.attrs({
  id: 'getAQuoteButtonContainer',
})`
  display: flex;
  justify-content: ${(props) => (props.template === 'template1' ? 'space-evenly' : 'flex-start')};

  @media (max-width: 599px) {
    margin-left: 0;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    margin-left: 0;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
