import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { getReplies } from './selectors';
import {
  EventMessage,
  EventNameLabel,
  LoaderMargin,
  LossDateLabel,
  PolicyNumberLabel,
  QuestionReplyContainer,
  QuestionsMobileLabel,
  QuestionLabel,
  RecapDesktopLabel,
  ReportClaimStepFiveContainer,
  ResponsesMobileLabel,
} from './styles';
import {
  DISPLAY_DATE_FORMAT,
  TIME_FORMAT_24_HRS,
  TIME_FORMAT_AM_PM,
} from '../../../common/constants';
import Loader from '../../../common/loader';
import ReportClaimButtons from '../report-claim-button-container';
import ApiError from '../../../common/api-error';
import { ReportClaimPreviousButton } from '../report-claim-button-container/styles';

export const ReportClaimStepFiveReviewAndSubmit = (props) => {
  const {
    activeStep,
    body,
    lossDate,
    lossTime,
    address1,
    address2,
    address3,
    address4,
    claimEventValidationErrors,
    claimDescription,
    county,
    country,
    city,
    driverLicense,
    driverName,
    handleNext,
    handleStep,
    policyNumber,
    postalCode,
    questionReplies,
    state,
    submitted,
    whatHappened,
    isClaimEventValidated,
    isFilesBeingAttached,
  } = props;

  return (
    <>
      <Helmet>
        <title>Report Claim - Review and Submit</title>
      </Helmet>

      {!isClaimEventValidated ? (
        <>
          {claimEventValidationErrors === '' ? (
            <LoaderMargin>
              <Loader />
              {isFilesBeingAttached && <RecapDesktopLabel>Uploading file(s)...</RecapDesktopLabel>}
            </LoaderMargin>
          ) : (
            <>
              <ApiError title="Claim Validation Error" body={claimEventValidationErrors} />
              <ReportClaimPreviousButton
                onClick={() => {
                  handleStep(activeStep);
                }}
              >
                Back
              </ReportClaimPreviousButton>
            </>
          )}
        </>
      ) : (
        <>
          {!submitted ? (
            <ReportClaimStepFiveContainer aria-label="Recap Table">
              <EventNameLabel>{claimDescription}</EventNameLabel>
              <EventMessage>
                The following is a recap of the incident information you have provided. Please
                review and ensure everything is correct:
              </EventMessage>
              <QuestionReplyContainer>
                {policyNumber && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <PolicyNumberLabel
                      id={'QuestionLabelPolicyNumber'}
                      aria-label="Question Policy Number"
                    >
                      {' '}
                      Policy Number
                    </PolicyNumberLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelPolicyNumber'} aria-label={`Response ${policyNumber}`}>
                      {' '}
                      {policyNumber}
                    </div>
                  </>
                )}
                {driverName && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel id={'QuestionLabelDriverName'} aria-label="Question Driver Name">
                      {' '}
                      Driver Name
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelDriverName'} aria-label={`Response ${driverName}`}>
                      {' '}
                      {driverName}
                    </div>
                  </>
                )}
                {driverLicense && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel id={'QuestionLabelDriverLicense'} aria-label="Question ">
                      {' '}
                      Driver License
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelDriverLicense'} aria-label={`Response ${driverLicense}`}>
                      {' '}
                      {driverLicense}
                    </div>
                  </>
                )}
                {lossDate && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <LossDateLabel id={'QuestionLabelLossDate'} aria-label="Question Loss Date">
                      {' '}
                      Loss Date
                    </LossDateLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div
                      id={'ResponseLabelLossDate'}
                      aria-label={`Response ${moment(lossDate).format(DISPLAY_DATE_FORMAT)}`}
                    >
                      {' '}
                      {moment(lossDate).format(DISPLAY_DATE_FORMAT)}
                    </div>
                  </>
                )}
                {lossTime && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel id={'QuestionLabelLossTime'} aria-label="Question Loss Time">
                      {' '}
                      Loss Time
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div
                      id={'ResponseLabelLossTime'}
                      aria-label={`Response ${moment(lossTime, TIME_FORMAT_24_HRS).format(
                        TIME_FORMAT_AM_PM,
                      )}`}
                    >
                      {moment(lossTime, TIME_FORMAT_24_HRS).format(TIME_FORMAT_AM_PM)}
                    </div>
                  </>
                )}
                {country && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel id={'QuestionLabelCountry'} aria-label="Question Country">
                      {' '}
                      Country
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelCountry'} aria-label={`Response ${country}`}>
                      {' '}
                      {country}
                    </div>
                  </>
                )}
                {address1 && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel
                      id={'QuestionLabelAddress1'}
                      aria-label="Question Address Line One"
                    >
                      {' '}
                      Address 1
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelAddress1'} aria-label={`Response ${address1}`}>
                      {' '}
                      {address1}
                    </div>
                  </>
                )}
                {address2 && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel
                      id={'QuestionLabelAddress2'}
                      aria-label="Question Address Line Two"
                    >
                      {' '}
                      Address 2
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelAddress2'} aria-label={`Response ${address2}`}>
                      {' '}
                      {address2}
                    </div>
                  </>
                )}
                {address3 && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel
                      id={'QuestionLabelAddress3'}
                      aria-label="Question Address Line Three"
                    >
                      {' '}
                      Address 3
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelAddress3'} aria-label={`Response ${address3}`}>
                      {' '}
                      {address3}
                    </div>
                  </>
                )}
                {address4 && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel
                      id={'QuestionLabelAddress4'}
                      aria-label="Question Address Line Four"
                    >
                      {' '}
                      Address 4
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelAddress4'} aria-label={`Response ${address4}`}>
                      {' '}
                      {address4}
                    </div>
                  </>
                )}
                {county && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel id={'QuestionLabelCounty'} aria-label="Question County">
                      {' '}
                      County
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelCounty'} aria-label={`Response ${county}`}>
                      {' '}
                      {county}
                    </div>
                  </>
                )}
                {city && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel id={'QuestionLabelCity'} aria-label="Question City">
                      {' '}
                      City
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelCity'} aria-label={`Response ${city}`}>
                      {' '}
                      {city}
                    </div>
                  </>
                )}
                {country && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel id={'QuestionLabelCountry'} aria-label="Question Country">
                      {' '}
                      Country
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelCountry'} aria-label={`Response ${country}`}>
                      {' '}
                      {country}
                    </div>
                  </>
                )}
                {state && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel id={'QuestionLabelState'} aria-label="Question State">
                      {' '}
                      State
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelState'} aria-label={`Response ${state}`}>
                      {' '}
                      {state}
                    </div>
                  </>
                )}
                {postalCode && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel id={'QuestionLabelPostalCode'} aria-label="Question Postal Code">
                      {' '}
                      Postal Code
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelPostalCode'} aria-label={`Response ${postalCode}`}>
                      {' '}
                      {postalCode}
                    </div>
                  </>
                )}
                {whatHappened && (
                  <>
                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                    <QuestionLabel
                      id={'QuestionLabelWhatHappened'}
                      aria-label="Question What Happened"
                    >
                      {' '}
                      What Happened
                    </QuestionLabel>
                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                    <div id={'ResponseLabelWhatHappened'} aria-label={`Response ${whatHappened}`}>
                      {' '}
                      {whatHappened}
                    </div>
                  </>
                )}
                {questionReplies &&
                  questionReplies.map((question) => {
                    return question.map((thisQuestion) => {
                      if (thisQuestion.subQuestions) {
                        return (
                          <React.Fragment key={thisQuestion.id}>
                            <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                            <QuestionLabel> {thisQuestion.topDesc}</QuestionLabel>
                            <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                            <div id={thisQuestion.shortDesc}>{thisQuestion.topVal}</div>
                            {thisQuestion.subQuestions.map((subQuestion) => {
                              if (subQuestion.Value) {
                                return (
                                  <React.Fragment key={subQuestion.id}>
                                    <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                                    <QuestionLabel
                                      id={subQuestion.shortDesc + ` Label`}
                                      aria-label={`Question ${subQuestion.shortDesc}`}
                                    >
                                      {subQuestion.shortDesc}
                                    </QuestionLabel>
                                    <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                                    <div
                                      id={subQuestion.shortDesc + ` Value`}
                                      aria-label={`Response ${subQuestion.Value}`}
                                    >
                                      {subQuestion.Value}
                                    </div>
                                  </React.Fragment>
                                );
                              }
                              return null;
                            })}
                          </React.Fragment>
                        );
                      } else {
                        if (thisQuestion.Value) {
                          return (
                            <React.Fragment key={thisQuestion.id}>
                              <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                              <QuestionLabel
                                id={`QuestionLabel ${thisQuestion.shortDesc}`}
                                aria-label={`Question ${thisQuestion.shortDesc}`}
                              >
                                {thisQuestion.shortDesc}
                              </QuestionLabel>
                              <ResponsesMobileLabel>Response</ResponsesMobileLabel>
                              <div
                                id={`ResponseLabel ${thisQuestion.shortDesc}`}
                                aria-label={`Response ${thisQuestion.Value}`}
                              >
                                {thisQuestion.Value}
                              </div>
                            </React.Fragment>
                          );
                        }
                        return null;
                      }
                    });
                  })}
              </QuestionReplyContainer>
              <ReportClaimButtons
                activeStep={activeStep}
                handleNext={handleNext}
                handleStep={handleStep}
                body={body}
              />
            </ReportClaimStepFiveContainer>
          ) : (
            <LoaderMargin>
              <Loader />
            </LoaderMargin>
          )}
        </>
      )}
    </>
  );
};
ReportClaimStepFiveReviewAndSubmit.propTypes = {
  activeStep: PropTypes.any,
  address1: PropTypes.string,
  address2: PropTypes.string,
  address3: PropTypes.string,
  address4: PropTypes.string,
  body: PropTypes.object,
  city: PropTypes.string,
  county: PropTypes.string,
  country: PropTypes.string,
  claimDescription: PropTypes.string,
  claimEventValidationErrors: PropTypes.any,
  driverName: PropTypes.string,
  driverLicense: PropTypes.string,
  dispatch: PropTypes.func,
  handleNext: PropTypes.any,
  handleStep: PropTypes.any,
  lossDate: PropTypes.string,
  lossTime: PropTypes.string,
  policyNumber: PropTypes.string,
  postalCode: PropTypes.any,
  questionReplies: PropTypes.array,
  selectedClaimEventTemplateView: PropTypes.string,
  state: PropTypes.string,
  submitted: PropTypes.bool,
  whatHappened: PropTypes.any,
  isClaimEventValidated: PropTypes.bool,
  isFilesBeingAttached: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  address1: state.reportClaimStepTwoSlice.selectedLocation?.address?.[0]?.addr1,
  address2: state.reportClaimStepTwoSlice.selectedLocation?.address?.[0]?.addr2,
  address3: state.reportClaimStepTwoSlice.selectedLocation?.address?.[0]?.addr3,
  address4: state.reportClaimStepTwoSlice.selectedLocation?.address?.[0]?.addr4,
  body: state.reportClaimStepFourSlice.validationClaim,
  claimDescription: state.reportClaimSlice.selectedClaimEventTemplate.description,
  claimEventValidationErrors: state.reportClaimStepFourSlice.claimEventValidationErrors,
  county: state.reportClaimStepTwoSlice.selectedLocation?.address?.[0]?.county,
  country: state.reportClaimStepTwoSlice.selectedLocation?.address?.[0]?.regionCd,
  city: state.reportClaimStepTwoSlice.selectedLocation?.address?.[0]?.city,
  driverName: state.reportClaimStepFourSlice.driverName,
  driverLicense: state.reportClaimStepFourSlice.driverLicense,
  lossDate: state.reportClaimStepThreeSlice.claimEventContacts.lossDt,
  lossTime: state.reportClaimStepThreeSlice.claimEventContacts.lossTm,
  policyNumber: state.reportClaimStepThreeSlice.claimEventContacts.policyNumber,
  postalCode: state.reportClaimStepTwoSlice.selectedLocation?.address?.[0]?.postalCode,
  questionReplies: getReplies(state),
  state: state.reportClaimStepTwoSlice.selectedLocation?.address?.[0]?.stateProvCd,
  selectedClaimEventTemplateView: state.reportClaimSlice.selectedClaimEventTemplate.view,
  submitted: state.reportClaimStepFiveSlice.isSubmitted,
  whatHappened: state.reportClaimStepTwoSlice.descriptionText,
  isClaimEventValidated: state.reportClaimStepFourSlice.isClaimEventValidated,
  isFilesBeingAttached: state.reportClaimStepFourSlice.isFilesBeingAttached,
});
export default connect(mapStateToProps)(ReportClaimStepFiveReviewAndSubmit);
