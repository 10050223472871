import React, { useContext, useEffect, useState } from 'react';
import {
  ButtonContainer,
  FieldContainer,
  FieldHeader,
  FooterContainer,
  ForgotUsernameHeader,
  ForgotUsernameMessageLabel,
  ForgotUsernameStepSubContainer,
  LogoLink,
  RequiredField,
  RetrieveUsernameButton,
  ReturnToLoginPageButton,
  ReturnToLoginPageButtonContainer,
  TextInputField,
} from './styles';
import { LoggedOutTemplateLogo } from '../login/styles';
import Footer from '../dashboard/footer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import Loader from '../../common/loader';
import { resetState, setIsLoading } from './forgotUsernameReducer';
import { retrieveUsername } from './forgotUsernameApi';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { isEmail } from '../../../utils/validators/generalValidators';
import { getPolicyInfoInputField } from '../../../utils/fields';
import { DateIconSpan, IconSpan } from '../../../utils/fields/styles';
import { StyledCheckIcon } from '../user-profile/styles';
import { setRegistrationToken } from '../login/loginReducer';
import {
  getRegistrationFields,
  verifyRegistrationFields,
} from '../create-account/createAccountApi';

export const ForgotUsername = (props) => {
  const {
    dispatch,
    response,
    isLoading,
    i18n,
    companyName,
    registrationFields,
    customerId,
    customerToken,
  } = props;
  const [isViaSms, setViaSms] = useState(false);
  const [policyNumber, setPolicyNumber] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const theme = useContext(ThemeContext);
  const { enqueueSnackbar } = useSnackbar();
  const [answers, setAnswers] = useState('');

  useEffect(() => {
    dispatch(setRegistrationToken(''));
    dispatch(setIsLoading(true));
    dispatch(getRegistrationFields(enqueueSnackbar));
  }, []);

  useEffect(() => {
    if (registrationFields && registrationFields.length > 0) {
      props.dispatch(setIsLoading(false));
    }
  }, [registrationFields]);

  const navigate = useNavigate();

  useEffect(() => {
    if (email !== '' && customerToken !== '' && customerId !== '') {
      navigate('/');
      enqueueSnackbar(
        'If the information you have provided matches our records, your user id will be sent to the registration email address.',
        {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        },
      );
      dispatch(
        retrieveUsername(
          {
            policyNumber: answers[0].answer,
            contactMethod: 'email',
            contactValue: email,
          },
          enqueueSnackbar,
        ),
      );
    }
  }, [customerToken, customerId]);

  const getUsername = () => {
    const registrationAnswers = [];
    for (let i = 0; i < registrationFields.length; i++) {
      registrationAnswers[i] = { name: registrationFields[i].value, value: answers[i].answer };
    }
    dispatch(
      verifyRegistrationFields(
        {
          optionKey: 'serviceportal',
          registrationInfo: registrationAnswers,
        },
        enqueueSnackbar,
        true,
      ),
    );
  };

  const areRequiredFieldsComplete = () => {
    if (
      registrationFields.length === 0 ||
      Object.entries(answers).length !== registrationFields.length
    )
      return true;
    for (const [, value] of Object.entries(answers)) {
      if (!value.fieldComplete) return true;
    }
    if (!isEmail(email)) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    // check required fields are complete and listen on any state related changes
    areRequiredFieldsComplete();
  }, [answers, registrationFields]);

  useEffect(() => {
    dispatch(resetState());
  }, []);

  useEffect(() => {
    if (response !== null) {
      navigate('/');
    }
  }, [response]);

  return (
    <>
      <LogoLink to="/dashboard">
        <LoggedOutTemplateLogo
          src={`config/images/loggedout-logo.png`}
          alt={companyName + ' logo'}
        />
      </LogoLink>
      <>
        <ForgotUsernameStepSubContainer>
          <ForgotUsernameHeader>Retrieve User ID</ForgotUsernameHeader>
          <ForgotUsernameMessageLabel>
            If you forgot your user ID, we can send it to you via email. The information you provide
            must match our records.
          </ForgotUsernameMessageLabel>
          {registrationFields.map((registrationField, index) => (
            <FieldContainer key={index} id={registrationField.name + 'FieldContainer'}>
              <FieldHeader>
                {registrationField.name}
                <RequiredField> *</RequiredField>
              </FieldHeader>
              {getPolicyInfoInputField(
                registrationField.value,
                index,
                answers,
                setAnswers,
                'ForgotUsername',
              )}
            </FieldContainer>
          ))}
          <FieldContainer id={'ForgotUsernameEmailFieldContainer'}>
            <FieldHeader>
              Registration Email
              <RequiredField> *</RequiredField>
            </FieldHeader>
            <div>
              <TextInputField
                id={'RegistrationEmail'}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                autoComplete="email"
              />{' '}
              {isEmail(email) && (
                <IconSpan>
                  <StyledCheckIcon />
                </IconSpan>
              )}
            </div>
          </FieldContainer>
          <ButtonContainer>
            {isLoading ? (
              <Loader />
            ) : (
              <RetrieveUsernameButton
                disabled={areRequiredFieldsComplete()}
                onClick={() => {
                  getUsername();
                }}
              >
                Retrieve Username
              </RetrieveUsernameButton>
            )}
          </ButtonContainer>
        </ForgotUsernameStepSubContainer>
        <ReturnToLoginPageButtonContainer>
          <ReturnToLoginPageButton to="/">Return to the Sign-In screen</ReturnToLoginPageButton>
        </ReturnToLoginPageButtonContainer>
      </>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  );
};
ForgotUsername.propTypes = {
  dispatch: PropTypes.func,
  response: PropTypes.object,
  registrationFields: PropTypes.array,
  isLoading: PropTypes.bool,
  state: PropTypes.object,
  i18n: PropTypes.object,
  customerToken: PropTypes.string,
  customerId: PropTypes.string,
  companyName: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  isLoading: state.forgotUsernameSlice.isLoading,
  registrationFields: state.loginSlice.registrationFields,
  response: state.forgotUsernameSlice.response,
  i18n: state.i18nSlice.i18n,
  companyName: state.configurationSlice.features.companyName,
  customerToken: state.loginSlice.registrationToken,
  customerId: state.loginSlice.customerId,
  state,
});

export default connect(mapStateToProps)(ForgotUsername);
