import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  AddVehicleCompanyContainer,
  AddVehicleCompanyField,
  AddVehicleCompanyFieldHeader,
  AddVehicleCompanySubHeader,
  AddVehicleCompanyTextAreaField,
  AddVehicleFinanceCompanyButton,
  AddVehicleFinanceCompanyButtonsContainer,
  AddVehicleFinanceCompanyTextInputField,
  LinkStyleButton,
  RadioLabel,
  VehicleComponent,
  VehicleRadioButton,
} from './styles';
import { RequiredField } from '../change-property-mortgagee/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getVehicleRisks, submitPolicyChange } from '../../policyChangeAPI';
import Loader from '../../../../common/loader';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import Select from 'react-select';
import {
  setPolicyChangeCoverageRecapFields,
  setVehicleInterestTypes,
} from '../../policyChangeReducer';
import { createField, formatRecapFieldDate, getVehicleById } from '../selector';
import { Spacer, StyledLoaderContainer } from '../../styles';
import Addresses from '../../../../common/addresses';
import { setAddressFields } from '../../../../common/addresses/addressUtils';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useNavigate } from 'react-router';
import { getVehicleInterests } from './selectors';

export const AddVehicleFinanceCompany = (props) => {
  const {
    dispatch,
    selectedPolicy,
    vehicleRisks,
    isVehicleRisksLoading,
    vehicleInterestTypes,
    customerId,
    selectedPolicyChangeTemplate,
    i18n,
    isLossPayee,
    vehicleInterests,
  } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [reason, setReason] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [name, setName] = useState('');
  const [stateOrProvince, setStateOrProvince] = useState({
    label: '-- Select a State --',
    value: 'default',
  });
  const [zip, setZip] = useState('');
  const [vehicleInterestType, setVehicleInterestType] = useState({
    label: '-- Select a Type --',
    value: 'default',
  });
  const [loanNumber, setLoanNumber] = useState('');
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedVehicleRiskId, setSelectedVehicleRiskId] = useState(null);

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(
      createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
    );
    fields.push(
      createField(
        'Select Financed Vehicle',
        getVehicleById(selectedVehicleRiskId, vehicleRisks),
        'text',
      ),
    );
    fields.push(createField('Reason for Add', reason, 'text'));
    fields.push(createField('Name', name, 'text'));
    fields.push(createField('Address', address, 'text'));
    fields.push(createField('City', city, 'text'));
    fields.push(createField('State', stateOrProvince.value, 'text'));
    fields.push(createField('ZIP', zip, 'text'));
    fields.push(createField('Type', vehicleInterestType.value, 'text'));
    fields.push(createField('Loan Number', loanNumber, 'text'));
    return fields;
  };

  const onSubmitButtonClick = () => {
    setIsSubmitButtonEnabled(false);
    setIsSubmitting(true);

    // create payload
    const payload = {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
          vehicle: [
            {
              id: selectedVehicleRiskId,
            },
          ],
          ai: [
            {
              accountNumber: loanNumber,
              interestName: name,
              interestTypeCd: vehicleInterestType.value,
              partyInfo: {
                addresses: [
                  {
                    addr1: address,
                    city: city,
                    stateProvCd: stateOrProvince.value,
                    postalCode: zip,
                  },
                ],
              },
            },
          ],
        },
      ],
      sourceCd: 'ServicePortal',
    };

    // set fields for recap/result
    props.dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));

    // submit API call
    props.dispatch(submitPolicyChange(payload));
  };

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    const areRequiredFieldsCompleted =
      effectiveDate.length > 0 &&
      reason.length > 0 &&
      name.length > 0 &&
      address &&
      address.length > 0 &&
      city &&
      city.length > 0 &&
      selectedVehicleRiskId.length > 0 &&
      stateOrProvince &&
      stateOrProvince.value &&
      stateOrProvince.value.length > 0 &&
      zip &&
      zip.length > 0 &&
      vehicleInterestType.value &&
      vehicleInterestType.value.length > 0 &&
      loanNumber.length > 0;
    if (areRequiredFieldsCompleted !== isSubmitButtonEnabled) {
      setIsSubmitButtonEnabled(areRequiredFieldsCompleted);
    }
  };

  checkRequiredFields();

  useEffect(() => {
    if (!effectiveDate) {
      setEffectiveDate(todayDt);
    }
    let options = vehicleInterests.map((interest) => {
      return { label: interest, value: interest };
    });
    dispatch(setVehicleInterestTypes(options));

    props.dispatch(getVehicleRisks(selectedPolicy.value));
  }, [selectedPolicy]);

  const handlePlaceSelect = (address) => {
    setAddressFields(address, setAddress, setCity, setZip, setStateOrProvince);
  };

  const navigate = useNavigate();

  return (
    <>
      <AddVehicleCompanyContainer>
        <AddVehicleCompanyField>
          <AddVehicleCompanyFieldHeader>
            Requested Effective Date <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addVehicleFinanceCompany.effectiveDateTooltip')}
            />
          </AddVehicleCompanyFieldHeader>
          <input
            type="date"
            aria-label="Requested Effective Date"
            value={effectiveDate}
            min={todayDt}
            onChange={(e) => {
              setEffectiveDate(e.target.value);
            }}
          />
        </AddVehicleCompanyField>
        <AddVehicleCompanyField>
          <AddVehicleCompanyFieldHeader>
            Reason for add <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addVehicleFinanceCompany.reasonTooltip')}
            />
          </AddVehicleCompanyFieldHeader>
          <AddVehicleCompanyTextAreaField
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
        </AddVehicleCompanyField>
        <AddVehicleCompanyField>
          <AddVehicleCompanyFieldHeader>
            Select Financed Vehicle <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addVehicleFinanceCompany.selectVehicleTooltip')}
            />
          </AddVehicleCompanyFieldHeader>
          {isVehicleRisksLoading ? (
            <Loader />
          ) : (
            vehicleRisks.map((vehicle) => {
              return (
                <VehicleComponent key={vehicle.id}>
                  <label htmlFor={vehicle.description}>
                    <VehicleRadioButton
                      id={vehicle.description}
                      name="riskVehicle"
                      value={vehicle.id}
                      onChange={(e) => {
                        setSelectedVehicleRiskId(e.target.value);
                      }}
                    />{' '}
                    <RadioLabel>{vehicle.description}</RadioLabel>
                  </label>
                </VehicleComponent>
              );
            })
          )}
        </AddVehicleCompanyField>
        <AddVehicleCompanySubHeader>
          {isLossPayee ? 'New Loss Payee Information' : 'New Vehicle Finance Company Information'}
        </AddVehicleCompanySubHeader>
        <AddVehicleCompanyField>
          <AddVehicleCompanyFieldHeader>
            Name <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addVehicleFinanceCompany.nameTooltip')}
            />
          </AddVehicleCompanyFieldHeader>
          <AddVehicleFinanceCompanyTextInputField
            id="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </AddVehicleCompanyField>
        <AddVehicleCompanyField>
          <Addresses
            id="changeCoverageAddVehicleFinanceCompanyAddress"
            handlePlaceSelect={handlePlaceSelect}
            isRequiredField={true}
          >
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addVehicleFinanceCompany.addressTooltip')}
            />
          </Addresses>
        </AddVehicleCompanyField>

        <AddVehicleCompanyField>
          <AddVehicleCompanyFieldHeader>
            Type <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addVehicleFinanceCompany.typeTooltip')}
            />
          </AddVehicleCompanyFieldHeader>
          <Select
            name="vehicleInterestTypesList"
            id="vehicleInterestTypesList"
            defaultValue={{ label: '-- Select a Type --', value: 'default' }}
            options={vehicleInterestTypes}
            onChange={(selectedInterestType) => setVehicleInterestType(selectedInterestType)}
          />
        </AddVehicleCompanyField>

        <AddVehicleCompanyField>
          <AddVehicleCompanyFieldHeader>
            Loan Number <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addVehicleFinanceCompany.loanNumberTooltip')}
            />
          </AddVehicleCompanyFieldHeader>
          <AddVehicleFinanceCompanyTextInputField
            id="loanNumber"
            value={loanNumber}
            onChange={(e) => {
              setLoanNumber(e.target.value);
            }}
          />
        </AddVehicleCompanyField>
      </AddVehicleCompanyContainer>
      <AddVehicleFinanceCompanyButtonsContainer>
        <LinkStyleButton
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          Cancel
        </LinkStyleButton>
        <AddVehicleFinanceCompanyButton
          id="Submit"
          disabled={!isSubmitButtonEnabled || isSubmitting}
          onClick={() => {
            onSubmitButtonClick();
          }}
        >
          Submit
        </AddVehicleFinanceCompanyButton>
        {isSubmitting ? (
          <StyledLoaderContainer>
            <Loader loaderheight="20px" loaderwidth="20px" />
          </StyledLoaderContainer>
        ) : (
          <Spacer />
        )}
      </AddVehicleFinanceCompanyButtonsContainer>
    </>
  );
};
AddVehicleFinanceCompany.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicy: PropTypes.object,
  vehicleRisks: PropTypes.array,
  vehicleInterestTypes: PropTypes.array,
  isVehicleRisksLoading: PropTypes.bool,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  i18n: PropTypes.object,
  isLossPayee: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  vehicleRisks: state.policyChangeSlice.vehicleRisks,
  vehicleInterestTypes: state.policyChangeSlice.vehicleInterestTypes,
  isVehicleRisksLoading: state.policyChangeSlice.isVehicleRisksLoading,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  vehicleInterests: getVehicleInterests(
    state.policyChangeSlice.selectedPolicyChangeTemplate.policyChangeSections[0].questions,
  ),
  customerId: state.customerSlice.customer.systemId,
  i18n: state.i18nSlice.i18n,
  state,
});

export default connect(mapStateToProps)(AddVehicleFinanceCompany);
