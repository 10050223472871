import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCustomerParty } from './selectors';
import EditingEmail from './editEmail';
import { setEditingEmail, setEmailErrors } from './profileReducer';
import {
  EditEmailAddressLink,
  EditEmailButtonContainer,
  EmailAddressLabel,
  EmailContainer,
  EmailDescription,
  EmailError,
  ProfileEmailsAddress,
} from './styles';
import TooltipInfo from '../../common/tooltip';
import { getText } from '../../../utils/i18n';

export const EmailDetails = (props) => {
  const { dispatch, editingEmail, emailErrors, isDashboard, notificationEmail, i18n } = props;

  React.useEffect(() => {
    props.dispatch(setEmailErrors(null));
  }, []);

  return (
    <>
      {emailErrors && <EmailError>{emailErrors}</EmailError>}
      <>
        <EmailContainer>
          <EmailAddressLabel>
            Email Address
            <EmailDescription> (Used for email notifications)</EmailDescription>
            <TooltipInfo
              title={getText(i18n, 'dashboard.customerInformation.emailAddressTooltip')}
            />
            <ProfileEmailsAddress>
              {notificationEmail && notificationEmail !== '' ? notificationEmail : 'None'}
            </ProfileEmailsAddress>
          </EmailAddressLabel>
          <EditEmailButtonContainer>
            {!editingEmail && (
              <EditEmailAddressLink onClick={() => dispatch(setEditingEmail(true))}>
                Edit
              </EditEmailAddressLink>
            )}
          </EditEmailButtonContainer>
        </EmailContainer>
      </>
      {editingEmail ? <EditingEmail isDashboard={isDashboard} /> : null}
    </>
  );
};

EmailDetails.propTypes = {
  dispatch: PropTypes.func,
  emailAddress: PropTypes.string,
  emailErrors: PropTypes.any,
  editingEmail: PropTypes.bool,
  isDashboard: PropTypes.bool,
  isLoading: PropTypes.bool,
  notificationEmail: PropTypes.string,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  isLoading: state.profileSlice.isLoading,
  emailErrors: state.profileSlice.emailErrors,
  editingEmail: state.profileSlice.editingEmail,
  notificationEmail: state.notificationSlice.notificationEmail,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(EmailDetails);
