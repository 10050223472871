import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAdditionalInterestsForVehicles, submitPolicyChange } from '../../policyChangeAPI';
import {
  ChangeVehicleFinanceCompanyButtonsContainer,
  ChangeVehicleFinanceCompanyComponent,
  ChangeVehicleFinanceCompanyContainer,
  ChangeVehicleFinanceCompanyField,
  ChangeVehicleFinanceCompanyFieldHeader,
  ChangeVehicleFinanceCompanyLoanNumberInputField,
  ChangeVehicleFinanceCompanyNameInputField,
  ChangeVehicleFinanceCompanyNextButton,
  ChangeVehicleFinanceCompanyRadioButton,
  ChangeVehicleFinanceCompanySubmitButton,
  ChangeVehicleFinanceCompanyTextAreaField,
  LinkStyleButton,
  RadioLabel,
  RequiredField,
} from './styles';
import moment from 'moment';
import { createField, formatRecapFieldDate, getVehicleFinanceCompanyById } from '../selector';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import Select from 'react-select';
import Loader from '../../../../common/loader';
import {
  setPolicyChangeCoverageRecapFields,
  setVehicleInterestTypes,
} from '../../policyChangeReducer';
import { Spacer, StyledLoaderContainer } from '../../styles';
import { setAddressFields } from '../../../../common/addresses/addressUtils';
import Addresses from '../../../../common/addresses';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useNavigate } from 'react-router';
import { getVehicleInterests } from '../add-vehicle-finance-company/selectors';

export const ChangeVehicleFinanceCompany = (props) => {
  const {
    dispatch,
    selectedPolicy,
    vehicleFinanceCompanyList,
    vehicleInterestTypes,
    vehicleInterests,
    customerId,
    selectedPolicyChangeTemplate,
    i18n,
    isLossPayee,
  } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showChangeFinanceCompanyPageTwo, setShowChangeFinanceCompanyPageTwo] = useState(false);
  const [stateOrProvince, setStateOrProvince] = useState({
    label: '-- Select a State --',
    value: 'default',
  });
  const [vehicleInterestType, setVehicleInterestType] = useState({
    label: '-- Select a Type --',
    value: 'default',
  });
  const [reason, setReason] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [name, setName] = useState('');
  const [zip, setZip] = useState('');
  const [loanNumber, setLoanNumber] = useState('');
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    const areRequiredFieldsCompleted =
      reason.length > 0 &&
      name.length > 0 &&
      address &&
      address.length > 0 &&
      city &&
      city.length > 0 &&
      selectedCompanyId.length > 0 &&
      stateOrProvince &&
      stateOrProvince.value &&
      stateOrProvince.value.length > 0 &&
      zip &&
      zip.length > 0 &&
      vehicleInterestType.value &&
      vehicleInterestType.value.length > 0 &&
      loanNumber.length > 0;
    if (areRequiredFieldsCompleted !== isSubmitButtonEnabled) {
      setIsSubmitButtonEnabled(areRequiredFieldsCompleted);
    }
  };

  checkRequiredFields();

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(
      createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
    );
    fields.push(createField('Reason for Change', reason, 'text'));
    fields.push(createField('Name', name, 'text'));
    fields.push(createField('Address', address, 'text'));
    fields.push(createField('City', city, 'text'));
    fields.push(createField('State', stateOrProvince.value, 'text'));
    fields.push(createField('ZIP', zip, 'text'));
    fields.push(createField('Type', vehicleInterestType.value, 'text'));
    fields.push(createField('Loan Number', loanNumber, 'text'));
    return fields;
  };

  const onSubmitButtonClick = () => {
    setIsSubmitButtonEnabled(false);
    setIsSubmitting(true);

    // create payload
    const payload = {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
          ai: [
            {
              accountNumber: loanNumber,
              interestName: name,
              interestTypeCd: vehicleInterestType.value,
              partyInfo: {
                addresses: [
                  {
                    addr1: address,
                    city: city,
                    postalCode: zip,
                    stateProvCd: stateOrProvince.value,
                  },
                ],
              },
            },
            { ...selectedCompany, status: 'Changed' },
          ],
        },
      ],
      sourceCd: 'ServicePortal',
    };

    // set fields for recap/result
    props.dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));

    // submit API call
    props.dispatch(submitPolicyChange(payload));
  };

  useEffect(() => {
    if (selectedPolicy?.value !== 'default') {
      props.dispatch(getAdditionalInterestsForVehicles(selectedPolicy.value));
    }
    let options = vehicleInterests.map((interest) => {
      return { label: interest, value: interest };
    });
    dispatch(setVehicleInterestTypes(options));
  }, [selectedPolicy]);

  const handlePlaceSelect = (address) => {
    setAddressFields(address, setAddress, setCity, setZip, setStateOrProvince);
  };

  const navigate = useNavigate();

  return (
    <>
      {vehicleFinanceCompanyList.length === 0 ? (
        <ChangeVehicleFinanceCompanyFieldHeader>
          No finance companies available to change.
        </ChangeVehicleFinanceCompanyFieldHeader>
      ) : !showChangeFinanceCompanyPageTwo ? (
        <>
          <ChangeVehicleFinanceCompanyContainer>
            <ChangeVehicleFinanceCompanyField>
              <ChangeVehicleFinanceCompanyFieldHeader>
                Requested Effective Date <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(
                    i18n,
                    'changeCoverage.changeVehicleFinanceCompany.effectiveDateTooltip',
                  )}
                />
              </ChangeVehicleFinanceCompanyFieldHeader>
              <input
                type="date"
                aria-label="Requested Effective Date"
                value={effectiveDate}
                min={todayDt}
                onChange={(e) => {
                  setEffectiveDate(e.target.value);
                }}
              />
            </ChangeVehicleFinanceCompanyField>
            <ChangeVehicleFinanceCompanyField>
              <ChangeVehicleFinanceCompanyFieldHeader>
                {isLossPayee
                  ? 'Loss Payee to be changed '
                  : 'Vehicle Finance Company to be changed '}
                <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(
                    i18n,
                    'changeCoverage.changeVehicleFinanceCompany.companyToBeChangedTooltip',
                  )}
                />
              </ChangeVehicleFinanceCompanyFieldHeader>
              {vehicleFinanceCompanyList.map((company) => {
                return (
                  <ChangeVehicleFinanceCompanyComponent key={company.id}>
                    <label htmlFor={company.id}>
                      <ChangeVehicleFinanceCompanyRadioButton
                        name="financeCompany"
                        value={company.id}
                        id={company.id}
                        onChange={(e) => {
                          setSelectedCompanyId(e.target.value);
                          setSelectedCompany(
                            getVehicleFinanceCompanyById(e.target.value, vehicleFinanceCompanyList),
                          );
                        }}
                      />{' '}
                      <RadioLabel>{`${company.interestName} - ${company.interestTypeCd} ${
                        company.accountNumber ? company.accountNumber : ''
                      }`}</RadioLabel>
                    </label>
                  </ChangeVehicleFinanceCompanyComponent>
                );
              })}
            </ChangeVehicleFinanceCompanyField>
          </ChangeVehicleFinanceCompanyContainer>
          <ChangeVehicleFinanceCompanyButtonsContainer>
            <LinkStyleButton
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Cancel
            </LinkStyleButton>
            <ChangeVehicleFinanceCompanyNextButton
              disabled={!selectedCompanyId}
              onClick={() => {
                setShowChangeFinanceCompanyPageTwo(true);
              }}
            >
              Next
            </ChangeVehicleFinanceCompanyNextButton>
          </ChangeVehicleFinanceCompanyButtonsContainer>
        </>
      ) : (
        <>
          <ChangeVehicleFinanceCompanyContainer>
            <ChangeVehicleFinanceCompanyField>
              <ChangeVehicleFinanceCompanyFieldHeader>
                {isLossPayee ? 'Loss Payee to be Changed' : 'Vehicle Finance Company to be Changed'}
              </ChangeVehicleFinanceCompanyFieldHeader>
              {`${selectedCompany.interestName} - ${selectedCompany.interestTypeCd} ${selectedCompany.accountNumber}`}
            </ChangeVehicleFinanceCompanyField>
            <ChangeVehicleFinanceCompanyField>
              <ChangeVehicleFinanceCompanyFieldHeader>
                Reason for Change <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.changeVehicleFinanceCompany.reasonTooltip')}
                />
              </ChangeVehicleFinanceCompanyFieldHeader>
              <ChangeVehicleFinanceCompanyTextAreaField
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
            </ChangeVehicleFinanceCompanyField>
            <ChangeVehicleFinanceCompanyField>
              <ChangeVehicleFinanceCompanyFieldHeader>
                Name <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.changeVehicleFinanceCompany.nameTooltip')}
                />
              </ChangeVehicleFinanceCompanyFieldHeader>
              <ChangeVehicleFinanceCompanyNameInputField
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </ChangeVehicleFinanceCompanyField>
            <ChangeVehicleFinanceCompanyField>
              <Addresses
                id="changeCoverageChangeVehicleFinanceCompanyAddress"
                handlePlaceSelect={handlePlaceSelect}
                isRequiredField={true}
              >
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.changeVehicleFinanceCompany.addressTooltip')}
                />
              </Addresses>
            </ChangeVehicleFinanceCompanyField>
            <ChangeVehicleFinanceCompanyField>
              <ChangeVehicleFinanceCompanyFieldHeader>
                Type <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.changeVehicleFinanceCompany.typeTooltip')}
                />
              </ChangeVehicleFinanceCompanyFieldHeader>
              <Select
                name="vehicleInterestTypesList"
                id="vehicleInterestTypesList"
                defaultValue={{ label: '-- Select a Type --', value: 'default' }}
                options={vehicleInterestTypes}
                onChange={(selectedInterestType) => setVehicleInterestType(selectedInterestType)}
              />
            </ChangeVehicleFinanceCompanyField>

            <ChangeVehicleFinanceCompanyField>
              <ChangeVehicleFinanceCompanyFieldHeader>
                Loan Number <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(
                    i18n,
                    'changeCoverage.changeVehicleFinanceCompany.loanNumberTooltip',
                  )}
                />
              </ChangeVehicleFinanceCompanyFieldHeader>
              <ChangeVehicleFinanceCompanyLoanNumberInputField
                value={loanNumber}
                onChange={(e) => {
                  setLoanNumber(e.target.value);
                }}
              />
            </ChangeVehicleFinanceCompanyField>
          </ChangeVehicleFinanceCompanyContainer>
          <ChangeVehicleFinanceCompanyButtonsContainer>
            <LinkStyleButton
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Cancel
            </LinkStyleButton>
            <ChangeVehicleFinanceCompanySubmitButton
              disabled={!isSubmitButtonEnabled || isSubmitting}
              onClick={() => {
                onSubmitButtonClick();
              }}
            >
              Submit
            </ChangeVehicleFinanceCompanySubmitButton>
            {isSubmitting ? (
              <StyledLoaderContainer>
                <Loader loaderheight="20px" loaderwidth="20px" />
              </StyledLoaderContainer>
            ) : (
              <Spacer />
            )}
          </ChangeVehicleFinanceCompanyButtonsContainer>
        </>
      )}
    </>
  );
};
ChangeVehicleFinanceCompany.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicy: PropTypes.object,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  vehicleFinanceCompanyList: PropTypes.array,
  vehicleInterestTypes: PropTypes.array,
  i18n: PropTypes.object,
  isLossPayee: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  customerId: state.customerSlice.customer.systemId,
  vehicleFinanceCompanyList: state.policyChangeSlice.vehicleFinanceCompanyList,
  vehicleInterestTypes: state.policyChangeSlice.vehicleInterestTypes,
  vehicleInterests: getVehicleInterests(
    state.policyChangeSlice.selectedPolicyChangeTemplate.policyChangeSections[0].questions,
  ),
  i18n: state.i18nSlice.i18n,
  state,
});

export default connect(mapStateToProps)(ChangeVehicleFinanceCompany);
