import styled from 'styled-components';
import Select from 'react-select';

export const AddCoverageContainer = styled.div.attrs({
  id: 'AddCoverageContainer',
})``;

export const AddCoverageField = styled.div.attrs({
  id: 'AddCoverageFieldContainer',
})`
  min-width: 200px;
  width: 50%;
  @media (max-width: 599px) {
    width: 98%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 98%;
  }
`;
export const AddCoverageFieldHeader = styled.h4.attrs({
  id: 'AddCoverageFieldHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  width: 75%;
  @media (max-width: 599px) {
    width: 98%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 98%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const RequiredField = styled.span.attrs({})`
  color: red;
`;

export const AddCoverageTextAreaField = styled.textarea.attrs({})`
  height: 100px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 75%;
  @media (max-width: 599px) {
    width: 98%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 98%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const TwoColumnContainerTop = styled.div.attrs({})`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
  @media (max-width: 570px) {
  }
  @media (min-width: 571px) and (max-width: 699px) {
  }
  @media (min-width: 700px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const TwoColumnContainer = styled.div.attrs({})`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 30px;
  @media (max-width: 470px) {
  }
  @media (min-width: 571px) and (max-width: 699px) {
  }
  @media (min-width: 700px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const AddCoverageTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  width: 50%;
  padding-right: 0px;
  @media (max-width: 599px) {
    width: 98%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 98%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const AddCoverageCheckboxField = styled.input.attrs({
  type: 'checkbox',
})`
  height: 30px;
  width: 30px;
  @media (max-width: 599px) {
    width: 98%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 98%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const AddDateField = styled.input.attrs({
  type: 'date',
})`
  width: 50%;
  height: 35px;
  @media (max-width: 599px) {
    width: 98%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 98%;
  }
`;
export const AddCoverageTextAreaInputField = styled.textarea.attrs({})`
  height: 30px;
  width: 75%;
  padding-right: 0px;
  @media (max-width: 599px) {
    width: 98%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 98%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const AddCoverageNumericInputField = styled.input.attrs({
  type: 'number',
})`
  height: 30px;
  width: 75%;
  padding-right: 0px;
  @media (max-width: 599px) {
    width: 98%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 98%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const AddCoverageButtonsContainer = styled.div.attrs({
  id: 'addCoverageButtonsContainer',
})`
  text-align: right;
  margin-right: 2%;
  margin-top: 2%;
  display: flex;
  @media (max-width: 384px) {
    text-align: center;
  }
`;
export const AddCoverageButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 385px) {
    margin-bottom: 16px;
  }
`;
export const StyledSelect = styled(Select)`
  width: 75%;
  @media (max-width: 599px) {
    width: 98%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 98%;
  }
`;
export const LinkStyleButton = styled.button.attrs({
  id: 'cancelButton',
})`
  background-color: initial;
  color: ${(props) => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 0;
  margin-top: 16px;

  :hover {
    background-color: initial;
    color: ${(props) => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${(props) => props.theme.primaryActiveColor};
  }
`;
