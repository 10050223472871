import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  OtherButton,
  OtherButtonsContainer,
  OtherTextAreaField,
  OtherContainer,
  OtherField,
  OtherFieldHeader,
  RequiredField,
  LinkStyleButton,
} from './styles';
import { ISO_DATE_FORMAT } from '../../../../common/constants';

import { submitPolicyChange } from '../../policyChangeAPI';
import { setPolicyChangeCoverageRecapFields } from '../../policyChangeReducer';
import { createField } from '../selector';
import Loader from '../../../../common/loader';
import { Spacer, StyledLoaderContainer } from '../../styles';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useNavigate } from 'react-router';

export const Other = (props) => {
  const { customerId, selectedPolicyChangeTemplate, selectedPolicy, i18n } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);

  // local state
  const [reason, setReason] = useState('');
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(createField('Reason for Change', reason, 'text'));
    return fields;
  };

  const onSubmitButtonClicked = () => {
    setIsSubmitting(true);
    setIsSubmitButtonEnabled(false);

    // create payload
    const payload = {
      changeDt: effectiveDate,
      templateIdRef: selectedPolicyChangeTemplate.id,
      customerRef: customerId,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
        },
      ],
      sourceCd: 'ServicePortal',
    };

    // set fields for recap/result
    props.dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));

    // submit API call
    props.dispatch(submitPolicyChange(payload));
  };

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    const areRequiredFieldsCompleted = reason.length > 0;
    if (areRequiredFieldsCompleted !== isSubmitButtonEnabled) {
      setIsSubmitButtonEnabled(areRequiredFieldsCompleted);
    }
  };

  checkRequiredFields();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!effectiveDate) {
      setEffectiveDate(todayDt);
    }
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <OtherContainer>
        <OtherField>
          <OtherFieldHeader>
            Requested Effective Date <RequiredField>*</RequiredField>
            <TooltipInfo title={getText(i18n, 'changeCoverage.Other.effectiveDateTooltip')} />
          </OtherFieldHeader>
          <input
            type="date"
            aria-label="Requested Effective Date"
            value={effectiveDate}
            min={todayDt}
            onChange={(e) => {
              setEffectiveDate(e.target.value);
            }}
          />
        </OtherField>
        <OtherFieldHeader>
          Reason for Change <RequiredField>*</RequiredField>
          <TooltipInfo title={getText(i18n, 'changeCoverage.Other.reasonTooltip')} />
        </OtherFieldHeader>
        <OtherTextAreaField
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
          }}
        />
      </OtherContainer>
      <OtherButtonsContainer>
        <LinkStyleButton
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          Cancel
        </LinkStyleButton>
        <OtherButton
          id="Submit"
          disabled={!isSubmitButtonEnabled}
          onClick={() => {
            onSubmitButtonClicked();
          }}
        >
          Submit
        </OtherButton>
        {isSubmitting ? (
          <StyledLoaderContainer>
            <Loader loaderheight="20px" loaderwidth="20px" />
          </StyledLoaderContainer>
        ) : (
          <Spacer />
        )}
      </OtherButtonsContainer>
    </>
  );
};

Other.propTypes = {
  dispatch: PropTypes.func,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  selectedPolicy: PropTypes.object,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  customerId: state.customerSlice.customer.systemId,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  i18n: state.i18nSlice.i18n,
  state,
});

export default connect(mapStateToProps)(Other);
