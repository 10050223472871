import styled from 'styled-components';

export const AnonymousReportClaimsStepSixContainer = styled.div.attrs({
  id: 'AnonymousReportClaimsStepSixContainer',
})`
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 3%;
    margin-right: 3%;
  }
`;

export const QuestionReplyContainer = styled.div.attrs({
  id: 'questionReplyContainer',
})`
  display: grid;
  @media (max-width: 599px) {
    column-gap: 8px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    column-gap: 8px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 3fr;
    row-gap: 8px;
  }
`;

export const RecapDesktopLabel = styled.h3.attrs({})`
  margin-top: 20px;
  margin-bottom: 8px;
  font-weight: 500;
  /* @media (max-width: 900px) {
    display: none;
  } */
`;

export const RecapText = styled.div.attrs({
  id: 'recapText',
})`
  font-size: 16px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const QuestionsMobileLabel = styled.div.attrs({
  id: 'questionsMobileLabel',
})`
  margin-top: 30px;
  margin-bottom: 8px;
  /* font-size: 18px; */
  font-weight: 600;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const ResponsesMobileLabel = styled.div.attrs({
  id: 'responsesMobileLabel',
})`
  margin-top: 12px;
  margin-bottom: 8px;
  /* font-size: 18px; */
  font-weight: 600;
  @media (min-width: 900px) {
    display: none;
  }
`;

export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-top: 16px;
  }
`;
export const QuestionLabel = styled.div.attrs((props) => ({
  id: props.id ? props.id : 'questionLabelGeneric',
}))`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
