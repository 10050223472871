import styled from 'styled-components';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
export const RequiredField = styled.span.attrs({})`
  color: red;
`;

export const RemoveDriverButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
  display: flex;
  margin-top: 10%;
  width: 100%;
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const NextButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;

export const RemoveDriverField = styled.div.attrs({})`
  width: 75%;
  @media (max-width: 959px) {
    width: 100%;
  }
`;
export const RemoveDriverComponent = styled.div.attrs({})`
  margin-bottom: 12px;
`;
export const RemoveLabel = styled.div.attrs({})`
  display: block;
  margin-left: 22px;
`;
export const RemoveDriverRadioButton = styled.input.attrs({
  type: 'radio',
})`
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  float: left;
`;

export const RemoveDriverHeader = styled.h4.attrs({
  id: 'RemoveDriverHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.001rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const PhotoIcon = styled(InsertPhotoIcon).attrs({
  className: 'fa fa-picture-o',
  'aria-hidden': true,
})``;
PhotoIcon.displayName = 'PhotoIcon';

export const RemoveDriverDateInputField = styled.input.attrs({
  type: 'date',
})`
  height: 30px;
  width: 60%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const GarbageIconContainer = styled.div.attrs({})`
  margin-left: 15px;
`;
export const GarbageIcon = styled(DeleteOutlineIcon).attrs({
  className: 'fa fa-trash-o',
})`
  cursor: pointer;
  margin-left: -15px;
`;

export const RemoveDriverTextAreaField = styled.textarea.attrs({
  id: 'removeDriverTextAreaField',
})`
  height: 100px;
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 50%;
  }
  @media (min-width: 1280px) {
    width: 50%;
  }
`;
export const RemoveDriverSelectDriverContainer = styled.div`
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const LinkStyleButton = styled.button.attrs({
  id: 'Cancel',
})`
  background-color: initial;
  color: ${(props) => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 0;

  :hover {
    background-color: initial;
    color: ${(props) => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${(props) => props.theme.primaryActiveColor};
  }
`;
