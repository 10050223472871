import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditingEmail from './edit-email';
import { setEmailErrors } from '../user-profile/profileReducer';
import { EmailAddressLabel, EmailContainer, EmailError, ProfileEmailsAddress } from './styles';
import TooltipInfo from '../../common/tooltip';
import { getText } from '../../../utils/i18n';
import { getCustomerParty } from './selectors';

export const EmailDetails = (props) => {
  const { dispatch, emailAddress, editingEmailAndPhone, emailErrors, i18n, email, setEmail } =
    props;

  React.useEffect(() => {
    dispatch(setEmailErrors(null));
  }, []);

  return (
    <>
      {emailErrors && <EmailError>{emailErrors}</EmailError>}

      <>
        <EmailContainer>
          <EmailAddressLabel>
            Email Address
            <TooltipInfo
              title={getText(i18n, 'dashboard.customerInformation.emailAddressTooltip')}
            />
          </EmailAddressLabel>
          {!editingEmailAndPhone && (
            <>
              <ProfileEmailsAddress>{emailAddress}</ProfileEmailsAddress>
            </>
          )}
          {editingEmailAndPhone ? <EditingEmail email={email} setEmail={setEmail} /> : null}
        </EmailContainer>
      </>
    </>
  );
};

EmailDetails.propTypes = {
  dispatch: PropTypes.func,
  email: PropTypes.string,
  emailAddress: PropTypes.string,
  emailErrors: PropTypes.any,
  editingEmailAndPhone: PropTypes.bool,
  i18n: PropTypes.object,
  isDashboard: PropTypes.bool,
  isLoading: PropTypes.bool,
  notificationEmail: PropTypes.string,
  setEmail: PropTypes.func,
};
const mapStateToProps = (state) => ({
  isLoading: state.profileSlice.isLoading,
  emailErrors: state.profileSlice.emailErrors,
  editingEmailAndPhone: state.profileSlice.editingEmailAndPhone,
  emailAddress: getCustomerParty(state).emailInfo
    ? getCustomerParty(state).emailInfo.emailAddr
    : '',
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(EmailDetails);
