import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import {
  AccountDetailsHeader,
  BalancesHeader,
  CurrentDueContainer,
  CurrentDueData,
  CurrentDueHeaderMobile,
  DueDateContainer,
  DueDateData,
  DueDateHeaderMobile,
  PayoffAmountData,
  PayoffAmountHeaderMobile,
  PayoffContainer,
  PayoffCurrentDueAndDueDateContainer,
  RecentPaymentsHeader,
} from './styles';

import AccountRows from './accountRows';
import RecentPaymentsRow from './recentPaymentsRow';
import { getPaymentTypes } from '../dashboard/billing/billingAPI';
import { useLocation } from 'react-router-dom';
import { resetStepTwoState } from '../make-payment/step-two-amount/makePaymentStepTwoReducer';
import ScheduledPendingPaymentsRow from './scheduledPendingPaymentsRow';

export const AccountDetails = (props) => {
  const { billingAccounts, dispatch, paymentTypes, pendingOrScheduledPayments, recentPayments } =
    props;
  useEffect(() => {
    dispatch(getPaymentTypes());
    window.scrollTo(0, 0);
    //path is fine, allow state to be tracked
    return () => {
      // reset pay instruments for account details if a users leaves the details page
      // and unlisten
      dispatch(resetStepTwoState(null));
    };
  }, []);

  let {
    state: { accountNumber },
  } = useLocation();
  return (
    <>
      <Helmet>
        <title>Account Details</title>
      </Helmet>
      {accountNumber ? (
        <>
          {billingAccounts.map((account) => {
            const { fullAccount } = account;
            if (fullAccount.systemId === accountNumber) {
              return (
                <React.Fragment key={fullAccount.id}>
                  <AccountDetailsHeader>
                    Account {fullAccount.accountDisplayNumber}
                  </AccountDetailsHeader>
                  <PayoffCurrentDueAndDueDateContainer>
                    <PayoffContainer>
                      <PayoffAmountHeaderMobile>Payoff Amount</PayoffAmountHeaderMobile>
                      <PayoffAmountData>
                        ${parseFloat(fullAccount.totalAmt).toFixed(2)}
                      </PayoffAmountData>
                    </PayoffContainer>
                    <CurrentDueContainer>
                      <CurrentDueHeaderMobile>Current Due</CurrentDueHeaderMobile>
                      <CurrentDueData>${parseFloat(fullAccount.openAmt).toFixed(2)}</CurrentDueData>
                    </CurrentDueContainer>
                    <DueDateContainer>
                      <DueDateHeaderMobile>Due Date</DueDateHeaderMobile>
                      <DueDateData>{account.dueDate}</DueDateData>
                    </DueDateContainer>
                  </PayoffCurrentDueAndDueDateContainer>
                  {recentPayments && (
                    <>
                      <RecentPaymentsHeader>Recent Payments</RecentPaymentsHeader>
                      <RecentPaymentsRow fullAccount={fullAccount} paymentTypes={paymentTypes} />
                    </>
                  )}
                  {pendingOrScheduledPayments && (
                    <>
                      <RecentPaymentsHeader>Pending/Scheduled Payments</RecentPaymentsHeader>
                      <ScheduledPendingPaymentsRow
                        fullAccount={fullAccount}
                        paymentTypes={paymentTypes}
                      />
                    </>
                  )}
                  <BalancesHeader>Balances </BalancesHeader>
                  <AccountRows fullAccount={fullAccount} />
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </>
      ) : (
        <div>accounts unavailable</div>
      )}
    </>
  );
};
AccountDetails.propTypes = {
  billingAccounts: PropTypes.array,
  dispatch: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      accountNumber: PropTypes.string,
      policyToUpdatePayPlan: PropTypes.any,
    }),
  }),
  paymentTypes: PropTypes.object,
  pendingOrScheduledPayments: PropTypes.bool,
  recentPayments: PropTypes.bool,
};
export const mapStateToProps = (state) => ({
  state,
  billingAccounts: state.billingSlice.billingAccounts,
  paymentTypes: state.billingSlice.paymentTypes,
  pendingOrScheduledPayments:
    state.configurationSlice.features.features.pendingOrScheduledPaymentsList,
  recentPayments: state.configurationSlice.features.features.recentPaymentsList,
});

export default connect(mapStateToProps)(AccountDetails);
