import styled from 'styled-components';
export const MobileLineDivider = styled.div.attrs({
  id: 'MobileLineDivider',
})`
  height: 1px;
  width: ${props => (props.width ? props.width : `100%`)};
  background-color: black;
  @media (max-width: 899px) {
    display: none;
  }
`;
