import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import OktaSignIn from '@okta/okta-signin-widget';
import { useOktaAuth } from '@okta/okta-react';
import { Helmet } from 'react-helmet';
import './style.css';
import {
  CreateAccountButton,
  FooterContainer,
  LoggedOutTemplateLogo,
  LoginArea,
  LoginButtonContainer,
  LoginContainer,
  LogoLink,
  MakePaymentAnonymousButton,
  MakePaymentAnonymousContainer,
  OktaContainer,
  ReportClaimButton,
  ReportClaimContainer,
  StyledDiv,
  StyledSpan,
} from './styles';
import SidePanel from './sidepanel';
import Footer from '../dashboard/footer';
import PropTypes from 'prop-types';
import { showHelperPanel } from './selector';
import { useNavigate } from 'react-router';
import { setRegistrationToken } from './loginReducer';
import { initializeGoogleAnalytics } from '../../../utils/google-analytics';
import { updatePendoConfig } from '../../../utils/pendo';
import { initializeGoogleAnalytics4 } from '../../../utils/google-analytics-4';

const UserLogin = (props) => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef();
  const { features, showHelperPanel, companyName, user, i18n } = props;

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }
    if (oktaAuth === undefined || features.features === undefined) {
      return false;
    }
    const issuer = features.tokenIssuer;
    const clientId = features.clientId;

    const scopes = ['openid', 'profile', 'email', 'customer', 'offline_access'];
    const redirectUri = window.location.protocol + '//' + window.location.host + '/login/callback';

    const widget = new OktaSignIn({
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      useClassicEngine: false,
      i18n: {
        en: i18n.signin.loginWidget,
      },
      helpLinks: {
        help: '',
        custom: [
          {
            text: 'Forgot password?',
            href: '/#/forgotPassword',
          },
          {
            text: 'Forgot user ID?',
            href: '/#/forgotUsername',
          },
        ],
      },
      features: {
        rememberMe: features && features.features && features.features.rememberMe,
      },
      authParams: {
        issuer,
        // display: 'page',
        // responseMode: pkce ? 'query' : 'fragment',
        scopes,
      },
    });

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
        scopes,
      })
      .then((tokens) => {
        oktaAuth.handleLoginRedirect(tokens);
      })
      .catch((err) => {
        throw err;
      });

    // set user for pendo
    updatePendoConfig(features.companyName, user?.preferred_username || 'anonymous');

    return () => (widget !== undefined ? widget.remove() : undefined);
  }, [oktaAuth, features]);

  const navigate = useNavigate();

  useEffect(() => {
    // initialize Google Analytics
    initializeGoogleAnalytics(features.keys, features.companyName);

    // initialize Google Analytics 4
    initializeGoogleAnalytics4(features.keys, features.companyName);
  }, [features]);

  useEffect(() => {
    // clear registrationToken, so create account will ask for validation fields
    props.dispatch(setRegistrationToken(''));
  }, []);

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <LogoLink to="/dashboard">
        <LoggedOutTemplateLogo
          src={`config/images/loggedout-logo.png`}
          alt={companyName + ' logo'}
        />
      </LogoLink>
      <LoginContainer>
        {showHelperPanel && <SidePanel />}
        <LoginArea className="center" sidepanel={showHelperPanel}>
          <OktaContainer ref={widgetRef} />
          <LoginButtonContainer>
            <StyledSpan>Don&#39;t have an account?</StyledSpan>
            <CreateAccountButton to="/createAccount">Create New Account</CreateAccountButton>
          </LoginButtonContainer>
          {features && features.features && features.features.unauthenticatedPayments && (
            <MakePaymentAnonymousContainer>
              <StyledDiv>
                Want to pay your bill without creating an account or signing in?
              </StyledDiv>
              <MakePaymentAnonymousButton
                id="MakePaymentAnonymousButton"
                onClick={() => {
                  navigate('/makePaymentAnonymous');
                }}
              >
                Pay Bill
              </MakePaymentAnonymousButton>
            </MakePaymentAnonymousContainer>
          )}
          {features && features.features && features.features.unauthenticatedReportClaim && (
            <ReportClaimContainer>
              <StyledDiv>Want to report a claim without signing in?</StyledDiv>
              <ReportClaimButton
                id="ReportClaimButton"
                onClick={() => {
                  navigate('/reportClaimNotLoggedIn');
                }}
              >
                Report Claim
              </ReportClaimButton>
            </ReportClaimContainer>
          )}
        </LoginArea>
      </LoginContainer>

      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  );
};

UserLogin.propTypes = {
  dispatch: PropTypes.any,
  features: PropTypes.object,
  showHelperPanel: PropTypes.bool,
  userId: PropTypes.string,
  state: PropTypes.object,
  i18n: PropTypes.object,
  companyName: PropTypes.string,
  user: PropTypes.object,
};

export const mapStateToProps = (state) => ({
  features: state.configurationSlice.features,
  i18n: state.i18nSlice.i18n,
  showHelperPanel: showHelperPanel(state),
  companyName: state.configurationSlice.features.companyName,
  user: state.loginSlice.user,
  state,
});

export default connect(mapStateToProps)(UserLogin);
