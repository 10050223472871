import moment from 'moment';
import { findIndex, maxBy } from 'lodash';

import { API } from '../../../../utils/api/index';
import { setBillingAccounts, setErrors, setLoading, setPaymentTypes } from './billingReducer';
import { ISO_DATE_FORMAT } from '../../../common/constants';

export const getLatestInvoiceDate = (invoices) => {
  const latest = maxBy(invoices, (i) => moment(i.invoiceDt, ISO_DATE_FORMAT).valueOf());
  return latest ? latest.invoiceDt : null;
};
const getLatestDueDate = (invoices) => {
  const latest = maxBy(invoices, (i) => moment(i.dueDt, ISO_DATE_FORMAT).valueOf());
  return latest ? latest.dueDt : null;
};
export const getCustomerBillingInformation = (
  customerId,
  hasOpenBalance,
  billingAccountsCountLimit,
  billingStatusCd,
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    if (API) {
      return (
        API.get(
          `billingAccounts?customerId=${customerId}&hasOpenBalance=${hasOpenBalance}&limit=${billingAccountsCountLimit}&cycleCd=${billingStatusCd}`,
        )
          //first we get the customer id accounts ^^ and then we get the system ids for each.

          .then((response) => {
            if (
              response.data.billingAccounts === undefined ||
              response.data.billingAccounts.length === 0
            ) {
              // when there are no billing accounts, do not execute transformation code
              setBillingAccounts([]);
              return;
            }
            let billingAccountsBeforeTransform = response.data.billingAccounts;
            let promises = response.data.billingAccounts.map((account) => {
              return API.get(`billingAccounts/${account.billingAccount.systemId}/full`)
                .then((response) => response.data)
                .catch((err) => err);
            });
            return Promise.allSettled(promises)
              .then((response) => {
                let afterTransform = billingAccountsBeforeTransform.map((account) => {
                  const arrayMatch = findIndex(response, (i) => {
                    return account.ref === i?.value?.systemId;
                  });

                  let invoiceDate = getLatestInvoiceDate(
                    response[arrayMatch]?.value?.billingInvoices,
                  );

                  let dueDate = '';

                  if (response[arrayMatch]?.value?.openAmt !== '0.00') {
                    dueDate = getLatestDueDate(response[arrayMatch]?.value?.billingInvoices);
                  }
                  let fullAccount = response[arrayMatch]?.value;
                  return {
                    ...account,
                    invoiceDate,
                    dueDate,
                    fullAccount,
                  };
                });
                dispatch(setBillingAccounts(afterTransform));
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => dispatch(setErrors(error.message)))
          .finally(() => dispatch(setLoading(false)))
      );
    }
  };
};

export const getPaymentTypes = () => {
  return (dispatch) => {
    return API.get('coderefs/AR/account/insured-payment-types')
      .then((response) => {
        const keys = response.data.keys?.[0]?.options;
        if (keys && keys.length > 0) {
          const paymentTypes = {};
          keys.forEach((key) => {
            paymentTypes[key.value] = key.name;
          });
          dispatch(setPaymentTypes(paymentTypes));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};
