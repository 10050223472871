import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  AmountContainer,
  AmountData,
  AmountHeader,
  AmountHeaderMobile,
  DescriptionContainer,
  NoRecentPayments,
  PaymentIdContainer,
  PaymentIdData,
  PaymentIdHeader,
  PaymentIdHeaderMobile,
  ReceiptDateContainer,
  ReceiptDateData,
  ReceiptDateHeader,
  ReceiptDateHeaderMobile,
  ReceiptDescriptionData,
  ReceiptDescriptionHeader,
  ReceiptDescriptionHeaderMobile,
  ReceiptTypeData,
  ReceiptTypeHeader,
  ReceiptTypeHeaderMobile,
  RecentPaymentDataContainer,
  RecentPaymentHeaderContainer,
  ShowRecentPaymentsButton,
  StatusContainer,
  StatusData,
  StatusHeader,
  StatusHeaderMobile,
  TableFix,
  TypeContainer,
} from './styles';
import { DISPLAY_DATE_FORMAT } from '../../common/constants';
import { setPolicyAccountsAndMerge, setStatementAccountsAndMerge } from './utils';

export const RecentPaymentsRow = (props) => {
  const { fullAccount, paymentTypes } = props;

  const [paymentTypesMap, setPaymentTypesMap] = React.useState(new Map());
  const [showAllRecentPayments, setShowRecentPayments] = React.useState(false);
  React.useEffect(() => {
    if (paymentTypes) {
      const entries = Object.entries(paymentTypes);
      if (entries.length > 0) {
        setPaymentTypesMap(new Map(Object.entries(paymentTypes)));
      }
    }
  }, [paymentTypes]);

  const getTransactionAmount = (transactionAmount, receiptAmt) => {
    const paymentAmountFloat = parseFloat(transactionAmount ? transactionAmount : receiptAmt);
    return paymentAmountFloat < 0
      ? `-$${parseFloat(Math.abs(paymentAmountFloat)).toFixed(2)}`
      : `$${parseFloat(paymentAmountFloat).toFixed(2)}`;
  };

  const setStatementAccounts = () => {
    let merged = setStatementAccountsAndMerge(fullAccount, paymentTypesMap, false);

    return (
      <>
        <RecentPaymentHeaderContainer>
          <PaymentIdHeader>Payment Id</PaymentIdHeader>
          <ReceiptDateHeader>Transaction Date</ReceiptDateHeader>
          <ReceiptDescriptionHeader>Description</ReceiptDescriptionHeader>
          <ReceiptTypeHeader>Type</ReceiptTypeHeader>
          <AmountHeader>Amount </AmountHeader>
          <StatusHeader>Status</StatusHeader>
        </RecentPaymentHeaderContainer>
        {merged.length > 0 ? (
          merged
            .filter((_item, index) => (showAllRecentPayments ? index <= merged.length : index < 5))
            .map((payment, index) => {
              return (
                <RecentPaymentDataContainer key={index}>
                  <PaymentIdContainer>
                    <PaymentIdHeaderMobile>Payment ID</PaymentIdHeaderMobile>
                    <PaymentIdData>{payment.receiptReference}</PaymentIdData>
                  </PaymentIdContainer>
                  <DescriptionContainer>
                    <ReceiptDescriptionHeaderMobile>Description</ReceiptDescriptionHeaderMobile>
                    <ReceiptDescriptionData>{payment.desc}</ReceiptDescriptionData>
                  </DescriptionContainer>
                  <TypeContainer>
                    <ReceiptTypeHeaderMobile>Type</ReceiptTypeHeaderMobile>
                    <ReceiptTypeData>{payment.receiptTypeCd}</ReceiptTypeData>
                  </TypeContainer>
                  <ReceiptDateContainer>
                    <ReceiptDateHeaderMobile>Transaction Date</ReceiptDateHeaderMobile>
                    <ReceiptDateData>
                      {moment(payment.receiptDt).format(DISPLAY_DATE_FORMAT)}
                    </ReceiptDateData>
                  </ReceiptDateContainer>
                  <AmountContainer>
                    <AmountHeaderMobile>Amount</AmountHeaderMobile>
                    <AmountData>
                      {getTransactionAmount(payment.transactionAmount, payment.receiptAmt)}
                    </AmountData>
                  </AmountContainer>
                  <StatusContainer>
                    <StatusHeaderMobile>Status</StatusHeaderMobile>
                    <StatusData>Applied</StatusData>
                  </StatusContainer>
                </RecentPaymentDataContainer>
              );
            })
        ) : (
          <NoRecentPayments>No Recent Payments Available</NoRecentPayments>
        )}
      </>
    );
  };

  const setPolicyAccounts = () => {
    let merged = setPolicyAccountsAndMerge(fullAccount, paymentTypesMap, false);

    return (
      <>
        <RecentPaymentHeaderContainer>
          <PaymentIdHeader>Payment Id</PaymentIdHeader>
          <ReceiptDescriptionHeader>Description</ReceiptDescriptionHeader>
          <ReceiptTypeHeader>Type</ReceiptTypeHeader>
          <ReceiptDateHeader>Transaction Date</ReceiptDateHeader>
          <AmountHeader>Amount </AmountHeader>
          <StatusHeader>Status</StatusHeader>
        </RecentPaymentHeaderContainer>
        {merged.length > 0 ? (
          <>
            {merged
              .filter((_item, index) =>
                showAllRecentPayments ? index <= merged.length : index < 5,
              )
              .map((payment, index) => {
                return (
                  <RecentPaymentDataContainer key={index}>
                    <PaymentIdContainer>
                      <PaymentIdHeaderMobile>Payment Id</PaymentIdHeaderMobile>
                      <PaymentIdData>{payment.receiptReference}</PaymentIdData>
                    </PaymentIdContainer>
                    <DescriptionContainer>
                      <ReceiptDescriptionHeaderMobile>Description</ReceiptDescriptionHeaderMobile>
                      <ReceiptDescriptionData>{payment.desc}</ReceiptDescriptionData>
                    </DescriptionContainer>
                    <TypeContainer>
                      <ReceiptTypeHeaderMobile>Type</ReceiptTypeHeaderMobile>
                      <ReceiptTypeData>{payment.receiptTypeCd}</ReceiptTypeData>
                    </TypeContainer>
                    <ReceiptDateContainer>
                      <ReceiptDateHeaderMobile>Transaction Date</ReceiptDateHeaderMobile>
                      <ReceiptDateData>
                        {moment(payment.receiptDt).format(DISPLAY_DATE_FORMAT)}
                      </ReceiptDateData>
                    </ReceiptDateContainer>
                    <AmountContainer>
                      <AmountHeaderMobile>Amount</AmountHeaderMobile>
                      <AmountData>
                        {getTransactionAmount(payment.transactionAmount, payment.receiptAmt)}
                      </AmountData>
                    </AmountContainer>
                    <StatusContainer>
                      <StatusHeaderMobile>Status</StatusHeaderMobile>
                      <StatusData>Applied</StatusData>
                    </StatusContainer>
                  </RecentPaymentDataContainer>
                );
              })}
            <TableFix>
              <ShowRecentPaymentsButton
                onClick={() => setShowRecentPayments(!showAllRecentPayments)}
              >
                {merged.length <= 5
                  ? null
                  : showAllRecentPayments
                  ? `Hide Recent Payments`
                  : `Show All Recent Payments`}
              </ShowRecentPaymentsButton>
            </TableFix>
          </>
        ) : (
          <NoRecentPayments>No Recent Payments Available</NoRecentPayments>
        )}
      </>
    );
  };

  return (
    <>
      {fullAccount.accountDisplayName === 'Statement'
        ? setStatementAccounts()
        : setPolicyAccounts()}
    </>
  );
};
RecentPaymentsRow.propTypes = {
  fullAccount: PropTypes.shape({
    underlyingBillingAccounts: PropTypes.array,
    accountDisplayName: PropTypes.string,
    billingHistory: PropTypes.array,
    pendingReceipts: PropTypes.array,
  }),
  paymentTypes: PropTypes.object,
};
export default RecentPaymentsRow;
