import {
  setClaimEventDynamicQuestions,
  setClaimEventDynamicQuestionsErrors,
  setErrors,
} from './anonymousReportClaimStepFourReducer';
import { TOKENIZED_API } from '../../../../utils/api';

export const getDynamicQuestions = (props, customerToken) => {
  return dispatch => {
    dispatch(setErrors(null));
    const requestBody = {
      id: props.state.anonymousReportClaimStepThreeSlice.claimEvent.id,
      sourceCd: 'ServicePortal_v4.0.0',
      customerNumber: props.claimEvent.customerNumber,
      eventCd: props.claimEvent.eventCd,
      accidentInd: false, // always false in SP 3 at this stage
      lossDt: props.dateValue,
      lossTm: props.timeValue,
      addr: props.state.anonymousReportClaimStepThreeSlice.addr,
      detailDescription: props.state.anonymousReportClaimStepThreeSlice.descriptionText,
      policyNumber:
        props.selectedLocation && props.selectedLocation.policyNumber
          ? props.selectedLocation.policyNumber
          : props.selectedVehicle.policyNumber,
      templateIdRef: props.claimEvent.templateIdRef,
    };

    if (
      props.state.anonymousReportClaimStepTwoSlice.selectedClaimEventTemplate.view === 'Location'
    ) {
      // if HO policy, we use location
      requestBody.riskIdRef = props.selectedLocation.id;
    } else if (
      props.state.anonymousReportClaimStepTwoSlice.selectedClaimEventTemplate.view === 'Vehicle'
    ) {
      // if PA policy, we use driver and vehicle info
      const selectedDriver = props.selectedDriver;
      const nameInfo = selectedDriver.partyInfo.nameInfo;
      requestBody.driverIdRef = selectedDriver.value;
      requestBody.driverName =
        props.state.anonymousReportClaimStepThreeSlice.manualDriverName !== ''
          ? props.state.anonymousReportClaimStepThreeSlice.manualDriverName
          : `${nameInfo.givenName} ${nameInfo.surname}`;
      requestBody.driverLicense = selectedDriver.partyInfo.driverInfo.licenseNumber;
      requestBody.vehicleIdRef = props.selectedVehicle.id;
      requestBody.permissiveDriverRelationshipToInsuredCd =
        props.state.anonymousReportClaimStepThreeSlice.driverRelationshipSelected;
    }

    return TOKENIZED_API.post(`/claimEventValidate?customerToken=${customerToken}`, requestBody)
      .then(response => {
        dispatch(setClaimEventDynamicQuestions(response.data));
      })
      .catch(err => {
        dispatch(
          setClaimEventDynamicQuestionsErrors(
              'Your request cannot be processed at this time.',
          ),
        );
      });
  };
};
