import { createField, formatRecapFieldDate } from '../selector';
import { isEmail, isPhone } from '../../../../../utils/validators/generalValidators';

export const YES_NO_DEFAULT = 'No';

export const getListItems = (constraints) => {
  try {
    return constraints
      .replace(/\\,/g, '@')
      .split(',')
      .map((x) => {
        const item = x.replace(/@/g, ',');
        return { label: item, value: item };
      });
  } catch (e) {
    return [];
  }
};
export const createPolicyChangeCoverageSummary = (
  effectiveDate,
  reason,
  changeName,
  questions,
  answers,
) => {
  const fields = [];
  fields.push(createField('Change Requested', changeName, 'text', false));
  fields.push(createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'));
  fields.push(createField('Reason', reason, 'textArea'));

  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    const answer = answers[i]?.answer;
    let value;
    if (question.dataType === 'Numeric') {
      value = answer.toString();
    } else {
      value = answer;
    }
    if (value && value.length > 0) {
      fields.push(createField(question.text, value, 'text'));
    }

    if (
      question.questions &&
      question.questions.question &&
      question.questions.question.length > 0 &&
      answers[i].subQuestionAnswers
    ) {
      for (let j = 0; j < question.questions.question.length; j++) {
        const subQuestion = question.questions.question[j];
        const subAnswer = answers[i].subQuestionAnswers[j];
        let subValue;
        if (subQuestion.dataType === 'Numeric') {
          subValue = subAnswer.toString();
        } else {
          subValue = subAnswer;
        }
        if (subValue && subValue.length > 0) {
          fields.push(createField(subQuestion.text, subValue, 'text'));
        }
      }
    }
  }
  return fields;
};

export const checkAdditionalAnswersComplete = (
  additionalQuestions,
  answers,
  setAdditionalQuestionsAnswers,
  setAdditionalSubQuestionsAnswers,
) => {
  let requiredAnswersCompleted = true;
  for (let i = 0; i < additionalQuestions.length; i++) {
    const answer = answers[i] !== undefined ? answers[i].answer : '';
    const question = additionalQuestions[i];
    if (question.dataType === 'YesNo' && question.requiredInd === 'Yes') {
      if (answer === undefined || answer.length === 0) {
        // default or yes if not set
        setAdditionalQuestionsAnswers(
          i,
          question.defaultValue !== undefined ? question.defaultValue : YES_NO_DEFAULT,
        );
      }
    } else if (question.dataType === 'List' && question.requiredInd === 'Yes') {
      requiredAnswersCompleted = answer !== undefined && answer.length !== 0;
      if (!requiredAnswersCompleted) {
        return false;
      }
    } else if (question.dataType === 'Numeric' && question.requiredInd === 'Yes') {
      requiredAnswersCompleted =
        requiredAnswersCompleted && answer !== undefined && answer.length > 0;
      if (!requiredAnswersCompleted) {
        return false;
      }
    } else if (question.dataType === 'TextArea' && question.requiredInd === 'Yes') {
      requiredAnswersCompleted =
        requiredAnswersCompleted && answer !== undefined && answer.length > 0;
      if (!requiredAnswersCompleted) {
        return false;
      }
    } else if (
      question.dataType === 'Phone' &&
      (question.requiredInd === 'Yes' || (answer !== undefined && answer.length > 0))
    ) {
      requiredAnswersCompleted =
        requiredAnswersCompleted && answer !== undefined && isPhone(answer);
      if (!requiredAnswersCompleted) {
        return false;
      }
    } else if (
      question.dataType === 'Email' &&
      (question.requiredInd === 'Yes' || (answer !== undefined && answer.length > 0))
    ) {
      requiredAnswersCompleted =
        requiredAnswersCompleted && answer !== undefined && isEmail(answer);
      if (!requiredAnswersCompleted) {
        return false;
      }
    } else if (question.requiredInd === 'Yes') {
      requiredAnswersCompleted =
        requiredAnswersCompleted && answer !== undefined && answer.length > 0;
      if (!requiredAnswersCompleted) {
        return false;
      }
    }

    // sub question answers check
    if (answers[i] && answers[i].subQuestionAnswers) {
      for (let j = 0; j < answers[i].subQuestionAnswers.length; j++) {
        const subAnswer = answers[i].subQuestionAnswers[j];
        const subQuestion = additionalQuestions[i].questions.question[j];
        if (subQuestion.dataType === 'YesNo' && subQuestion.requiredInd === 'Yes') {
          if (subAnswer === undefined || subAnswer.length === 0) {
            // default or yes if not set
            setAdditionalSubQuestionsAnswers(
              i,
              j,
              question.defaultValue !== undefined ? question.defaultValue : YES_NO_DEFAULT,
            );
          }
        } else if (subQuestion.dataType === 'List' && subQuestion.requiredInd === 'Yes') {
          requiredAnswersCompleted = subAnswer !== undefined && subAnswer.length !== 0;
          if (!requiredAnswersCompleted) {
            return false;
          }
        } else if (subQuestion.dataType === 'Numeric' && subQuestion.requiredInd === 'Yes') {
          requiredAnswersCompleted =
            requiredAnswersCompleted && subAnswer !== undefined && subAnswer.length > 0;
          if (!requiredAnswersCompleted) {
            return false;
          }
        } else if (subQuestion.dataType === 'TextArea' && subQuestion.requiredInd === 'Yes') {
          requiredAnswersCompleted =
            requiredAnswersCompleted && subAnswer !== undefined && subAnswer.length > 0;
          if (!requiredAnswersCompleted) {
            return false;
          }
        } else if (subQuestion.dataType === 'Phone' && subQuestion.requiredInd === 'Yes') {
          requiredAnswersCompleted =
            requiredAnswersCompleted && subAnswer !== undefined && isPhone(subAnswer);
          if (!requiredAnswersCompleted) {
            return false;
          }
        } else if (subQuestion.dataType === 'Email' && subQuestion.requiredInd === 'Yes') {
          requiredAnswersCompleted =
            requiredAnswersCompleted && subAnswer !== undefined && isEmail(subAnswer);
          if (!requiredAnswersCompleted) {
            return false;
          }
        } else if (subQuestion.requiredInd === 'Yes') {
          requiredAnswersCompleted =
            requiredAnswersCompleted && subAnswer !== undefined && subAnswer.length > 0;
          if (!requiredAnswersCompleted) {
            return false;
          }
        }
      }
    }
  }
  return !!requiredAnswersCompleted;
};

export const getQuestionReplies = (questions, answers) => {
  const replies = [];
  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    const answer = answers[i]?.answer;
    let value;
    if (question.dataType === 'Numeric') {
      value = answer.toString();
    } else {
      value = answer;
    }

    replies.push({ name: question.name, value, visibleInd: true });
    // sub questions
    if (
      question.questions &&
      question.questions.question &&
      question.questions.question.length > 0 &&
      answers[i].subQuestionAnswers
    ) {
      for (let j = 0; j < question.questions.question.length; j++) {
        const subQuestion = question.questions.question[j];
        const subAnswer = answers[i].subQuestionAnswers[j];
        let subValue;
        if (subQuestion.dataType === 'Numeric') {
          subValue = subAnswer.toString();
        } else {
          subValue = subAnswer;
        }
        replies.push({ name: subQuestion.name, value: subValue, visibleInd: true });
      }
    }
  }
  return replies;
};
