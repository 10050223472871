import { some } from 'lodash';
import { API } from '../../../utils/api';
import { getCustomerBillingInformation } from '../dashboard/billing/billingAPI';
import { setLoading, setPayPlanOptions } from './payPlanReducer';

export const getPayPlanOptions = (policyRef, payPlanTypesToFilter) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    // when filtering out the payment plan types we remove falsy values, so we check if
    // any(lodash: some) of the pay plans name are included
    // and if they are we remove them, because lodash returns truthy we invert the value so it's filtered
    // full list of acceptable strings will be added to config readme
    return API.get(`policies/${policyRef}/paymentPlans`).then((response) => {
      let filteredPayPlanOptions = response.data.applicationPayPlanListItems.filter((plan) => {
        return !some(payPlanTypesToFilter, (payPlan) => {
          return plan.name.includes(payPlan);
        });
      });
      dispatch(setPayPlanOptions(filteredPayPlanOptions));
      dispatch(setLoading(false));
    });
  };
};
export const updatePayPlan = (
  customerId,
  body,
  notify,
  billingId,
  hasOpenBalance,
  billingAccountsCountLimit,
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return API.post(`/policies/${customerId}/updatePaymentPlanRequest`, body)
      .then(async () => {
        notify('Your payment plan has been updated', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });

        return await dispatch(
          getCustomerBillingInformation(billingId, hasOpenBalance, billingAccountsCountLimit),
        );
      })
      .catch(() => {
        notify(
          'There was an issue updating your payment plan. Please contact your insurance agent.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          },
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
