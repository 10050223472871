import { find } from 'lodash';
import styled from 'styled-components';
import Select from 'react-select';

export const getBestWayToContact = (state) => {
  const contactPhone = find(
    state.reportClaimStepThreeSlice.claimEventContacts.contact.phoneInfo,
    (phone) => phone.preferredInd === true,
  );
  if (contactPhone) {
    return contactPhone;
  } else if (
    state.reportClaimStepThreeSlice.claimEventContacts.contact.emailInfo.preferredInd === true
  ) {
    return state.reportClaimStepThreeSlice.claimEventContacts.contact.emailInfo;
  }
  return {};
};
const getMailingAddress = (addresses) => {
  const mailing = addresses.find((element) => element.addrTypeCd === 'InsuredMailingAddr');
  return mailing ? mailing : '';
};
export const getAddress = (addresses) => {
  const mailingAddress = getMailingAddress(addresses);
  return mailingAddress === '' ? '' : mailingAddress.addr1;
};
export const getMailingCity = (addresses) => {
  const mailingCity = getMailingAddress(addresses);
  return mailingCity === '' ? '' : mailingCity.city;
};
export const getMailingState = (addresses) => {
  const mailingState = getMailingAddress(addresses);
  return mailingState === '' ? '' : mailingState.stateProvCd;
};
export const getMailingZip = (addresses) => {
  const mailingZip = getMailingAddress(addresses);
  return mailingZip === '' ? '' : mailingZip.postalCode;
};
export const getPhoneNumber = (contact, temp) => {
  const phone = contact.find((element) => element.phoneTypeCd === 'InsuredPhonePrimary');
  return temp !== '' ? temp : phone ? phone.phoneNumber : '';
};
export const getPhoneType = (contact, temp) => {
  const phone = contact.find((element) => element.phoneTypeCd === 'InsuredPhonePrimary');
  return temp !== '' ? temp : phone ? phone.phoneName : '';
};
export const getBestWayToContactSelection = (contactList, bestWayToContact) => {
  return bestWayToContact ? contactList.find((contact) => contact.value === bestWayToContact) : '';
};
