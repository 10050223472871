import * as React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  AccountContainer,
  AccountLabelMobile,
  AccountLink,
  BillingDataContainer,
  BillToContainer,
  BillToLabelMobile,
  CurrentDueContainer,
  CurrentDueLabelMobile,
  DueDateContainer,
  DueDateLabelMobile,
  InvoiceDateContainer,
  InvoiceDateLabelMobile,
  PayOffAmountContainer,
  PayOffAmountLabelMobile,
  PaymentPlanContainer,
  PaymentPlanLabelMobile,
} from './styles';
import { DISPLAY_DATE_FORMAT } from '../../../common/constants';
export const BillingRow = (props) => {
  const { billingAccounts } = props;
  return (
    <>
      {billingAccounts.map((account, index) => {
        const { billingAccount } = account;
        return (
          <BillingDataContainer key={billingAccount.id}>
            <AccountContainer>
              <AccountLabelMobile>Account Number</AccountLabelMobile>
              <AccountLink
                to="/accountDetails"
                aria-label={`Account Number ${billingAccount.accountDisplayNumber}`}
                id={index === 0 ? 'skipContent' : undefined}
                state={{ accountNumber: account.ref }}
              >
                {billingAccount.accountDisplayNumber}
              </AccountLink>
            </AccountContainer>
            <PayOffAmountContainer aria-label={`Payoff Amount ${billingAccount.totalAmt}`}>
              <PayOffAmountLabelMobile>Payoff Amount</PayOffAmountLabelMobile>$
              {billingAccount.totalAmt}
            </PayOffAmountContainer>
            <BillToContainer aria-label={`Bill To ${billingAccount.billToCd}`}>
              <BillToLabelMobile>Bill To</BillToLabelMobile>
              {billingAccount.billToCd}
            </BillToContainer>
            <PaymentPlanContainer aria-label={`Payment Plan ${billingAccount.payPlan.description}`}>
              <PaymentPlanLabelMobile>Payment Plan</PaymentPlanLabelMobile>
              {billingAccount.payPlan.description}
            </PaymentPlanContainer>
            <InvoiceDateContainer
              aria-label={`Invoice Date ${
                account.invoiceDate ? moment(account.invoiceDate).format(DISPLAY_DATE_FORMAT) : ''
              }`}
            >
              <InvoiceDateLabelMobile>Invoice Date</InvoiceDateLabelMobile>
              {account.invoiceDate ? moment(account.invoiceDate).format(DISPLAY_DATE_FORMAT) : null}
            </InvoiceDateContainer>
            <CurrentDueContainer aria-label={`Current Due ${billingAccount.openAmt}`}>
              <CurrentDueLabelMobile>Current Due</CurrentDueLabelMobile>${billingAccount.openAmt}
            </CurrentDueContainer>
            <DueDateContainer
              aria-label={`Due Date ${
                account.dueDate ? moment(account.dueDate).format(DISPLAY_DATE_FORMAT) : ''
              }`}
            >
              <DueDateLabelMobile>Due Date</DueDateLabelMobile>
              {account.dueDate ? moment(account.dueDate).format(DISPLAY_DATE_FORMAT) : null}
            </DueDateContainer>
          </BillingDataContainer>
        );
      })}
    </>
  );
};
BillingRow.propTypes = {
  billingAccounts: PropTypes.array,
};
export default BillingRow;
