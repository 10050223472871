import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  AccountCreationErrorMessage,
  CheckedCircle,
  UncheckedCircle,
  CreateAccountButton,
  CreateAccountButtonContainer,
  CreateAccountContainer,
  CreateAccountHeader,
  CreateAccountLink,
  CreateAccountMessageLabel,
  CreateAccountStepOneContainer,
  CreateAccountStepTwoContainer,
  HelpText,
  PasswordConstraintMessageLabel,
  RegistrationFieldContainer,
  RegistrationFieldHeader,
  RegistrationFieldPasswordInputField,
  RegistrationFieldTextInputField,
  RequiredField,
  TermsAndConditionsContainer,
  TextLink,
  UpdateButton,
} from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getCustomersWithCrossLinkToken,
  getRegistrationFields,
  updateEmailAndRegisterCustomer,
  verifyRegistrationFields,
  registerCustomer,
} from './createAccountApi';
import Loader from '../../common/loader';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { FooterContainer, LoggedOutTemplateLogo, LogoLink } from '../login/styles';
import {
  setAccountCreationErrors,
  setAccountCreationSuccessful,
  setCrossLinkRegistrationToken,
  setErrors,
  setRegistrationToken,
} from '../login/loginReducer';
import Footer from '../dashboard/footer';
import { getPolicyInfoInputField } from '../../../utils/fields';
import { getText } from '../../../utils/i18n';
import TooltipInfo from '../../common/tooltip';
import { Modal } from '../../common/modal-with-html';
import termsHtml from '../../../config/documents/tos.html';
import privacyHtml from '../../../config/documents/privacy.html';
import { isEmail } from '../../../utils/validators/generalValidators';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

export const CreateAccount = (props) => {
  const {
    registrationFields,
    registrationToken,
    isAccountCreationSuccessful,
    crossLinkRegistrationToken,
    accountCreationErrors,
    i18n,
    companyName,
    isCustomerEmailFound,
    customPrivacyStatement,
    customTOS,
    customerEmailOnFile,
    customerId,
  } = props;

  const [answers, setAnswers] = useState({});
  const [showStepTwo, setShowStepTwo] = useState(false);
  const [passwordConstraints, setPasswordConstraints] = useState(
    props.state.configurationSlice.features.passwordConstraints,
  );
  const [userIdConstraints, setUserIdConstraints] = useState(
    props.state.configurationSlice.features.userIdConstraints,
  );

  const [isConditionsAccepted, setConditionAccepted] = useState(false);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isCreateAccountButtonSubmitting, setCreateAccountButtonSubmitting] = useState(false);
  const [termsAndConditionsDialogOpen, setTermsAndConditionsDialogOpen] = useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
  const [customerUpdateDialogOpen, setCustomerUpdateDialogOpen] = useState(false);
  const [email, setEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const areRequiredFieldsCompleted = () => {
    if (
      registrationFields.length === 0 ||
      Object.entries(answers).length !== registrationFields.length
    )
      return false;
    // eslint-disable-next-line no-unused-vars
    for (let value of Object.values(answers)) {
      if (!value.fieldComplete) return false;
    }
    return true;
  };

  const createAccountButtonClickHandler = () => {
    // map question as name and answer as value
    const registrationAnswers = [];
    for (let i = 0; i < registrationFields.length; i++) {
      registrationAnswers[i] = { name: registrationFields[i].value, value: answers[i].answer };
    }

    const payload = {
      optionKey: 'serviceportal',
      registrationInfo: registrationAnswers,
    };
    props.dispatch(verifyRegistrationFields(payload, enqueueSnackbar));
  };

  const createAccountHandler = (update) => {
    setCreateAccountButtonSubmitting(true);
    const payload = {
      registrationToken: crossLinkRegistrationToken
        ? crossLinkRegistrationToken
        : registrationToken,
      loginId: userId,
      password: password,
      emailAddr: email,
      termsAccepted: isConditionsAccepted,
    };
    if (update) {
      props.dispatch(
        updateEmailAndRegisterCustomer(
          payload,
          enqueueSnackbar,
          email,
          customerId,
          registrationToken,
        ),
      );
      setCustomerUpdateDialogOpen(false);
    } else {
      props.dispatch(registerCustomer(payload, enqueueSnackbar));
      setCustomerUpdateDialogOpen(false);
    }
  };

  const validatePassword = (passwordValue) => {
    const updatePasswordConstraints = [];
    passwordConstraints.forEach((passwordConstraint) => {
      const item = { ...passwordConstraint };
      const constraint = item.constraint;
      const value = item.value;
      switch (constraint) {
        case 'minLength':
          item.valid = passwordValue.length >= value;
          break;
        case 'minNumber':
          item.valid = (passwordValue.match(/\d/g) || []).length >= value;
          break;
        case 'minLowerCase':
          item.valid = (passwordValue.match(/[a-z]/g) || []).length >= value;
          break;
        case 'minUpperCase':
          item.valid = (passwordValue.match(/[A-Z]/g) || []).length >= value;
          break;
        default:
          item.valid = true;
          break;
      }
      updatePasswordConstraints.push(item);
    });
    setPasswordConstraints(updatePasswordConstraints);
  };

  const validateUserId = (userIdValue) => {
    const updateUserIdConstraints = [];
    userIdConstraints.forEach((userIdConstraint) => {
      const item = { ...userIdConstraint };
      const constraint = item.constraint;
      const value = item.value;
      if (constraint === 'minLength') {
        item.valid = userIdValue.length >= value;
      } else {
        item.valid = true;
      }

      updateUserIdConstraints.push(item);
    });
    setUserIdConstraints(updateUserIdConstraints);
  };

  const areAccountCreationFieldsComplete = () => {
    let completeFlag = true;
    completeFlag = completeFlag && isConditionsAccepted;
    completeFlag =
      completeFlag &&
      userIdConstraints.filter((constraint) => constraint && constraint.valid === true).length ===
        userIdConstraints.length;
    completeFlag =
      completeFlag &&
      passwordConstraints.filter((constraint) => constraint && constraint.valid === true).length ===
        passwordConstraints.length;
    completeFlag = completeFlag && password === confirmPassword;
    completeFlag = completeFlag && isEmail(email);

    return completeFlag;
  };

  const handleGotoStepOne = () => {
    props.dispatch(getRegistrationFields(enqueueSnackbar));
    props.dispatch(setRegistrationToken(null));
    props.dispatch(setCrossLinkRegistrationToken(null));
    props.dispatch(setErrors(null));
    props.dispatch(setAccountCreationErrors(null));
    setShowStepTwo(false);
    setUserId('');
    setPassword('');
    setConfirmPassword('');
    setConditionAccepted(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(getRegistrationFields(enqueueSnackbar));
    setShowStepTwo(false);
    setConditionAccepted(false);
  }, []);

  useEffect(() => {
    if (registrationToken && registrationToken.length > 0) {
      setShowStepTwo(true);
    }
  }, [registrationToken]);

  useEffect(() => {
    if (crossLinkRegistrationToken && crossLinkRegistrationToken.length > 0) {
      props.dispatch(getCustomersWithCrossLinkToken(crossLinkRegistrationToken, enqueueSnackbar));
    }
  }, [crossLinkRegistrationToken]);

  useEffect(() => {
    if (isAccountCreationSuccessful) {
      setShowStepTwo(false);
      props.dispatch(setRegistrationToken(''));
      props.dispatch(setCrossLinkRegistrationToken(''));
      props.dispatch(setAccountCreationSuccessful(undefined));
      // navigating to '/' re-renders okta widget, '/login' does not
      navigate('/');
    } else {
      setCreateAccountButtonSubmitting(false);
      props.dispatch(setAccountCreationSuccessful(undefined));
    }
  }, [isAccountCreationSuccessful]);

  // when customer email is updated but okta registration fails, clear the email
  useEffect(() => {
    if (isCustomerEmailFound) {
      setEmail(customerEmailOnFile);
    } else {
      setEmail('');
    }
  }, [isCustomerEmailFound]);

  return (
    <>
      <Helmet>
        <title>Create Account</title>
      </Helmet>
      <LogoLink to="/dashboard">
        <LoggedOutTemplateLogo
          src={`config/images/loggedout-logo.png`}
          alt={companyName + ' logo'}
        />
      </LogoLink>
      <CreateAccountContainer>
        {!showStepTwo ? (
          <CreateAccountStepOneContainer>
            <CreateAccountHeader id="createAccountStepOneHeader">
              Create Account - Step One
            </CreateAccountHeader>
            <CreateAccountMessageLabel>
              Please answer the following security questions:
            </CreateAccountMessageLabel>
            {registrationFields.length === 0 ? (
              <Loader />
            ) : (
              registrationFields.map((registrationField, index) => (
                <RegistrationFieldContainer
                  key={index}
                  id={registrationField.name + 'FieldContainer'}
                >
                  <RegistrationFieldHeader>
                    {registrationField.name}
                    <RequiredField>*</RequiredField>
                    <TooltipInfo
                      title={getText(i18n, 'createAccount.' + registrationField.name + '.Tooltip')}
                    />{' '}
                    <HelpText>
                      {getText(i18n, 'signup.helpText.' + registrationField.value)}
                    </HelpText>
                  </RegistrationFieldHeader>
                  {getPolicyInfoInputField(
                    registrationField.value,
                    index,
                    answers,
                    setAnswers,
                    'CreateAccount',
                  )}
                </RegistrationFieldContainer>
              ))
            )}
            <CreateAccountButtonContainer>
              <CreateAccountButton
                disabled={!areRequiredFieldsCompleted()}
                onClick={() => {
                  createAccountButtonClickHandler();
                }}
              >
                Register
              </CreateAccountButton>
            </CreateAccountButtonContainer>
          </CreateAccountStepOneContainer>
        ) : (
          <CreateAccountStepTwoContainer>
            {accountCreationErrors && (
              <AccountCreationErrorMessage>
                There was an error creating your account. You can start from beginning by clicking{' '}
                <span>
                  <CreateAccountLink
                    onClick={() => {
                      handleGotoStepOne();
                    }}
                  >
                    here
                  </CreateAccountLink>
                </span>
                {'.'}
              </AccountCreationErrorMessage>
            )}
            <CreateAccountHeader id="createAccountStepOneHeader">
              Create Account - Step Two
            </CreateAccountHeader>
            <CreateAccountMessageLabel>
              To create your account, please provide a User ID and password and accept the Terms of
              Service and Privacy Statement.
            </CreateAccountMessageLabel>
            <form>
              <RegistrationFieldContainer>
                <RegistrationFieldHeader>
                  User ID <TooltipInfo title={getText(i18n, 'createAccount.userIdTooltip')} />
                </RegistrationFieldHeader>
                <RegistrationFieldTextInputField
                  id="userId"
                  onChange={(e) => {
                    validateUserId(e.target.value);
                    setUserId(e.target.value);
                  }}
                  autoComplete="username"
                />
              </RegistrationFieldContainer>
              {userId.length > 0 &&
                userIdConstraints &&
                userIdConstraints.map((constraint) => (
                  <PasswordConstraintMessageLabel key={constraint.constraint}>
                    {constraint.message}
                  </PasswordConstraintMessageLabel>
                ))}
              <RegistrationFieldContainer>
                <RegistrationFieldHeader>
                  Password <TooltipInfo title={getText(i18n, 'createAccount.passwordTooltip')} />
                </RegistrationFieldHeader>
                <RegistrationFieldPasswordInputField
                  id="password"
                  onChange={(e) => {
                    validatePassword(e.target.value);
                    setPassword(e.target.value);
                  }}
                  autoComplete="new-password"
                />
                {password.length > 0 &&
                  passwordConstraints &&
                  passwordConstraints.map((constraint) => (
                    <PasswordConstraintMessageLabel key={constraint.constraint}>
                      {constraint.message}
                    </PasswordConstraintMessageLabel>
                  ))}
              </RegistrationFieldContainer>
              <RegistrationFieldContainer>
                <RegistrationFieldHeader>
                  Confirm Password{' '}
                  <TooltipInfo title={getText(i18n, 'createAccount.confirmPasswordTooltip')} />
                </RegistrationFieldHeader>
                <RegistrationFieldPasswordInputField
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="new-password"
                />
                <PasswordConstraintMessageLabel>
                  {confirmPassword.length > 0 && (
                    <>
                      {password === confirmPassword ? <CheckedCircle /> : <UncheckedCircle />}{' '}
                      Passwords must match
                    </>
                  )}
                </PasswordConstraintMessageLabel>
              </RegistrationFieldContainer>
              <RegistrationFieldContainer>
                <RegistrationFieldHeader>
                  Registration Email{' '}
                  <TooltipInfo title={getText(i18n, 'createAccount.emailTooltip')} />
                </RegistrationFieldHeader>
                <RegistrationFieldTextInputField
                  id="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  autoComplete="email"
                  value={email}
                />
              </RegistrationFieldContainer>
            </form>
            <TermsAndConditionsContainer>
              <input
                type="checkbox"
                checked={isConditionsAccepted}
                onChange={() => {
                  setConditionAccepted(!isConditionsAccepted);
                }}
              />
              <span>
                {' '}
                I have read and accept the{' '}
                <TextLink
                  onClick={() => {
                    setTermsAndConditionsDialogOpen(true);
                  }}
                >
                  {getText(i18n, 'general.termsOfServiceTitle', 'Terms of Service')}
                </TextLink>{' '}
                and{' '}
                <TextLink
                  onClick={() => {
                    setPrivacyDialogOpen(true);
                  }}
                >
                  {getText(i18n, 'general.privacyStatementTitle', 'Privacy Statement')}
                </TextLink>
                .
                <Modal
                  modalHtml={termsHtml}
                  dialogOpen={termsAndConditionsDialogOpen}
                  customModal={customTOS}
                  modalTitle={getText(i18n, 'general.termsOfServiceTitle', 'Terms of Service')}
                  setDialogClosed={() => setTermsAndConditionsDialogOpen(false)}
                />
                <Modal
                  modalHtml={privacyHtml}
                  dialogOpen={privacyDialogOpen}
                  customModal={customPrivacyStatement}
                  modalTitle={getText(i18n, 'general.privacyStatementTitle', 'Privacy Statement')}
                  setDialogClosed={() => setPrivacyDialogOpen(false)}
                />
                <Dialog
                  open={customerUpdateDialogOpen}
                  onClose={() => setCustomerUpdateDialogOpen(false)}
                  aria-labelledby="confirm-dialog"
                >
                  <DialogContent>
                    <h3>
                      {getText(
                        i18n,
                        'signup.customerEmailUpdate.title',
                        'Update customer email address with registration email address?',
                      )}
                    </h3>
                    <span>
                      {getText(
                        i18n,
                        'signup.customerEmailUpdate.body',
                        'The email address you have entered is different from the email address for the customer in our records. Would you like to update the customer email address with the registration email address you entered?',
                      )}
                    </span>
                    <br />
                    <DialogActions>
                      <UpdateButton
                        id="NoButton"
                        onClick={() => {
                          createAccountHandler(false);
                        }}
                        variant="contained"
                      >
                        No
                      </UpdateButton>
                      <UpdateButton
                        id="YesButton"
                        onClick={() => createAccountHandler(true)}
                        variant="contained"
                      >
                        Yes
                      </UpdateButton>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              </span>
            </TermsAndConditionsContainer>
            <CreateAccountButtonContainer>
              {isCreateAccountButtonSubmitting ? (
                <Loader />
              ) : (
                <CreateAccountButton
                  disabled={!areAccountCreationFieldsComplete()}
                  onClick={() => {
                    if (email !== customerEmailOnFile) {
                      setCustomerUpdateDialogOpen(true);
                    } else {
                      createAccountHandler(false);
                    }
                  }}
                >
                  Create Account
                </CreateAccountButton>
              )}
            </CreateAccountButtonContainer>
          </CreateAccountStepTwoContainer>
        )}
      </CreateAccountContainer>

      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  );
};
CreateAccount.propTypes = {
  dispatch: PropTypes.any,
  registrationFields: PropTypes.array,
  registrationToken: PropTypes.string,
  crossLinkRegistrationToken: PropTypes.string,
  isAccountCreationSuccessful: PropTypes.bool,
  accountCreationErrors: PropTypes.string,
  state: PropTypes.object,
  i18n: PropTypes.object,
  companyName: PropTypes.string,
  isCustomerEmailFound: PropTypes.bool,
  customPrivacyStatement: PropTypes.bool,
  customTOS: PropTypes.bool,
  customerEmailOnFile: PropTypes.string,
  customerId: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  registrationFields: state.loginSlice.registrationFields,
  accountCreationErrors: state.loginSlice.accountCreationErrors,
  registrationToken: state.loginSlice.registrationToken,
  crossLinkRegistrationToken: state.loginSlice.crossLinkRegistrationToken,
  isAccountCreationSuccessful: state.loginSlice.isAccountCreationSuccessful,
  i18n: state.i18nSlice.i18n,
  companyName: state.configurationSlice.features.companyName,
  customPrivacyStatement: state.configurationSlice.features.features.customPrivacyStatement,
  customTOS: state.configurationSlice.features.features.customTOS,
  isCustomerEmailFound: state.loginSlice.isCustomerEmailFound,
  customerEmailOnFile: state.loginSlice.customerEmailOnFile,
  customerId: state.loginSlice.customerId,
  state,
});

export default connect(mapStateToProps)(CreateAccount);
