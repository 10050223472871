export const getAdditionalQuestions = (template) => {
  try {
    return template.policyChangeSections[0].questions[0].question;
  } catch (e) {
    return [];
  }
};

export const getAllQuestionReplies = (questions, answers) => {
  let replies = [];
  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    const answer = answers[i].answer;
    let value;
    if (question.dataType === 'Numeric') {
      value = answer.toString();
    } else {
      value = answer;
    }
    replies.push({
      name: question.name,
      value,
      visibleInd: true,
    });

    // sub questions
    if (
      question.questions &&
      question.questions.question &&
      question.questions.question.length > 0 &&
      answers[i].subQuestionAnswers
    ) {
      for (let j = 0; j < question.questions.question.length; j++) {
        const subQuestion = question.questions.question[j];
        const subAnswer = answers[i].subQuestionAnswers[j];
        let subValue;
        if (subQuestion.dataType === 'YesNo') {
          subValue = subAnswer;
        } else if (subQuestion.dataType === 'Numeric') {
          subValue = subAnswer.toString();
        } else if (subQuestion.dataType === 'TextArea') {
          subValue = subAnswer;
        }
        replies.push({
          name: subQuestion.name,
          value: subValue,
          visibleInd: true,
        });
      }
    }
  }
  return replies;
};
export const filterReplies = (questions) => {
  let replies = questions.filter((r) => {
    switch (r.name) {
      case 'DriverFirstName': {
        return false;
      }
      case 'DriverLastName': {
        return false;
      }
      case 'DriverMiddleName': {
        return false;
      }
      case 'Reason': {
        return false;
      }
      case 'Birthdate': {
        return false;
      }
      case 'Gender': {
        return false;
      }
      case 'MaritalStatus': {
        return false;
      }
      case 'RelationshipToInsured': {
        return false;
      }
      case 'DateLicensed': {
        return false;
      }
      case 'StateProvince': {
        return false;
      }
      case 'LicenseNumber': {
        return false;
      }
      case 'EffectiveDate': {
        return false;
      }
      default:
        return true;
    }
  });
  return replies;
};
