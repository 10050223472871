import * as React from 'react';
import { classes, Main, Toolbar } from '../mobile/styles';
import { DashboardContentArea } from '../desktop/styles';
import PropTypes from 'prop-types';
import Routes from './routes';

export const Routing = props => {
  const { isMobile } = props;
  return (
    <>
      {isMobile ? (
        <Main className={classes.content} style={{ paddingBottom: '10px', marginBottom: '10px' }}>
          {/* marginBottom is to help show components that fall below the footer  */}
          <Toolbar className={classes.toolbar}>
            <Routes isMobile={isMobile} />
          </Toolbar>
        </Main>
      ) : (
        <DashboardContentArea>
          <Routes isMobile={isMobile} />
        </DashboardContentArea>
      )}
    </>
  );
};
Routing.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Routing;
