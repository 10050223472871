import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  amountToPay: undefined,
  instrumentToPayWith: null,
  payDate: null,
  fundingInstrumentError: '',
  fundingInstruments: [],
  isLoading: false,
  radioAmountType: -1,
  routingNumberError: '',
  bankInformation: null,
};

export const makePaymentStepTwoSlice = createSlice({
  name: 'makePaymentStepTwoSlice',
  initialState,
  reducers: {
    resetStepTwoState: () => {
      return initialState;
    },
    setPaymentAmountToPay: (state, action) => {
      return { ...state, amountToPay: action.payload };
    },
    setPaymentInstrument: (state, action) => {
      return { ...state, instrumentToPayWith: action.payload };
    },
    setPayDate: (state, action) => {
      return { ...state, payDate: action.payload };
    },
    setFundingInstrumentError: (state, action) => {
      return { ...state, fundingInstrumentError: action.payload };
    },
    setFundingInstruments: (state, action) => {
      return { ...state, fundingInstruments: action.payload };
    },
    setIsLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setRadioAmountType: (state, action) => {
      return { ...state, radioAmountType: action.payload };
    },
    setRoutingNumberError: (state, action) => {
      return { ...state, routingNumberError: action.payload };
    },
    setBankInformation: (state, action) => {
      return { ...state, bankInformation: action.payload };
    },
  },
});
export const {
  resetStepTwoState,
  setIsLoading,
  setPaymentAmountToPay,
  setPaymentInstrument,
  setPayDate,
  setFundingInstrumentError,
  setFundingInstruments,
  setRoutingNumberError,
  setRadioAmountType,
  setBankInformation,
} = makePaymentStepTwoSlice.actions;
export default makePaymentStepTwoSlice.reducer;
