import { MULTI_PART_API } from '../../../../utils/api';
import { cloneDeep } from 'lodash';
import { TOKENIZED_API } from '../../../../utils/api';
import {
  setClaimEventTemplates,
  setClaimEventValidated,
  setValidateClaim,
} from './anonymousReportClaimStepFiveReducer';
import {
  setClaimEventTemplatesStepSix,
  setClaimEventValidationErrors,
  setClaimEventValidatedStepSix,
  setUploadFilesLabel,
  setValidateClaimStepSix,
} from '../recap-and-submit/anonymouseReportClaimStepSixReducer';
import { setNewPhones } from '../step4/anonymousReportClaimStepFourReducer';
import { recurseQuestionReplies } from '../../report-claim/report-claim-step-four/utils';

const getReportClaimBody = (props, question, filenames) => {
  let getReplies = (event) => {
    let answers = [];
    let questions = cloneDeep(event);
    return recurseQuestionReplies(questions, answers);
  };

  // deep copy phone info to new object since it is read only
  let phoneInfoList = JSON.parse(
    JSON.stringify(
      props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.phoneInfo,
    ),
  );

  let updatedPersonInfo = props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact
    .personInfo
    ? JSON.parse(
        JSON.stringify(
          props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.personInfo,
        ),
      )
    : {};

  // setting bestWayToContact and phone info i.e. phoneNumber, type and preferredInd
  let phoneInfoPrimary = phoneInfoList[0];
  let bestWayToContact = props.state.anonymousReportClaimStepFourSlice.bestWayToContact;
  let phoneNumber = props.state.anonymousReportClaimStepFourSlice.phoneTemp;
  if (phoneNumber) {
    phoneInfoPrimary.phoneNumber = phoneNumber;
    let phoneType = props.state.anonymousReportClaimStepFourSlice.phoneType;
    phoneInfoPrimary.phoneName = phoneType.value;
    if (bestWayToContact === 'Phone') {
      phoneInfoPrimary.preferredInd = true;
      updatedPersonInfo.bestWayToContact = phoneInfoPrimary.phoneName;
    }
  }
  props.dispatch(setNewPhones(phoneInfoList));

  let finalBody = {
    id: props.state.anonymousReportClaimStepThreeSlice.claimEvent.id,
    sourceCd: 'ServicePortal_v4.0.0',
    customerNumber: props.state.anonymousReportClaimStepThreeSlice.claimEvent.customerNumber,
    eventCd: props.state.anonymousReportClaimStepThreeSlice.claimEvent.eventCd,
    accidentInd: props.state.anonymousReportClaimStepThreeSlice.claimEvent.accidentInd,
    lossDt: props.state.anonymousReportClaimStepThreeSlice.dateValue,
    lossTm: props.state.anonymousReportClaimStepThreeSlice.timeValue,
    addr: props.state.anonymousReportClaimStepThreeSlice.addr,
    detailDescription: props.state.anonymousReportClaimStepThreeSlice.descriptionText,
    policyNumber: props.state.anonymousReportClaimStepFourSlice.claimEventContacts.policyNumber,
    templateIdRef: props.state.anonymousReportClaimStepFourSlice.claimEventContacts.templateIdRef,
    contact: {
      id: props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.id,
      partyTypeCd:
        props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.partyTypeCd,
      personInfo: updatedPersonInfo,
      emailInfo: props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.emailInfo,
      phoneInfo: phoneInfoList,
      nameInfo: props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.nameInfo
        ? props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.nameInfo
        : {},
      addresses: props.state.anonymousReportClaimStepFourSlice.claimEventContacts.contact.addresses,
    },
    questionReplies: {
      id: 'QuestionReplies-1',
      questionReply: getReplies(question),
    },
    eventSections: [
      {
        attachments: filenames,
      },
    ],
  };

  if (props.state.anonymousReportClaimStepTwoSlice.selectedClaimEventTemplate.view === 'Location') {
    // if HO policy, we use location
    finalBody.riskIdRef = props.state.anonymousReportClaimStepThreeSlice.selectedLocation.id;
  } else if (
    props.state.anonymousReportClaimStepTwoSlice.selectedClaimEventTemplate.view === 'Vehicle'
  ) {
    // if PA policy, we user driver and vehicle info
    const selectedDriver = props.state.anonymousReportClaimStepThreeSlice.selectedDriver;
    const nameInfo = selectedDriver.partyInfo.nameInfo;
    finalBody.driverIdRef = selectedDriver.value;
    finalBody.driverName =
      props.state.anonymousReportClaimStepThreeSlice.manualDriverName !== ''
        ? props.state.anonymousReportClaimStepThreeSlice.manualDriverName
        : `${nameInfo.givenName} ${nameInfo.surname}`;
    finalBody.driverLicense =
      props.state.anonymousReportClaimStepThreeSlice.manualLicenseNumber !== ''
        ? props.state.anonymousReportClaimStepThreeSlice.manualLicenseNumber
        : selectedDriver.partyInfo.driverInfo.licenseNumber;
    finalBody.vehicleIdRef = props.state.anonymousReportClaimStepThreeSlice.selectedVehicle.id;
    finalBody.permissiveDriverRelationshipToInsuredCd =
      props.state.anonymousReportClaimStepThreeSlice.driverRelationshipSelected;
  }

  return finalBody;
};
export const validateReportClaim = async (body, customerToken, enqueueSnackbar, dispatch) => {
  return TOKENIZED_API.post(`/claimEvents?validateOnly=true&customerToken=${customerToken}`, body)
    .then(() => {
      dispatch(setClaimEventValidated(true));
      dispatch(setClaimEventValidatedStepSix(true));
    })
    .catch((err) => {
      dispatch(
        setClaimEventValidationErrors(
          `We are unable to validate your claim at this time, please contact your provider for assistance. ${err?.message}`,
        ),
      );
      enqueueSnackbar('Your request cannot be processed at this time.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    });
};
const createFinalRequestBodyAndValidateClaim = async (
  props,
  question,
  response,
  dispatch,
  customerToken,
  enqueueSnackbar,
) => {
  let finalBody = await getReportClaimBody(props, question, response);
  dispatch(setClaimEventTemplates(question));
  dispatch(setClaimEventTemplatesStepSix(question));
  dispatch(setValidateClaim(finalBody));
  dispatch(setValidateClaimStepSix(finalBody));
  await validateReportClaim(finalBody, customerToken, enqueueSnackbar, dispatch);
};
export const uploadFilesAndReview = (files, question, props, customerToken, enqueueSnackbar) => {
  return async (dispatch) => {
    dispatch(setClaimEventValidated(false));
    dispatch(setClaimEventValidatedStepSix(false));
    if (files.length > 0) {
      dispatch(setUploadFilesLabel(true));
      let fd = new FormData();
      let promises = files.map((file) => {
        fd.append(file.name, file);
        return MULTI_PART_API.post('multifileupload', fd)
          .then(() => {
            return {
              description: 'Pictures of the Damage',
              templateId: file.templateId,
              requiredInd: false,
              fileName: file.name,
              id: file.id,
            };
          })
          .catch((err) => {
            dispatch(
              setClaimEventValidationErrors(
                `You Claim Event cannot be validated at this time, please contact your provider for assistance. ${err?.message}`,
              ),
            );
            console.log(err);
            enqueueSnackbar('Your request cannot be processed at this time.', {
              variant: 'error',
              anchorOrigin: {
                horizontal: 'center',
                vertical: 'top',
              },
            });
          });
      });
      await Promise.all(promises)
        .then(async (response) => {
          await createFinalRequestBodyAndValidateClaim(
            props,
            question,
            response,
            dispatch,
            customerToken,
          );
        })
        .catch(() => {
          enqueueSnackbar('Your request cannot be processed at this time.', {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          });
        });
    } else {
      await createFinalRequestBodyAndValidateClaim(
        props,
        question,
        [],
        dispatch,
        customerToken,
        enqueueSnackbar,
      );
    }
  };
};
