import { TOKENIZED_API } from '../../../../utils/api';
import {
  setConfirmationNumber,
  setErrors,
  setIsLoading,
  setNextButtonClicked,
  setResponse,
} from '../makePaymentAnonymousReducer';
import { gaEvent } from '../../../../utils/google-analytics';
import { ga4Event } from '../../../../utils/google-analytics-4';
// import moment from 'moment';
// import { ISO_DATE_FORMAT } from '../../../common/constants';

export const makePayment = (systemId, body, token) => {
  return async (dispatch) => {
    dispatch(setNextButtonClicked(false));
    // Only statement account requires payment spread.
    // Since this is controlled by IN API, we can always send in "autoSpread=true".
    // If we ever add schedulePaymentRequest Api permission to do anon flow we can enable if else block below.
    // if (payDate === moment().format(ISO_DATE_FORMAT)) {
    TOKENIZED_API.post(
      `/billingAccounts/${systemId}/paymentRequest?autoSpread=true&customerToken=${token}`,
      body,
    )
      .then((response) => {
        if (response.status === 202) {
          // google-analytics event for payment
          ga4Event({
            category: 'make-a-payment',
            action: 'payment_complete',
            label: 'anonymous_payment',
          });
          dispatch(setResponse(body));
          if (body.paymentMethod === 'Credit Card') {
            // /billingHistories call is here
            TOKENIZED_API.get(`${response.headers.link}?customerToken=${token}`)
              .then((response) => {
                dispatch(setConfirmationNumber(response.data.transactionId));
              })
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((err) => {
        ga4Event({
          category: 'make-a-payment-failure',
          action: 'payment_failed',
          label: 'anonymous_payment',
        });
        console.log(err);
        dispatch(setErrors('error'));
      })
      .finally(() => {
        dispatch(setIsLoading(false));
        gaEvent({ category: 'make-a-payment', action: 'complete' });
      });
    // } else {
    //   // SP v3 doesn't spread payment for scheduled payments, which may be wrong.
    //   // We can keep consistency with SP v3 for now until the customers raise concerns.
    //   TOKENIZED_API
    //     .post(`/billingAccounts/${systemId}/scheduledPaymentRequest`, body)
    //     .then(response => {
    //       if (response.status === 202) {
    //         dispatch(setResponse(body));
    //       }
    //     })
    //     .catch(err => {
    //       const errMsg = err?.response?.data?.message ? err.response.data.message : 'Error';
    //       dispatch(setErrors(errMsg));
    //     })
    //     .finally(() => {
    //       gaEvent({ category: 'make-a-payment', action: 'complete' });
    //       dispatch(setIsLoading(false));
    //     });
    // }
  };
};
