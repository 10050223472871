import uniqBy from 'lodash/uniqBy';

export const getLocations = (claimEvent) => {
  const risks = (claimEvent || {}).risks || [];
  return risks.map(function (risk) {
    let location = {};
    if (risk.address) {
      const address = risk.address[0];
      location.label =
        risk.policyNumber +
        ' - ' +
        address.addr1 +
        ' ' +
        (address.addr2 || '') +
        (address.addr2 ? ' ' : '') +
        address.city +
        ', ' +
        address.stateProvCd +
        ' ' +
        address.postalCode;
      location.addressOnFile = true;
      location.value = address.id;
      location.riskAddress = address;
    } else {
      location.label = risk.policyNumber + ' - ' + risk.description;
      location.value = risk.id;
    }
    location = { ...location, ...risk };
    return location;
  });
};

export const getDrivers = (claimEvent) => {
  const drivers = (claimEvent || {}).drivers || [];

  const driverList = drivers.map((driver) => {
    let thisDriver = {};
    if (driver.partyInfo.nameInfo.surname) {
      thisDriver.label =
        driver.policyNumber +
        ' - ' +
        driver.partyInfo.nameInfo.givenName +
        ' ' +
        driver.partyInfo.nameInfo.surname;
    } else {
      thisDriver.label = driver.policyNumber + ' - ' + driver.partyInfo.nameInfo.givenName;
    }
    thisDriver.value = driver.partyInfo.id;
    return { ...thisDriver, ...driver };
  });

  driverList.sort((driver) => (driver.partyInfo.partyTypeCd === 'DriverParty' ? -1 : 1));
  return uniqBy(driverList, 'value');
};

export const getVehicles = (claimEvent) => {
  const risks = (claimEvent || {}).risks || [];

  return risks.map(function (risk) {
    let location = {};
    location.label = `${risk.policyNumber} - ${risk.description}`;
    location.value = risk.id;
    location = { ...location, ...risk };
    return location;
  });
};
