import React, { useState } from 'react';
import {
  AddCoverageCheckboxField,
  AddCoverageTextAreaField,
  AddDateField,
  AddCoverageTextInputField,
  AddCoverageField,
  AddCoverageFieldHeader,
  AddCoverageNumericInputField,
  RequiredField,
  StyledSelect,
} from './styles';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AdditionSubQuestionField from './additional-sub-question-field';
import { YES_NO_DEFAULT } from './selector';
import { getDeductibles } from '../selector';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import moment from 'moment';

const yesNoOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const AdditionQuestionField = (props) => {
  const { id, q, index, setAdditionalQuestionsAnswers, setAdditionalSubQuestionsAnswers } = props;
  const yesNoInitialState = q.defaultValue || YES_NO_DEFAULT;
  const [selectedYesNoValue, setSelectedYesNoValue] = useState({
    label: yesNoInitialState,
    value: yesNoInitialState,
  });
  const [textInputValue, setInputValue] = useState('');
  const [selectedListValue, setSelectedListValue] = useState({ label: '-- Select --', value: '' });
  const [numericInputValue, setNumericInputValue] = useState('');
  const [dateChoice, setDateChoice] = useState('');
  const [checkboxValue, setCheckboxValue] = useState('No');
  const listOptions = q.constraints ? getDeductibles(q.constraints) : [];
  const getInputField = (type) => {
    let input;
    switch (type) {
      case 'Text':
        input = (
          <AddCoverageTextInputField
            id={id}
            value={textInputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setAdditionalQuestionsAnswers(index, e.target.value);
            }}
          ></AddCoverageTextInputField>
        );
        break;
      case 'TextArea':
        input = (
          <AddCoverageTextAreaField
            id={id}
            value={textInputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setAdditionalQuestionsAnswers(index, e.target.value);
            }}
          ></AddCoverageTextAreaField>
        );
        break;
      case 'Date':
        input = (
          <AddDateField
            id={id}
            value={dateChoice}
            onChange={(e) => {
              setDateChoice(() => {
                return e.target.value;
              });
              setAdditionalQuestionsAnswers(index, e.target.value);
            }}
          />
        );
        break;
      case 'YesNo':
        input = (
          <StyledSelect
            id={id}
            options={yesNoOptions}
            value={selectedYesNoValue}
            onChange={(selectedValue) => {
              setSelectedYesNoValue(selectedValue);
              setAdditionalQuestionsAnswers(index, selectedValue.value);
            }}
          />
        );
        break;
      case 'List':
        input = (
          <StyledSelect
            id={id}
            options={listOptions}
            value={selectedListValue}
            onChange={(selectedValue) => {
              setSelectedListValue(selectedValue);
              setAdditionalQuestionsAnswers(index, selectedValue.value);
            }}
          />
        );
        break;
      case 'Numeric':
        input = (
          <AddCoverageNumericInputField
            id={id}
            value={numericInputValue}
            onChange={(e) => {
              setAdditionalQuestionsAnswers(index, e.target.value);
              setNumericInputValue(e.target.value);
            }}
          />
        );
        break;
      default:
        input = null;
    }
    if (q.name === 'DriverCertifyQA') {
      input = (
        <AddCoverageCheckboxField
          id={id}
          value={checkboxValue}
          onChange={(e) => {
            setCheckboxValue(checkboxValue === 'No' ? 'Yes' : 'No');
            setAdditionalQuestionsAnswers(index, checkboxValue === 'No' ? 'Yes' : 'No');
          }}
        />
      );
    }
    return input;
  };

  return (
    <AddCoverageField key={q.name}>
      <AddCoverageFieldHeader>
        {q.text ? q.text : q.shortDesc ? q.shortDesc : null}
        {q.requiredInd === 'Yes' && <RequiredField>*</RequiredField>}
      </AddCoverageFieldHeader>
      {getInputField(q.dataType)}
      {q.questions &&
        q.questions.question &&
        q.questions.question.map((subQuestion, subIndex) => (
          <div key={subIndex}>
            {selectedYesNoValue.value === subQuestion.showOnParentValue && (
              <AdditionSubQuestionField
                id={id}
                q={subQuestion}
                index={subIndex}
                parentIndex={index}
                setAdditionalSubQuestionsAnswers={setAdditionalSubQuestionsAnswers}
              />
            )}
          </div>
        ))}
    </AddCoverageField>
  );
};
AdditionQuestionField.propTypes = {
  id: PropTypes.string,
  q: PropTypes.object,
  index: PropTypes.number,
  setAdditionalQuestionsAnswers: PropTypes.func,
  setAdditionalSubQuestionsAnswers: PropTypes.func,
};

const mapStateToProps = (state) => ({
  state,
});

export default connect(mapStateToProps)(AdditionQuestionField);
