import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeStep: 0,
  accountInfo: {},
  amountToPay: undefined,
  acceptedPendingPaymentMessage: false,
  bankInformation: null,
  billSelected: {},
  confirmationNumber: null,
  error: null,
  fundingInstrumentError: '',
  fundingInstruments: [],
  isLoading: false,
  instrumentToPayWith: null,
  makePaymentStarted: false,
  nextButtonClicked: false,
  nextButtonDisabled: true,
  payDate: null, //in case we add scheduled payment dates
  paymentConfigId: null,
  radioAmountType: -1,
  response: null,
  routingNumberError: '',
};

export const makePaymentAnonymousSlice = createSlice({
  name: 'makePaymentAnonymousSlice',
  initialState,
  reducers: {
    resetMakePaymentAnonymous: () => {
      return initialState;
    },
    setActiveStep: (state, action) => {
      return { ...state, activeStep: action.payload };
    },
    setAmountToPay: (state, action) => {
      return { ...state, amountToPay: action.payload };
    },
    setBillSelected: (state, action) => {
      return { ...state, billSelected: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, error: action.payload };
    },
    setPayDate: (state, action) => {
      return { ...state, payDate: action.payload };
    },
    setIsLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setMakePaymentStarted: (state, action) => {
      return { ...state, makePaymentStarted: action.payload };
    },
    setNextButtonClicked: (state, action) => {
      return { ...state, nextButtonClicked: action.payload };
    },
    setNextButtonDisabled: (state, action) => {
      return { ...state, nextButtonDisabled: action.payload };
    },
    setInstrumentToPayWith: (state, action) => {
      return { ...state, instrumentToPayWith: action.payload };
    },
    setRadioAmountType: (state, action) => {
      return { ...state, radioAmountType: action.payload };
    },
    setResponse: (state, action) => {
      return { ...state, response: action.payload };
    },
    setPaymentConfigId: (state, action) => {
      return { ...state, paymentConfigId: action.payload };
    },
    setConfirmationNumber: (state, action) => {
      return { ...state, confirmationNumber: action.payload };
    },
    setPaymentInstrument: (state, action) => {
      return { ...state, instrumentToPayWith: action.payload };
    },
    setFundingInstruments: (state, action) => {
      return { ...state, fundingInstruments: action.payload };
    },
    setFundingInstrumentError: (state, action) => {
      return { ...state, fundingInstrumentError: action.payload };
    },
    setBankInformation: (state, action) => {
      return { ...state, bankInformation: action.payload };
    },
    setRoutingNumberError: (state, action) => {
      return { ...state, routingNumberError: action.payload };
    },
  },
});
export const {
  resetMakePaymentAnonymous,
  setActiveStep,
  setAmountToPay,
  setBankInformation,
  setBillSelected,
  setConfirmationNumber,
  setErrors,
  setFundingInstrumentError,
  setFundingInstruments,
  setIsLoading,
  setMakePaymentStarted,
  setNextButtonClicked,
  setNextButtonDisabled,
  setInstrumentToPayWith,
  setPaymentInstrument,
  setPayDate,
  setRadioAmountType,
  setRoutingNumberError,
  setPaymentConfigId,
  setResponse,
} = makePaymentAnonymousSlice.actions;
export default makePaymentAnonymousSlice.reducer;
