import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getLineCd } from './selector';
import {
  AddMortgageeButton,
  AddMortgageeButtonsContainer,
  AddMortgageePropertyComponent,
  MortgageeInterestTypesList,
  AddMortgageeRadioButton,
  AddMortgageeStateSelectorContainer,
  AddMortgageeSubHeader,
  AddMortgageeTextAreaField,
  AddMortgageeTextInputField,
  AddPropertyMortgageeContainer,
  AddPropertyMortgageeField,
  AddPropertyMortgageeFieldHeader,
  EscrowContainer,
  RadioAddressLabel,
  RequiredField,
  TwoColumnContainer,
  LinkStyleButton,
} from './styles';
import { ISO_DATE_FORMAT } from '../../../../common/constants';

import {
  getBuildingRisks,
  getMortgageeInterestTypes,
  submitPolicyChange,
} from '../../policyChangeAPI';
import { setBuildingRisks, setPolicyChangeCoverageRecapFields } from '../../policyChangeReducer';
import { createField, getAddress } from '../selector';
import Loader from '../../../../common/loader';
import { Spacer, StyledLoaderContainer } from '../../styles';
import Addresses from '../../../../common/addresses';
import { setAddressFields } from '../../../../common/addresses/addressUtils';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useNavigate } from 'react-router';

export const AddPropertyMortgagee = (props) => {
  const {
    customerId,
    mortgageeInterestTypes,
    selectedPolicyChangeTemplate,
    selectedPolicy,
    buildingRisks,
    isBuildingRisksLoading,
    i18n,
    lineCd,
  } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);

  // local state
  const [selectedBuildingRiskId, setSelectedBuildingRiskId] = useState(null);
  const [showAddPropertyMortgageePageTwo, setShowAddPropertyMortgageePageTwo] = useState(false);
  const [reason, setReason] = useState('');
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [name, setName] = useState('');
  const [stateOrProvince, setStateOrProvince] = useState({
    label: '-- Select a State --',
    value: 'default',
  });
  const [zip, setZip] = useState('');
  const [interestType, setInterestType] = useState({
    label: '-- Select a Type --',
    value: 'default',
  });
  const [loanNumber, setLoanNumber] = useState('');
  const [isEscrowed, setIsEscrowed] = useState(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(createField('Reason for Change', reason, 'text'));
    fields.push(createField('Name', name, 'text'));
    fields.push(createField('Property', getAddress(selectedBuildingRiskId, buildingRisks), 'text'));
    fields.push(createField('Address', address, 'text'));
    fields.push(createField('City', city, 'text'));
    fields.push(createField('State', stateOrProvince.value, 'text'));
    fields.push(createField('ZIP', zip, 'text'));
    fields.push(createField('Type', interestType.value, 'text'));
    fields.push(createField('Loan Number', loanNumber, 'text'));
    fields.push(createField('Premium Escrowed', isEscrowed ? 'Yes' : 'No', 'text'));
    return fields;
  };

  const onNextButtonClicked = () => {
    window.scrollTo(0, 0);
    setShowAddPropertyMortgageePageTwo(true);
  };

  const getBuildById = (id) => {
    const addr = buildingRisks.find((building) => building.id === id);
    return { id, addr: [addr] };
  };

  const onSubmitButtonClicked = () => {
    setIsSubmitting(true);
    setIsSubmitButtonEnabled(false);

    // create payload
    const payload = {
      changeDt: effectiveDate,
      templateIdRef: selectedPolicyChangeTemplate.id,
      customerRef: customerId,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
          ai: [
            {
              partyInfo: {
                addresses: [
                  {
                    addr1: address,
                    city: city,
                    stateProvCd: stateOrProvince.value,
                    postalCode: zip,
                  },
                ],
              },
              interestName: name,
              interestTypeCd: interestType.value,
              accountNumber: loanNumber,
              escrowInd: isEscrowed,
            },
          ],
          building: [getBuildById(selectedBuildingRiskId)],
        },
      ],
      sourceCd: 'ServicePortal',
    };

    // set fields for recap/result
    props.dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));

    // submit API call
    props.dispatch(submitPolicyChange(payload));
  };

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    const areRequiredFieldsCompleted =
      reason.length > 0 &&
      name.length > 0 &&
      address &&
      address.length > 0 &&
      city &&
      city.length > 0 &&
      stateOrProvince &&
      stateOrProvince.value &&
      stateOrProvince.value.length > 0 &&
      zip &&
      zip.length > 0 &&
      interestType.value &&
      interestType.value.length > 0 &&
      loanNumber.length > 0;
    if (areRequiredFieldsCompleted !== isSubmitButtonEnabled) {
      setIsSubmitButtonEnabled(areRequiredFieldsCompleted);
    }
  };

  checkRequiredFields();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!effectiveDate) {
      setEffectiveDate(todayDt);
    }

    props.dispatch(getMortgageeInterestTypes());

    if (selectedPolicy && selectedPolicy.value && selectedPolicy.value !== 'default') {
      props.dispatch(getBuildingRisks(selectedPolicy.value, lineCd));
    } else {
      props.dispatch(setBuildingRisks([]));
    }

    // TODO reset reducer to default
  }, []);

  const handlePlaceSelect = (address) => {
    setAddressFields(address, setAddress, setCity, setZip, setStateOrProvince);
  };

  const navigate = useNavigate();

  return (
    <>
      {showAddPropertyMortgageePageTwo ? (
        <AddPropertyMortgageeContainer>
          <AddPropertyMortgageeFieldHeader>Property Information</AddPropertyMortgageeFieldHeader>
          <AddPropertyMortgageeFieldHeader>
            Reason for Change <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addPropertyMortgagee.reasonTooltip')}
            />
          </AddPropertyMortgageeFieldHeader>
          <AddMortgageeTextAreaField
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
          <AddMortgageeSubHeader>New Mortgagee Information</AddMortgageeSubHeader>
          <AddPropertyMortgageeFieldHeader>
            Name <RequiredField>*</RequiredField>
            <TooltipInfo title={getText(i18n, 'changeCoverage.addPropertyMortgagee.nameTooltip')} />
          </AddPropertyMortgageeFieldHeader>
          <AddMortgageeTextInputField
            id="name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Addresses
            id="changeCoverageAddPropertyMortgageeAddress"
            handlePlaceSelect={handlePlaceSelect}
            isRequiredField={true}
          >
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addPropertyMortgagee.addressTooltip')}
            />
          </Addresses>
          <AddMortgageeStateSelectorContainer>
            <AddPropertyMortgageeFieldHeader>
              Type <RequiredField>*</RequiredField>
              <TooltipInfo
                title={getText(i18n, 'changeCoverage.addPropertyMortgagee.typeTooltip')}
              />
            </AddPropertyMortgageeFieldHeader>
            <MortgageeInterestTypesList
              name="mortgageeInterestTypesList"
              defaultValue={{ label: '-- Select a Type --', value: 'default' }}
              options={mortgageeInterestTypes}
              onChange={(selectedInterestType) => setInterestType(selectedInterestType)}
            />
          </AddMortgageeStateSelectorContainer>
          <AddPropertyMortgageeFieldHeader>
            Loan Number <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addPropertyMortgagee.loanNumberTooltip')}
            />
          </AddPropertyMortgageeFieldHeader>
          <AddMortgageeTextInputField
            id="loanNumber"
            value={loanNumber}
            onChange={(e) => {
              setLoanNumber(e.target.value);
            }}
          />
          <AddPropertyMortgageeFieldHeader>
            Premium Escrowed
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.addPropertyMortgagee.premiumEscrowedTooltip')}
            />
          </AddPropertyMortgageeFieldHeader>
          <EscrowContainer>
            <input
              type="checkbox"
              id="checkboxEscrow"
              checked={isEscrowed}
              onChange={() => setIsEscrowed(!isEscrowed)}
            />{' '}
            <label htmlFor="checkboxEscrow">
              If your insurance premium is included in your mortgage payment, check this box.
              Otherwise do not.
            </label>
          </EscrowContainer>
          <AddMortgageeButtonsContainer>
            <LinkStyleButton
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Cancel
            </LinkStyleButton>
            <AddMortgageeButton
              id="Submit"
              disabled={!isSubmitButtonEnabled}
              onClick={() => {
                onSubmitButtonClicked();
              }}
            >
              Submit
            </AddMortgageeButton>
            {isSubmitting ? (
              <StyledLoaderContainer>
                <Loader loaderheight="20px" loaderwidth="20px" />
              </StyledLoaderContainer>
            ) : (
              <Spacer />
            )}
          </AddMortgageeButtonsContainer>
        </AddPropertyMortgageeContainer>
      ) : (
        <AddPropertyMortgageeContainer>
          <TwoColumnContainer>
            <AddPropertyMortgageeField>
              <AddPropertyMortgageeFieldHeader>
                Requested Effective Date <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addPropertyMortgagee.effectiveDateTooltip')}
                />
              </AddPropertyMortgageeFieldHeader>
              <input
                type="date"
                aria-label="Requested Effective Date"
                value={effectiveDate}
                min={todayDt}
                onChange={(e) => {
                  setEffectiveDate(e.target.value);
                }}
              />
            </AddPropertyMortgageeField>
            {isBuildingRisksLoading ? (
              <Loader />
            ) : (
              <AddPropertyMortgageeField>
                <AddPropertyMortgageeFieldHeader>
                  Properties <RequiredField>*</RequiredField>
                  <TooltipInfo
                    title={getText(i18n, 'changeCoverage.addPropertyMortgagee.propertiesTooltip')}
                  />
                </AddPropertyMortgageeFieldHeader>
                {buildingRisks.map((buildingRisk) => {
                  return (
                    <AddMortgageePropertyComponent key={buildingRisk.id}>
                      <label htmlFor={buildingRisk.id}>
                        <AddMortgageeRadioButton
                          id={buildingRisk.id}
                          name="riskProperty"
                          value={buildingRisk.id}
                          onChange={(e) => {
                            setSelectedBuildingRiskId(e.target.value);
                          }}
                        />
                        <RadioAddressLabel id={`radioAddressLabel-${buildingRisk.id}`}>
                          {buildingRisk.addr1} {buildingRisk.addr2} <br /> {buildingRisk.city},
                          {buildingRisk.stateProvCd} {buildingRisk.postalCode}
                        </RadioAddressLabel>
                      </label>
                    </AddMortgageePropertyComponent>
                  );
                })}
              </AddPropertyMortgageeField>
            )}
          </TwoColumnContainer>
          <AddMortgageeButtonsContainer>
            <LinkStyleButton
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Cancel
            </LinkStyleButton>
            <AddMortgageeButton
              id="Next"
              disabled={!selectedBuildingRiskId}
              onClick={() => {
                onNextButtonClicked();
              }}
            >
              Next
            </AddMortgageeButton>
          </AddMortgageeButtonsContainer>
        </AddPropertyMortgageeContainer>
      )}
    </>
  );
};

AddPropertyMortgagee.propTypes = {
  dispatch: PropTypes.func,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  selectedPolicy: PropTypes.object,
  mortgageeInterestTypes: PropTypes.array,
  buildingRisks: PropTypes.array,
  isBuildingRisksLoading: PropTypes.bool,
  i18n: PropTypes.object,
  lineCd: PropTypes.string,
};

const mapStateToProps = (state) => ({
  customerId: state.customerSlice.customer.systemId,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  mortgageeInterestTypes: state.policyChangeSlice.mortgageeInterestTypes,
  buildingRisks: state.policyChangeSlice.buildingRisks,
  isBuildingRisksLoading: state.policyChangeSlice.isBuildingRisksLoading,
  i18n: state.i18nSlice.i18n,
  lineCd: getLineCd(state.policyChangeSlice.selectedPolicy, state.policySlice.policies),
  state,
});

export default connect(mapStateToProps)(AddPropertyMortgagee);
