import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findIndex } from 'lodash';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import { determinePaymentConfigId } from '../../common/payment/selectors';
import { addPayment, getBillingAddress, makeSDK } from '../make-payment/step-two-amount/utils';
import ACHPayContainer from '../make-payment/step-two-amount/ach';
import { getPaymentCategory } from '../../../utils/payment/index';
import { getFirstName, getLastName } from '../make-payment/step-two-amount/utils';
import {
  ACHCard,
  ACHInfo,
  AddNewBankAccount,
  AddNewCard,
  AddNewPaymentType,
  AddPaymentInfo,
  CreditCard,
  CreditInfo,
  RadioChoice,
  StyledACHIcon,
  StyledAddIcon,
  StyledButtonNextDeleteCard,
  StyledCreditIcon,
  StyledDeleteButton,
  StyledDeleteIcon,
  StyledDialogActions,
  StyledDialogSpan,
  StyledHorizontalLine,
  StyledModal,
  StyledModalACHIcon,
  StyledModalCreditIcon,
  StyledTextSpan,
} from './styles';

import { deleteFundingInstrument } from '../make-payment/step-two-amount/makePaymentStepTwoApi';
import { setPaymentInstrument } from '../make-payment/step-two-amount/makePaymentStepTwoReducer';

export const FundingInstruments = (props) => {
  const {
    accountId,
    accountPaymentConfigId,
    dispatch,
    existingFundingInstruments,
    instrumentToPayWith,
    customerAddress,
    payScheduleAccount, //passed in prop, account being edited
    paymentApiUrl,
    paymentMethods,
    paymentSdkUrl,
    paymentTenantName,
  } = props;
  const [open, setOpen] = useState(false);
  const [achContainerOpen, setACHContainerOpen] = useState(false);
  const [addPaymentOpen, setAddPaymentOpen] = useState(false);
  const [fundingInstruments, setFundingInstruments] = useState(existingFundingInstruments);
  const [instrumentToDelete, setInstrumentToDelete] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [paymentCategory, setPaymentCategory] = useState();

  useEffect(() => {
    getPaymentCategory(paymentMethods, setPaymentCategory);
    if (paymentMethods.includes('ACHIFrame')) {
      setPaymentCategory('ACHIFrame');
    }
  }, []);

  const deleteInstrument = (instrument) => {
    if (instrument.isTemporary) {
      let indexToRemove = findIndex(fundingInstruments, (current) => {
        if (instrument.sourceType === 'CreditCard') {
          return current?.id === instrument.id;
        } else {
          return current?.achBankAccountNumber === instrument.achBankAccountNumber;
        }
      });
      if (instrument.id === instrumentToPayWith.id) {
        dispatch(setPaymentInstrument(null));
      }
      let cloned = cloneDeep(fundingInstruments);
      cloned.splice(indexToRemove, 1);
      setFundingInstruments(cloned);
    } else {
      dispatch(
        deleteFundingInstrument(accountId, instrument.id, enqueueSnackbar, setFundingInstruments),
      );
      setFundingInstruments(fundingInstruments.filter((e) => e.id !== instrument.id));
    }
    setOpen(false);
  };
  const createSDK = async (overRidePayCat) => {
    let payCat = paymentCategory;
    if (paymentCategory === 'ACHIFrame') {
      payCat = overRidePayCat; //we have to decide based on payment button
      // selected which iframe to load, but only in scenario
      // where customer has ACHIFrame otherwise use normal paymentCategory flow
    }
    //also check for iframe and remove from dom too
    if (!window.GuidewirePayments.createSDK) {
      // if createSDK isn't on window it means it's already been
      // created so we need to delete from window, reload script, and dismount old iframe
      let body = document.body;
      let iframeToBeRemoved = document.getElementsByTagName('iframe')[0];
      body.removeChild(iframeToBeRemoved);

      delete window.GuidewirePayments;
      let head = document.getElementsByTagName('head')[0];
      const paymentSdkScriptTag = document.getElementById('paymentSdkScript');
      head.removeChild(paymentSdkScriptTag);
      const newPaymentSDKTag = document.createElement('script');
      newPaymentSDKTag.setAttribute('src', paymentSdkUrl);
      newPaymentSDKTag.setAttribute('data-gw-payments-sdk', '');
      newPaymentSDKTag.setAttribute('id', 'paymentSdkScript');
      newPaymentSDKTag.setAttribute('async', '');
      head.appendChild(newPaymentSDKTag);
    } else {
      //createSDK hasn't been called yet so we need to now call it
      if (window?.GuidewirePayments?.createSDK) {
        window.GuidewirePayments = makeSDK(
          getFirstName(payScheduleAccount),
          getLastName(payScheduleAccount),
          customerAddress,
          paymentTenantName,
          accountPaymentConfigId,
          paymentApiUrl,
          payCat,
        );
        return await dispatch(
          addPayment(setFundingInstruments, fundingInstruments, false, enqueueSnackbar),
        );
      }
    }
    let newSDK = document.getElementById('paymentSdkScript');

    return newSDK.addEventListener('load', async () => {
      console.log('reloading payment sdk script');
      window.GuidewirePayments = makeSDK(
        getFirstName(payScheduleAccount),
        getLastName(payScheduleAccount),
        customerAddress,
        paymentTenantName,
        accountPaymentConfigId,
        paymentApiUrl,
        payCat,
      );
      await dispatch(addPayment(setFundingInstruments, fundingInstruments, false, enqueueSnackbar));
    });
  };

  const AddNewPaymentButton = async () => {
    if (paymentCategory === 'UserSelect') {
      return await createSDK();
    }
    setAddPaymentOpen(true); //open initial cc/ach modal
  };
  const addCC = async () => {
    await createSDK('CreditCard');
    setAddPaymentOpen(false);
    setACHContainerOpen(false);
  };
  const addACH = async () => {
    if (paymentCategory === 'ACHFormOnly' || paymentCategory === 'CreditCard') {
      return setACHContainerOpen(true); //open ach form, addACH button will
      // not be visible due to logic below if ACH is not included in array and CreditCard is paymentCategory
      // returned when arrays is [CC, ACH]
    } else {
      setAddPaymentOpen(false); //close modal, open iframe
      await createSDK('ECheck');
    }
  };

  const handleAddPaymentClose = () => {
    setAddPaymentOpen(false);
    setACHContainerOpen(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const openDeleteDialog = (instrument) => {
    setOpen(true);
    setInstrumentToDelete(instrument);
  };

  const setACHTopLevel = (fundingInstrument) => {
    setFundingInstruments([fundingInstrument]);
    dispatch(setPaymentInstrument(fundingInstrument));
    setAddPaymentOpen(false);
    setACHContainerOpen(false);
  };
  return (
    <>
      {fundingInstruments &&
        fundingInstruments.map((instrument, index) => {
          if (instrument) {
            if (
              (instrument.methodCd === 'Credit Card' || instrument.sourceType === 'CreditCard') &&
              paymentMethods.includes('CC')
            ) {
              return (
                <label htmlFor={index} key={instrument.id}>
                  <CreditCard key={instrument.id}>
                    <RadioChoice
                      onChange={() => {
                        dispatch(setPaymentInstrument(instrument));
                      }}
                      value={instrument}
                      id={index}
                      checked={
                        instrumentToPayWith ? instrumentToPayWith.id === instrument.id : false
                      }
                    />
                    <StyledCreditIcon />
                    <CreditInfo>
                      {instrument.methodCd} {instrument.cardType && instrument.cardType} -{' '}
                      {instrument.creditCardNumber} {instrument.lastFour && instrument.lastFour}
                    </CreditInfo>
                    {(instrument.isTemporary || instrument.action === 'None') && (
                      <>
                        <StyledDeleteIcon
                          id={index + 'deleteIcon'}
                          onClick={() => {
                            openDeleteDialog(instrument);
                          }}
                        />
                        <Dialog open={open} onClose={handleCloseDialog}>
                          <StyledModal>
                            <DialogTitle id="alert-dialog-title">
                              {'Are you sure you want to Delete?'}
                            </DialogTitle>
                            <StyledDialogSpan>This action cannot be undone.</StyledDialogSpan>
                            <StyledDialogActions>
                              <StyledDeleteButton
                                onClick={() => deleteInstrument(instrumentToDelete)}
                              >
                                Yes
                              </StyledDeleteButton>
                              <StyledButtonNextDeleteCard onClick={handleCloseDialog}>
                                No
                              </StyledButtonNextDeleteCard>
                            </StyledDialogActions>
                          </StyledModal>
                        </Dialog>
                      </>
                    )}
                  </CreditCard>
                </label>
              );
            } else if (
              (instrument.methodCd === 'ACH' || instrument.sourceType === 'ACH') &&
              (paymentMethods.includes('ACH') || paymentMethods.includes('ACHIFrame'))
            ) {
              return (
                <label
                  htmlFor={`ACH${index}`}
                  key={
                    instrument.achBankAccountNumber
                      ? instrument.achBankAccountNumber
                      : instrument.id
                      ? instrument.id
                      : `key${index}`
                  }
                >
                  <ACHCard key={instrument.achBankAccountNumber}>
                    <RadioChoice
                      onChange={() => {
                        dispatch(setPaymentInstrument(instrument));
                      }}
                      value={instrument}
                      id={`ACH${index}`}
                      checked={
                        instrumentToPayWith
                          ? instrumentToPayWith.achBankAccountNumber ===
                            instrument.achBankAccountNumber
                          : false
                      }
                    />
                    <StyledACHIcon />
                    <ACHInfo
                      aria-label={`Payment Type ${instrument?.achBankAccountTypeCd} -
                      ${
                        instrument?.achBankAccountNumber
                          ? instrument?.achBankAccountNumber?.slice(-4)
                          : ''
                      }`}
                    >
                      {instrument.achBankAccountTypeCd}{' '}
                      {instrument.achBankAccountNumber ? '-' : undefined}{' '}
                      {instrument.achBankAccountNumber
                        ? instrument.achBankAccountNumber.slice(-4)
                        : ''}
                    </ACHInfo>
                    {(instrument.isTemporary || instrument.action === 'None') && (
                      <>
                        <StyledDeleteIcon
                          onClick={() => {
                            openDeleteDialog(instrument);
                          }}
                        />
                        <Dialog open={open} onClose={handleCloseDialog}>
                          <StyledModal>
                            <DialogTitle id="alert-dialog-title">
                              {'Are you sure you want to Delete?'}
                            </DialogTitle>
                            <StyledDialogSpan id="alert-dialog-description">
                              This action cannot be undone.
                            </StyledDialogSpan>
                            <StyledDialogActions>
                              <StyledDeleteButton
                                onClick={() => deleteInstrument(instrumentToDelete)}
                              >
                                Yes
                              </StyledDeleteButton>
                              <StyledButtonNextDeleteCard onClick={handleCloseDialog}>
                                No
                              </StyledButtonNextDeleteCard>
                            </StyledDialogActions>
                          </StyledModal>
                        </Dialog>
                      </>
                    )}
                  </ACHCard>
                </label>
              );
            }
          }
        })}
      <AddNewPaymentType
        id={'AddNewPaymentButton'}
        onClick={() => {
          AddNewPaymentButton();
        }}
      >
        <StyledAddIcon />
        <AddPaymentInfo>Add New Payment Type</AddPaymentInfo>
      </AddNewPaymentType>
      <Dialog open={addPaymentOpen} onClose={handleAddPaymentClose} maxWidth={false}>
        <DialogTitle id="alert-dialog-title">{'Select Payment Type'}</DialogTitle>
        <StyledHorizontalLine />
        <StyledModal>
          {paymentMethods.includes('CC') && (
            <AddNewCard
              id={'CreditCardPaymentType'}
              onClick={() => {
                addCC();
              }}
            >
              <StyledModalCreditIcon />
              <StyledTextSpan>Credit Card</StyledTextSpan>
            </AddNewCard>
          )}
          {(paymentMethods.includes('ACH') || paymentMethods.includes('ACHIFrame')) && (
            <AddNewBankAccount
              id={'ACHPaymentType'}
              onClick={() => {
                addACH();
              }}
            >
              <StyledModalACHIcon />
              <StyledTextSpan>Bank Account</StyledTextSpan>
            </AddNewBankAccount>
          )}
          {achContainerOpen ? (
            <ACHPayContainer
              setACHTopLevel={setACHTopLevel}
              isUpdatingPaySchedule={true}
              isAnonymousPay={false}
            />
          ) : null}
        </StyledModal>
      </Dialog>
    </>
  );
};
FundingInstruments.propTypes = {
  accountId: PropTypes.string,
  accountPaymentConfigId: PropTypes.string,
  accountToPayWith: PropTypes.object,
  customerAddress: PropTypes.object,
  dispatch: PropTypes.func,
  existingFundingInstruments: PropTypes.array,
  instrumentToPayWith: PropTypes.object,
  isLoading: PropTypes.bool,
  otherAmountAllowed: PropTypes.bool,
  paymentTenantName: PropTypes.string,
  paymentCategory: PropTypes.string,
  paymentApiUrl: PropTypes.string,
  paymentVendor: PropTypes.string,
  schedulePaymentsAllowed: PropTypes.any,
  paymentMethods: PropTypes.array,
  payScheduleAccount: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  state,
  accountPaymentConfigId: props.payScheduleAccount
    ? determinePaymentConfigId(state.configurationSlice.features.paymentConfigIds, {
        billingAccount: props.payScheduleAccount,
      })
    : determinePaymentConfigId(
        state.configurationSlice.features.paymentConfigIds,
        state.makePaymentSlice.accountToPayWith,
      ),
  accountToPayWith: state.makePaymentSlice.accountToPayWith,
  accountId: state.makePaymentSlice?.accountToPayWith?.billingAccount?.systemId,
  otherAmountAllowed: state.configurationSlice.features.features.otherPaymentAmountAllowed,
  customerAddress: getBillingAddress(state.customerSlice.customer),
  existingFundingInstruments: state.makePaymentStepTwoSlice.fundingInstruments,
  instrumentToPayWith: state.makePaymentStepTwoSlice.instrumentToPayWith,
  schedulePaymentsAllowed: state.configurationSlice.features.features.scheduledPayments,
  isLoading: state.makePaymentStepTwoSlice.isLoading,
  paymentTenantName: state.configurationSlice.features.paymentTenantName,
  paymentApiUrl: state.configurationSlice.features.paymentApiUrl,
  paymentMethods: state.configurationSlice.features.paymentMethods,
  paymentSdkUrl:
    state.configurationSlice.features.paymentSdkUrl !== ''
      ? state.configurationSlice.features.paymentSdkUrl
      : 'https://js-sdk-prod.payments.guidewire.net/v1/gw-payments-sdk.js',
});

export default connect(mapStateToProps)(FundingInstruments);
